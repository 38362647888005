import { Button, Form, Input } from "reactstrap"

import { Loading } from "../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { fail } from "../../actions"

class Offer extends React.Component {
  state = {
    percentageOrPrice: "",
    quantity: 0,
    /* effectiveStarting: "", */
    updating: false,
    success: null,
    error: null,
  }

  componentDidMount = () => {
    const { order } = this.props
    let percentageOrPrice
    if (order.type === "azure") {
      percentageOrPrice = order.marginPercentage
    } else {
      percentageOrPrice = order.unitPrice
    }

    /* const effectiveStarting =
      order.effectiveStarting || new Date().toISOString().substring(0, 10) */

    this.setState({ percentageOrPrice /* , effectiveStarting */ })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  update = async (e) => {
    try {
      e.preventDefault()

      this.setState({ updating: true })

      const update = {
        company: this.props.customer,
        offerId: this.props.order.id,
        unitPrice: this.props.order.unitPrice,
      }

      update.quantity = this.state.quantity

      await axios
        .post(`/ordersUser`, update)
        .catch((error) =>
          this.props
            .fail(null, error)
            .then(() => this.setState({ fetching: false }))
        )

      this.setState({ updating: false, success: true, error: null })
    } catch (err) {
      this.setState({ updating: false, success: false, error: err.message })
    }
  }

  render = () => {
    const { order, t } = this.props

    return (
      <tr>
        <td title={order.id} style={{ verticalAlign: "middle" }}>
          <Form onSubmit={this.update} id={order.id}>
            {order.name}
          </Form>
        </td>
        <td>{order.unitPrice}</td>
        <td>
          <Input
            form={order.id}
            type="number"
            name="quantity"
            value={this.state.quantity}
            onChange={this.handleChange}
            min={0}
            step={1}
          />
        </td>
        {/* <td>
              <Input
                type="date"
                name="effectiveStarting"
                value={this.state.effectiveStarting}
                onChange={this.handleChange}
              />
            </td> */}
        <td>
          {this.state.updating ? (
            <Loading small />
          ) : (
            <Button
              form={order.id}
              color={
                this.state.success
                  ? "success"
                  : this.state.error
                  ? "danger"
                  : "primary"
              }
              type="submit"
              block
            >
              {t("_buy")}
            </Button>
          )}
        </td>
      </tr>
    )
  }
}

export default connect(null, { fail })(withTranslation()(Offer))
