const { default: tasks } = require("../reducers/tasks")

/**
 * Returns string representation of the whole task based on the subtasks
 */
export const getTaskStatus = (mainTask) => {
  // check for cancel
  const canceledTask = mainTask.tasks.find(
    (task) =>
      task.status === "canceled" && task.subStatus !== "canceledByPreviousTask"
  )
  if (canceledTask) {
    console.log(canceledTask)
    return {
      status: "canceled",
      subStatus: canceledTask.subStatus,
      currentTask: canceledTask.taskNumber,
    }
  }
  // check if all tasks are completed
  const completedTasks = mainTask.tasks.filter(
    (task) => task.status === "completed" && task.subStatus === "resolved"
  )
  if (completedTasks.length === mainTask.tasks.length) {
    return {
      status: "completed",
      subStatus: "resolved",
      currentTask: mainTask.tasks.length + 1,
    }
  }

  // find the first task that is not completed or has failed
  const firstTask = mainTask.tasks.find((t) => t.subStatus !== "resolved")
  if (firstTask) {
    return {
      status: firstTask.status,
      subStatus: firstTask.subStatus,
      currentTask: firstTask.taskNumber,
    }
  }
  return {
    status: "",
    subStatus: "",
    currentTask: 0,
  }
}
