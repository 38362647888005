import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
  Alert,
} from "reactstrap"

import { Loading } from "../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import AgreementForm from "./AgreementForm"

const stateToString = (state) => {
  switch (state) {
    case 0:
      return "fetching"
    case 1:
      return "noAgreements"
    case 2:
      return "agreementExists"
    case 3:
      return "error"
  }
}

class Agreement extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      state: 0,
      formVisible: false,
      formSuccess: {
        message: "",
        success: false,
      },
    }
  }

  componentDidMount() {
    this.loadAgreements()
  }

  loadAgreements = async () => {
    this.setState({
      state: 0, // fetching
    })
    axios
      .get(`/agreements/${this.props.customer.id}`, {
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,
        },
      })
      .then(({ data }) => {
        if (data.agreements.length === 0) {
          this.setState({
            state: 1, // no agreements
          })
        } else {
          this.setState({
            state: 2, // there are agreements
          })
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          state: 3, // error
        })
      })
  }
  handleSuccess = (success) => {
    if (success) {
      this.setState({
        formVisible: false,
        formSuccess: {
          message: this.props.t("_agreementSuccess"),
          success: true,
        },
      })
    } else {
      this.setState({
        formVisible: true,
        formSuccess: {
          message: this.props.t("_agreementError"),
          success: false,
        },
      })
    }
    this.loadAgreements()
  }

  render = () => {
    const { t } = this.props
    return (
      <div>
        <h3>{t("_createAgreement")}</h3>
        {(() => {
          switch (stateToString(this.state.state)) {
            case "fetching":
              return <Loading />
            case "agreementExists":
              return (
                <div className="mb-3">
                  <h5>{t("_agreementExists")}</h5>
                  {this.state.formSuccess.message !== "" && (
                    <Alert
                      color={
                        this.state.formSuccess.success ? "success" : "danger"
                      }
                    >
                      {this.state.formSuccess.message}
                    </Alert>
                  )}
                  <Button
                    color="primary"
                    className="mb-3"
                    onClick={() =>
                      this.setState({
                        formVisible: !this.state.formVisible,
                      })
                    }
                  >
                    {this.state.formVisible ? t("_hide") : t("_reconfirm")}
                  </Button>
                  <AgreementForm
                    customer={this.props.customer}
                    visible={this.state.formVisible}
                    success={this.handleSuccess}
                  />
                </div>
              )
            case "noAgreements":
              return (
                <AgreementForm
                  customer={this.props.customer}
                  visible={true}
                  success={this.handleSuccess}
                />
              )
            case "error":
              return <p>Something went wrong</p>
          }
        })()}
      </div>
    )
  }
}

export default connect((s) => s)(withTranslation()(Agreement))
