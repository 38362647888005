import React from "react"
import { fetchResources } from "../../actions"
import { PageContainer } from "../../components"
import { connect } from "react-redux"
import InvoiceDetail from "./InvoiceDetail"

class Invoice extends React.Component {
  componentDidMount = () => this.props.fetchResources({ resource: "invoices" })

  render = () => (
    <PageContainer
      loading={!this.props.invoice}
      title={this.props.match.params.invoiceId}
    >
      <InvoiceDetail invoice={this.props.invoice} />
    </PageContainer>
  )
}

const mapStateToProps = (state, props) => {
  const invoice = state.invoices.invoices.find(
    (i) => i.id.toString() === props.match.params.invoiceId
  )
  return { invoice }
}

export default connect(mapStateToProps, { fetchResources })(Invoice)
