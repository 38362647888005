export const ACTION_TYPES = Object.freeze({
  COMPANY_INFO_SUCCESS: "COMPANY_INFO_SUCCESS",
  COMPANY_INFO_ERROR: "COMPANY_INFO_ERROR",
  COMPANY_INFO_FETCHING: "COMPANY_INFO_REQUEST",
  COMPANY_INFO_RESET: "COMPANY_INFO_RESET",
})

export const initialState = {
  status: "initial", // initial | fetching | success | error
  error: null,
  companyInfo: null,
  controller: new AbortController(),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.COMPANY_INFO_RESET:
      return {
        ...initialState,
      }
    case ACTION_TYPES.COMPANY_INFO_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
        companyInfo: action.companyInfo,
        status: "success",
      }
    case ACTION_TYPES.COMPANY_INFO_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
        status: "error",
      }
    case ACTION_TYPES.COMPANY_INFO_FETCHING:
      return {
        fetching: true,
        error: null,
        companyInfo: null,
        controller: action.controller,
        status: "fetching",
      }
    default:
      return state
  }
}
