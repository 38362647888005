import axios from "axios"
import { ACTION_TYPES } from "../reducers/prh"

const fetchCompanyInfoRequest = (controller) => ({
  type: ACTION_TYPES.COMPANY_INFO_FETCHING,
  controller,
})

const fetchCompanyInfoSuccess = (companyInfo) => ({
  type: ACTION_TYPES.COMPANY_INFO_SUCCESS,
  companyInfo,
})

const fetchCompanyInfoError = (error) => ({
  type: ACTION_TYPES.COMPANY_INFO_ERROR,
  error,
})

export const resetCompanyInfo = () => async (dispatch, getState) => {
  const { controller } = getState().prh
  if (controller) {
    controller.abort()
  }
  dispatch({
    type: ACTION_TYPES.COMPANY_INFO_RESET,
  })
}

export const fetchCompanyInfo =
  ({ companyRegistrationNumber }) =>
  async (dispatch, getState) => {
    const { controller } = getState().prh

    if (controller) {
      controller.abort()
    }
    const newController = new AbortController()
    dispatch(fetchCompanyInfoRequest(newController))

    try {
      const { data } = await axios.get(
        `https://avoindata.prh.fi/tr/v1/${companyRegistrationNumber}`,
        {
          signal: newController.signal,
        }
      )
      if (!data || !data.results || !data.results.length) {
        throw new Error("No results")
      }
      dispatch(fetchCompanyInfoSuccess(data.results[0]))
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      }
      dispatch(fetchCompanyInfoError(err))
    }
  }
