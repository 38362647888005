import { Loading } from "../../components"
import React from "react"
import { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import axios from "axios"
import { connect } from "react-redux"
import { Table, Input, ButtonGroup, Button } from "reactstrap"

function APISettings(props) {
  const { customer, t } = props
  const [apiKey, setApiKey] = useState("")
  const [CIDR, setCIDR] = useState("")
  const [submittedApiKey, setSubmittedApiKey] = useState(undefined)
  const [submittedCIDR, setSubmittedCIDR] = useState(undefined)
  const [loadingApiInformation, setLoadingApiInformation] = useState(false)
  const [loadingApiKey, setLoadingApiKey] = useState(false)
  const [loadingApiCIDR, setLoadingApiCIDR] = useState(false)
  const [apiKeyExists, setApiKeyExists] = useState(false)
  const [generatingApiKey, setGeneratingApiKey] = useState(false)

  useEffect(() => {
    getApiInformation()
  }, [])

  const getApiInformation = async () => {
    setLoadingApiInformation(true)
    try {
      let response = await axios.get(`/customer/${customer.id}/api-information`)

      const exist = response.data.apiKeyExists
      const CIDR = response.data.apiAllowedCIDR

      setApiKeyExists(exist)
      setCIDR(CIDR)
    } catch (err) {
      alert(err.message)
    }
    setLoadingApiInformation(false)
  }

  const addApiKey = async (e) => {
    e.preventDefault()
    if (!apiKey) {
      alert(t("_enterApiKey"))
      return
    }
    setLoadingApiKey(true)

    if (!apiKeyExists || window.confirm(t("_replaceApiKeyConfirmation"))) {
      try {
        await axios.post(`/customer/${customer.id}/apikey`, {
          apiKey,
        })
        setSubmittedApiKey(apiKey)
      } catch (err) {
        alert(err.message)
      }
    }
    setLoadingApiKey(false)
  }

  const submitCIDR = async (e) => {
    setLoadingApiCIDR(true)
    try {
      await axios.post(`/customer/${customer.id}/apiAllowedCIDR`, { CIDR })
      setSubmittedCIDR(CIDR)
    } catch (err) {
      alert(err.message)
    }
    setLoadingApiCIDR(false)
  }

  const generateApiKey = async () => {
    setGeneratingApiKey(true)
    try {
      const key = await axios.get(`/customer/generateapikey`)
      setApiKey(key.data.apiKey)
    } catch (err) {
      alert(err.message)
    }
    setGeneratingApiKey(false)
  }

  const getApiKeyButtonColor = () => {
    if (submittedApiKey !== undefined) {
      if (submittedApiKey === apiKey) {
        return "success"
      }
    }
    return "primary"
  }

  const getAllowedCIDRButtonColor = () => {
    if (submittedCIDR !== undefined) {
      if (submittedCIDR === CIDR) {
        return "success"
      }
    }
    return "primary"
  }

  const deleteApiKey = async () => {
    if (window.confirm(t("_deleteApiKeyConfirmation"))) {
      try {
        await axios.delete(`/customer/${customer.id}/apikey`)
        setApiKey("")
        setSubmittedApiKey(undefined)
        setApiKeyExists(false)
      } catch (err) {
        alert(err.message)
      }
    }
  }

  return (
    <div>
      {submittedApiKey && (
        <h3 style={{ color: "red" }}>{t("_saveApiKeyPrompt")}</h3>
      )}

      {loadingApiInformation ? (
        <Loading />
      ) : (
        <form onSubmit={addApiKey}>
          <Table bordered striped responsive>
            <tbody>
              <tr>
                <td>
                  {t("_ApiKey")}
                  <Input
                    type="text"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    placeholder={
                      apiKeyExists ? t("_apiKeyExists") : t("_notSet")
                    }
                  />
                </td>
                <td className="align-bottom w-25">
                  {generatingApiKey ? (
                    <Loading small />
                  ) : (
                    <Button
                      color="primary"
                      className="w-100 mb-2"
                      onClick={generateApiKey}
                    >
                      {t("_generateRandom")}
                    </Button>
                  )}
                  {loadingApiKey ? (
                    <Loading small />
                  ) : (
                    <ButtonGroup className="w-100">
                      <Button
                        type="submit"
                        color={getApiKeyButtonColor()}
                        className="w-50"
                      >
                        {apiKeyExists ? t("_replace") : t("_submit")}
                      </Button>
                      {apiKeyExists && (
                        <Button
                          color={"primary"}
                          className="w-50"
                          onClick={deleteApiKey}
                        >
                          {t("_delete")}
                        </Button>
                      )}
                    </ButtonGroup>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {t("_allowedIP")}
                  <Input
                    type="text"
                    value={CIDR}
                    onChange={(e) => setCIDR(e.target.value)}
                    placeholder={t("_notSet")}
                  />
                </td>
                <td className="align-bottom">
                  {loadingApiCIDR ? (
                    <Loading small />
                  ) : (
                    <Button
                      onClick={submitCIDR}
                      color={getAllowedCIDRButtonColor()}
                      block
                    >
                      {t("_submit")}
                    </Button>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </form>
      )}
    </div>
  )
}

export default connect(null)(withTranslation()(APISettings))
