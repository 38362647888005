import {
  // Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  // NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap"
import i18next from "i18next"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import moment from "moment"
import "moment/locale/fi"

moment.locale(i18next.language)

class BottomBar extends React.Component {
  constructor(props) {
    super(props)
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
  }
  handleLanguageChange(lang) {
    moment.locale(lang)
    this.props.i18n.changeLanguage(lang)
  }
  render() {
    return (
      <Navbar color="light" fixed="bottom" expand="md" light>
        <Container>
          <Nav className="mr-auto" navbar />
          <Nav navbar>
            <UncontrolledDropdown direction="up" nav inNavbar>
              <DropdownToggle nav caret>
                {this.props.t("_currentLang")}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  value="en-EN"
                  onClick={(e) => this.handleLanguageChange(e.target.value)}
                >
                  English
                </DropdownItem>
                <DropdownItem
                  value="fi-FI"
                  onClick={(e) => this.handleLanguageChange(e.target.value)}
                >
                  Suomi
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    )
  }
}
export default connect((s) => s.user)(withTranslation()(BottomBar))
