import { Button, Col, Input, Row } from "reactstrap"
import { fetchResources, setlistsize, fail } from "../../actions"
import { Link } from "react-router-dom"
import { ErrorDialog, PageContainer, TranslatedTable } from "../../components"
import React from "react"
import axios from "axios"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class Customers extends React.Component {
  state = { search: "", updatingCustomerList: false, error: null }

  componentDidMount = () => this.props.fetchResources({ resource: "customers" })

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  updateCustomerList = () => {
    this.setState({ updatingCustomerList: true })
    axios
      .post("/customers/update")
      .then(() =>
        this.props.fetchResources({ resource: "customers", force: true })
      )
      .catch((error) => {
        this.props.fail(null, error)
        this.setState({ error })
      })
      .finally(() => this.setState({ updatingCustomerList: false }))
  }

  render = () => {
    const customers = this.props.customers.customers.filter((c) =>
      c.companyName.toLowerCase().includes(this.state.search.toLowerCase())
    )

    const { t } = this.props
    const columns = [
      {
        Header: t("_name"),
        accessor: "companyName",
        Cell: (props) => (
          <Link to={`/customers/${props.original.id}`}>{props.value}</Link>
        ),
      },
      {
        Header: t("_customerID"),
        accessor: "id",
      },
    ]

    return (
      <PageContainer title={t("_customers")}>
        <ErrorDialog
          open={this.state.error ? true : false}
          title={t("_failedToUpdateCustomers")}
          toggle={() =>
            this.setState({
              error: null,
            })
          }
        />
        <Row>
          <Col sm="6">
            <Input
              type="text"
              name="search"
              id="search"
              placeholder={t("_search")}
              value={this.state.search}
              onChange={this.handleChange}
            />
          </Col>

          <Col sm="6">
            <Button
              className="float-right"
              color="primary"
              onClick={this.updateCustomerList}
              disabled={this.state.updatingCustomerList}
            >
              {t("_updateCustomerList")}
            </Button>
          </Col>
        </Row>

        <hr />

        <TranslatedTable
          columns={columns}
          data={customers}
          fetching={
            this.props.customers.fetching || this.state.updatingCustomerList
          }
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
        />
        <hr />
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources, setlistsize, fail })(
  withTranslation()(Customers)
)
