import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Spinner,
  FormFeedback,
} from "reactstrap"

import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import axios from "axios"
import { Loading } from "."
import countryISOCodes from "../res/countryISOCodes"
import Validator from "../lib/validator"
import { update } from "../actions"

class RequireSignModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formErrors: {},
      firstName: "",
      lastName: "",
      phoneNumber: "",
      link: "",
      checked: false,
      waitingForSign: false,
      fetching: true,
    }
    this.validator = new Validator({
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      checked: {
        checked: true,
      },
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  componentDidMount() {
    this.props
      .update()
      .then(() => {
        if (this.props.user.signed !== "completed") {
          axios
            .post("/signdocument", {
              language: this.props.i18n.language === "fi-FI" ? "fi" : "en",
            })
            .then((res) =>
              this.setState({
                fetching: false,
                link: res.data.link,
              })
            )
            .catch((err) => console.log(err))
        } else {
          this.props.history.push("/")
        }
      })
      .catch((err) => console.log(err))
  }

  validate = () => {
    const formErrors = this.validator.validate(this.state, this.props.t)
    if (Object.keys(formErrors).length !== 0) {
      this.setState({ formErrors })
      return false
    }
    this.setState({ formErrors })
    return true
  }

  sign = async (e) => {
    e.preventDefault()
    if (this.state.fetching || !this.validate()) return
    if (this.props.user.signed === "waitingForCompletion") {
      await this.complete()
      return
    }
    this.setState({ waitingForSign: true })
    const width = Math.min(window.screen.availWidth, 1001)
    const height = Math.min(window.screen.availHeight * 0.8, 1050)
    const left = (window.screen.availWidth - width) / 2
    const top = (window.screen.availHeight - height) / 2
    window.open(
      this.state.link,
      "visma",
      `width=${width},height=${height},left=${left},top=${top},toolbar=0,status=0,`
    )
    await this.pollstatus()
  }

  pollstatus = async () => {
    while (true) {
      const { data } = await axios.get(`/pollsignstatus`)
      if (data.status === "signed") {
        break
      }
      await new Promise((resolve, reject) => setTimeout(resolve, 3000)) // wait 3 seconds after each request
    }
    await this.complete()
  }
  complete = async () => {
    await axios.post("/signcomplete", {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
    })
    await this.props.update()
    await this.props.history.push("/")
  }

  render = () => {
    const { t } = this.props
    return (
      <Modal
        isOpen={this.props.user.signed !== "completed"}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.toggle}>{t("_sign")}</ModalHeader>
        {this.state.fetching ? (
          <Loading />
        ) : (
          <ModalBody>
            <p>{t("_mustSign")}</p>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="firstName">
                      {t("_firstName")}{" "}
                      <span style={{ color: "#FF0000" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      placeholder="John"
                      invalid={"firstName" in this.state.formErrors}
                    />
                    <FormFeedback>
                      {this.state.formErrors.firstName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="lastName">
                      {t("_lastName")}{" "}
                      <span style={{ color: "#FF0000" }}>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      placeholder="Doe"
                      invalid={"lastName" in this.state.formErrors}
                    />
                    <FormFeedback>
                      {this.state.formErrors.lastName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="email">{t("_email")}</Label>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      value={this.props.user.email}
                      onChange={this.handleChange}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="phoneNumber">{t("_phoneNumber")}</Label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={this.state.phoneNumber}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value={this.state.checked}
                        onChange={(e) =>
                          this.setState({ checked: e.target.checked })
                        }
                        invalid={"checked" in this.state.formErrors}
                      />
                      {t("_confirmTrue")}
                      <FormFeedback>
                        {this.state.formErrors.checked}
                      </FormFeedback>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        )}

        <ModalFooter>
          <Button
            color="primary"
            onClick={this.sign}
            disabled={this.state.waitingForSign || this.state.fetching}
          >
            {this.props.user.signed === "waitingForCompletion"
              ? t("_submit")
              : t("_startSigningProcess")}
            <Spinner
              className="ml-3"
              size="sm"
              style={{
                display: this.state.waitingForSign ? "inline-block" : "none",
              }}
            ></Spinner>
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default connect((s) => s, { update })(
  withTranslation()(RequireSignModal)
)
