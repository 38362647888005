import { Col, Row } from "reactstrap"

import LicenseDetails from "./LicenseDetails"
import LicenseQuantityForm from "./LicenseQuantityForm"
import { PageContainer, Transitions } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { fetchResources } from "../../actions"

class License extends React.Component {
  componentDidMount = () => this.props.fetchResources({ resource: "orders" })

  render = () => (
    <PageContainer title="Details" loading={!this.props.license}>
      <Row>
        <Col sm="7">
          <LicenseDetails license={this.props.license} />
        </Col>
        <Col
          sm="5"
          className="d-flex flex-column"
          style={{
            gap: "80px",
          }}
        >
          <Row className="px-3">
            <LicenseQuantityForm license={this.props.license} />
          </Row>
          <Row className="px-3">
            {this.props.license?.status === "active" && (
              <Transitions
                order={this.props.license}
                orders={this.props.orders}
                className="mt-3"
              />
            )}
          </Row>
        </Col>
      </Row>
    </PageContainer>
  )
}

const mapStateToProps = (state, props) => {
  const license = state.orders.orders.find(
    (o) => o.id.toString() === props.match.params.licenseId
  )
  return { license, orders: state.orders.orders }
}

export default connect(mapStateToProps, { fetchResources })(License)
