import { Button, Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap"

import React from "react"

import { useTranslation } from "react-i18next"

const ErrorDialog = ({ title, message, open, toggle }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          {t("_close")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ErrorDialog
