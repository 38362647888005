import { Button, Col, Row } from "reactstrap"
import { fetchResources, setlistsize } from "../../actions"

import { Link } from "react-router-dom"
import CreateAccount from "./CreateAccount"
import { PageContainer, TranslatedTable } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class Users extends React.Component {
  state = { adding: false }

  componentDidMount = () => {
    this.props.fetchResources({ resource: "users", force: true })
    this.props.fetchResources({ resource: "customers" })
  }

  toggle = () => this.setState({ adding: !this.state.adding })

  closeAddForm = () => {
    this.props.fetchResources({ resource: "users", force: true })
    this.toggle()
  }

  render() {
    const { t } = this.props

    const columnsUser = [
      {
        Header: t("_email"),
        accessor: "email",
        Cell: (props) => (
          <Link to={`/users/${props.original.id}`}>{props.value}</Link>
        ),
      },
      {
        Header: t("_customer"),
        accessor: "customer.companyName",
      },
    ]
    const columnsAdmin = [
      {
        Header: t("_email"),
        accessor: "email",
        Cell: (props) => (
          <Link to={`/users/${props.original.id}`}>{props.value}</Link>
        ),
      },
    ]
    return (
      <PageContainer
        title={t("_users")}
        loading={
          !this.props.users.users.length ||
          !this.props.customers.customers.length
        }
      >
        <Row>
          <Col sm="6">
            {this.state.adding ? (
              <CreateAccount
                customers={this.props.customers.customers}
                onClose={this.closeAddForm}
              />
            ) : (
              <Button onClick={this.toggle}>{t("_createAccount")}</Button>
            )}
          </Col>
        </Row>

        <hr />

        <h3>{t("_users")}</h3>
        <TranslatedTable
          columns={columnsUser}
          data={this.props.users.users.filter((u) => !u.isAdmin)}
          fetching={this.props.users.fetching}
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          filterable={true}
        />

        <hr />
        <h3>{t("_admins")}</h3>
        <TranslatedTable
          columns={columnsAdmin}
          data={this.props.users.users.filter((u) => u.isAdmin)}
          fetching={this.props.users.fetching}
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          filterable={true}
        />
        <hr />
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources, setlistsize })(
  withTranslation()(Users)
)
