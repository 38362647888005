import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import React from "react"
import { ACTION_TYPES as USER_TYPE } from "../reducers/user"
import axios from "axios"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import { login } from "../actions"

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    token: "",
    error: null,
    fetching: false,
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  login2 = async (e) => {
    e.preventDefault()
    setTimeout(function () {
      window.location = "https://partners.avanio.com/auth"
    }, 500)
  }

  login = async (e) => {
    e.preventDefault()

    try {
      this.setState({ fetching: true })

      const { email, password, token } = this.state
      await this.props.dispatch(this.props.login({ email, password, token }))
    } catch (err) {
      this.setState({
        token: "",
        error: err.message,
        fetching: false,
      })
    }
  }

  render() {
    const { t } = this.props
    return (
      <Container>
        <Row>
          <Col sm={{ size: 4, offset: 4 }}>
            <h1 className="text-center text-primary">{t("_login")}</h1>
            <hr />
            <Card>
              <CardBody>
                <Form onSubmit={this.login}>
                  <FormGroup>
                    <Label for="email">{t("_email")}</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder={t("_emailPH")}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="password">{t("_password")}</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      autoComplete="current-password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      placeholder="••••••••"
                    />
                  </FormGroup>

                  {this.state.error ? (
                    <Alert color="danger">{t("_loginfailed")}</Alert>
                  ) : null}

                  {this.props.user.loggedOut ? (
                    <Alert color="primary">{t("_logoutsuccess")}</Alert>
                  ) : null}

                  <Button
                    type="submit"
                    color="primary"
                    disabled={this.state.fetching}
                    block
                  >
                    {t("_login")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Form onSubmit={this.login2}>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={this.state.fetching}
                    block
                  >
                    {t("AD-Login")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 4, offset: 4 }}>
            <Button color="link" block style={{ transform: "rotate(0)" }}>
              <a
                href="/#/forgot-password"
                className="stretched-link"
                style={{ paddingTop: "1em" }}
              >
                {t("_forgotPassword")}?
              </a>
            </Button>
            <h5 className="text-center mt-3">{t("_dontHaveAnAccount")}</h5>
            <Button color="link" block style={{ transform: "rotate(0)" }}>
              <a
                href="/#/register"
                className="stretched-link"
                style={{ paddingTop: "1em" }}
              >
                {t("_register")}
              </a>
            </Button>
          </Col>
        </Row>
        <Modal isOpen={this.props.user?.tokenRequired}>
          <Form onSubmit={this.login}>
            <ModalHeader>{t("_2FAauthentication")}</ModalHeader>
            <ModalBody>
              <Label for="token">{t("_authenticationToken")}</Label>
              <Input
                type="text"
                minLength={6}
                maxLength={6}
                name="token"
                value={this.state.token}
                onChange={this.handleChange}
                placeholder="123456"
              />
            </ModalBody>
            <ModalFooter>
              <Button type="submit">{t("_submit")}</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Container>
    )
  }
}

export default connect(
  (s) => s,
  (dispatch) => ({ login, dispatch })
)(withTranslation()(withRouter(Login)))
