export default (resource, { isObj = false } = {}) => {
  const resources = resource.toLowerCase()
  const RESOURCES = resource.toUpperCase()

  return (
    state = {
      [resources]: isObj ? {} : [],
      error: null,
      fetching: false,
      updateTime: 0,
    },
    action
  ) => {
    switch (action.type) {
      case `${RESOURCES}_REQUESTED`:
        return {
          ...state,
          fetching: true,
        }

      case `${RESOURCES}_RECEIVED`: {
        return {
          ...state,
          [resources]: action[resources],
          error: null,
          updateTime: Date.now(),
          fetching: false,
        }
      }

      case `${RESOURCES}_ERROR`:
        return {
          ...state,
          error: action.error,
          fetching: false,
        }

      case `${RESOURCES}_ERROR_CLEAR`:
        return { ...state, error: null }

      case "LOGOUT":
        return {
          [resources]: isObj ? {} : [],
          error: null,
          fetching: false,
          updateTime: 0,
        }

      default:
        return state
    }
  }
}
