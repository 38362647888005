import { Container } from "reactstrap"
import { Loading } from "."
import React from "react"

export default (props) => {
  const { children, title, loading, ...otherProps } = props
  return (
    <Container className="mb-5" {...otherProps}>
      <h1 className="text-center text-primary">{title}</h1>
      <hr />
      {loading ? <Loading /> : children}
    </Container>
  )
}
