import React from "react"
import axios from "axios"
import { connect } from "react-redux"
import { wsLogout } from "../actions/wsActions"

class Logout extends React.Component {
  componentDidMount = async () => {
    this.props.dispatch({ type: "LOGOUT" })

    await wsLogout()

    await axios.get("/logout")

    this.props.history.push("/")
  }

  render = () => null
}

export default connect()(Logout)
