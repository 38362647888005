import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import TaskNotification from "./TaskNotification"

class Notifications extends React.Component {
  render = () => {
    const { tasks, user } = this.props
    if (!user || !user.loggedIn) return null
    return (
      <div
        className="position-fixed mx-3 notification-div"
        style={{ width: "250px", right: "0px", top: "50px" }}
      >
        {tasks.tasks
          .filter((t) => t.showNotification)
          .map((task) => (
            <TaskNotification key={task._id} task={task} />
          ))}
      </div>
    )
  }
}

export default connect((s) => ({
  tasks: s.tasks,
  user: s.user,
}))(withTranslation()(Notifications))
