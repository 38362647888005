import { setTask } from "."

const { isServerPacket } = require("../lib/websocket/serverPacket")

export const createPacket = (type, data) => {
  return {
    type,
    data,
  }
}

export const sendPacket = (ws, packet) => {
  if (ws && ws.readyState === WebSocket.OPEN) {
    ws.send(JSON.stringify(packet))
  }
}

let ws = undefined
export const connect = () => async (dispatch) => {
  // dont connect if already connected
  if (ws) return
  ws = new WebSocket(
    `${window.location.protocol === "http:" ? "ws" : "wss"}://${
      process.env.NODE_ENV === "production"
        ? window.location.host
        : "localhost:4000"
    }/ws`
  )
  ws.onopen = async () => {
    console.log("connected")
    await dispatch(wsLogin())
  }
  ws.onmessage = async (msg) => {
    const payload = JSON.parse(msg.data)
    if (isServerPacket(payload)) {
      switch (payload.type) {
        case "pong":
          console.log("Received pong")
          break
        case "task_update":
          const task = payload.data
          console.log(task)
          dispatch(
            setTask({
              ...task,
              showNotification: true,
            })
          )
          break
      }
    }
  }
  ws.onclose = () => {
    ws = undefined
    setTimeout(() => {
      dispatch(connect())
    }, 10000)
  }
}

export const wsLogin = () => async (dispatch, getState) => {
  const state = getState()
  if (state.user.loggedIn) {
    sendPacket(
      ws,
      createPacket("token", {
        token: state.user.token,
      })
    )
  }
}

export const wsLogout = async () => {
  if (ws) {
    sendPacket(ws, createPacket("logout", {}))
  }
}
