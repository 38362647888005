// this should contain server packets

const packets = ["pong", "task_update"] // list of string of types of packets

const isServerPacket = (packet) => {
  return (
    typeof packet === "object" &&
    packet !== null &&
    packet.type &&
    packets.includes(packet.type)
  )
}

module.exports = { isServerPacket }
