export const ACTION_TYPES = Object.freeze({
  RESET: "REGISTER_RESET",
  STATUS: "REGISTER_STATUS",
  ERROR: "REGISTER_ERROR",
})

export const initialState = {
  status: "initial", // initial | redirected | accepted | registering | success | error
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      }
    case ACTION_TYPES.STATUS:
      return {
        ...state,
        status: action.status,
      }
    case ACTION_TYPES.ERROR:
      return {
        ...state,
        error: action.error,
        status: "error",
      }
    default:
      return state
  }
}
