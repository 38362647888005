import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
  Spinner,
} from "reactstrap"

import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import Validator from "../../lib/validator"

class PurchaseRights extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      maxTermDuration: 0,
      allowedBillingPlans: [],
      nceBillingPlans: [],
      maxCredit: 0,
      azureSpendingLimit: 0,
      synced: false, // does form show up to date information
      unsynced: [], // list of unsynced fields
      updating: false,
    }
    this.validator = new Validator({})
  }
  componentDidMount() {
    axios
      .get("/ncebillingplans")
      .then((resp) => resp.data)
      .then((data) =>
        this.setState({
          nceBillingPlans: data.nceBillingPlans,
        })
      )
    this.updatePurchaseRights().catch((err) => console.log(err))
  }

  updatePurchaseRights = async () => {
    axios
      .get(`/customer/${this.props.customer.id}/purchaserights`)
      .then((resp) => resp.data)
      .then((data) =>
        this.setState({
          ...data.purchaseRights,
          synced: true,
          updating: false,
        })
      )
      .catch((err) => console.log(err))
  }
  handleChange =
    (parseNumber) =>
    ({ target }) => {
      const unsynced = this.state.unsynced
      unsynced.push(target.name)
      this.setState({
        [target.name]: parseNumber ? parseInt(target.value) : target.value,
        synced: false,
        unsynced,
      })
    }

  handleTermChange = ({ target }) => {
    this.setState({ [target.name]: parseInt(target.value) }, () =>
      this.sendForm("maxTermDuration")
    )
  }

  handleBillingPlanChange = ({ target }) => {
    const currentBillingPlans = this.state.allowedBillingPlans
    if (target.checked) {
      currentBillingPlans.push(target.value)
    } else {
      currentBillingPlans.splice(currentBillingPlans.indexOf(target.value), 1)
    }
    this.setState(
      {
        allowedBillingPlans: currentBillingPlans,
      },
      () => this.sendForm("allowedBillingPlans")
    )
  }

  sendForm = async (field) => {
    let synced = this.state.synced
    if (this.state.unsynced.includes(field)) {
      synced = true
      this.setState({
        unsynced: this.state.unsynced.filter((s) => s !== field),
      })
    }
    this.setState({
      synced: false,
      updating: true,
    })
    axios
      .patch(`/customer/${this.props.customer.id}/purchaserights`, {
        purchaseRights: {
          [field]: this.state[field],
        },
      })
      .then(() => this.setState({ synced, updating: false }))
      .catch((err) => {
        console.log(err)
        this.updatePurchaseRights()
      })
  }

  submitOnEnter = (field) => async (e) => {
    console.log(e.target.value)
    if (e.charCode == 13) await this.sendForm(field)
  }

  render() {
    const { t } = this.props
    return (
      <div className="container-fluid border p-3">
        <Row className="p-3 d-flex justify-content-between">
          <h5>{t("_purchaseRights")}</h5>
          {!this.state.updating ? (
            <div className="invisible"></div>
          ) : (
            <Spinner size="sm"></Spinner>
          )}
          {this.state.synced && <div>synced</div>}
        </Row>
        <Form>
          <Label>{t("_billingPlan")}</Label> <br />
          {this.state.nceBillingPlans.map((billingPlan) => (
            <FormGroup check inline key={billingPlan}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.allowedBillingPlans.includes(billingPlan)}
                  value={billingPlan}
                  onChange={this.handleBillingPlanChange}
                />
                {billingPlan}
              </Label>
            </FormGroup>
          ))}
          <div className="mb-3"></div>
          <FormGroup>
            <Label>{t("_maxCommitment")}</Label>
            <Input
              type="select"
              id="maxTermDuration"
              name="maxTermDuration"
              onChange={this.handleTermChange}
              value={this.state.maxTermDuration}
            >
              <option value={1}>1 month</option>
              <option value={12}>1 year</option>
              <option value={36}>3 years</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="maxCredit">{t("_maxCredit")}</Label>
            <Input
              type="number"
              id="maxCredit"
              name="maxCredit"
              onChange={this.handleChange(true)}
              onKeyPress={this.submitOnEnter("maxCredit")}
              value={this.state.maxCredit}
            />
          </FormGroup>
          <FormGroup>
            <Label for="azureSpendingLimit">{t("_azureSpendingLimit")}</Label>
            <Input
              type="number"
              id="azureSpendingLimit"
              name="azureSpendingLimit"
              onChange={this.handleChange(true)}
              onKeyPress={this.submitOnEnter("azureSpendingLimit")}
              value={this.state.azureSpendingLimit}
            />
          </FormGroup>
        </Form>
      </div>
    )
  }
}

export default connect((s) => s)(withTranslation()(PurchaseRights))
