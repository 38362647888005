import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
} from "reactstrap"

import { Loading } from "../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import Validator from "../../lib/validator"

class AgreementForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      dateAgreed: "",
      formErrors: {},
    }
    this.validator = new Validator({
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      email: {
        required: true,
        match: {
          // http://emailregex.com/
          regex:
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        },
      },
      dateAgreed: {
        required: true,
      },
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  validate = () => {
    const formErrors = this.validator.validate(this.state, this.props.t)
    if (Object.keys(formErrors).length !== 0) {
      this.setState({ formErrors })
      return false
    }
    return true
  }
  sendForm = async (e) => {
    e.preventDefault()
    if (!this.validate()) return
    this.setState({
      fetching: true,
    })
    const data = {
      primaryContact: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
      },
      dateAgreed:
        this.state.dateAgreed !== ""
          ? new Date(this.state.dateAgreed).toISOString()
          : new Date().toISOString(),
      customerId: this.props.customer.id,
    }
    try {
      await axios.post("/createagreement", data, {
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,
        },
      })
      if ("success" in this.props) {
        this.setState({
          fetching: false,
        })
        this.props.success(true)
      }
    } catch (err) {
      console.log(err)
      this.setState({
        fetching: false,
      })
      this.props.success(false)
    }
  }

  render() {
    const { t } = this.props
    if (!this.props.visible) {
      return <div></div>
    }
    return (
      <div className="container-fluid border p-3">
        <h5>{t("_primaryCustomerContact")}</h5>
        <Table bordered striped responsive>
          <tbody>
            <tr>
              <td>
                {t("_firstName")}*
                <Input
                  type="text"
                  id="firstName"
                  name="firstName"
                  onChange={this.handleChange}
                  invalid={"firstName" in this.state.formErrors}
                />
              </td>
              <td>
                {t("_lastName")}*
                <Input
                  type="text"
                  id="lastName"
                  name="lastName"
                  onChange={this.handleChange}
                  invalid={"lastName" in this.state.formErrors}
                />
              </td>
            </tr>
            <tr>
              <td>
                {t("_email")}*
                <Input
                  type="text"
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  invalid={"email" in this.state.formErrors}
                />
              </td>
              <td>
                {t("_phoneNumber")}
                <Input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  onChange={this.handleChange}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <FormGroup>
          <Label className="h5" for="dateAgreed">
            {t("_dateAgreed")}*
          </Label>
          <Input
            type="date"
            id="dateAgreed"
            name="dateAgreed"
            onChange={this.handleChange}
            invalid={"dateAgreed" in this.state.formErrors}
            max={new Date().toISOString().substring(0, 10)}
          />
        </FormGroup>
        <div className="d-flex">
          <Button
            color="primary flex-grow-1"
            onClick={this.sendForm}
            disabled={this.state.fetching}
          >
            {t("_accept")}
          </Button>
        </div>
      </div>
    )
  }
}

export default connect((s) => s)(withTranslation()(AgreementForm))
