export const combineByField = (arr, arr2, field) => {
  return arr.map((item) => {
    const item2 = arr2.find((item2) => item2[field] === item[field])
    return { ...item, ...item2 }
  })
}

export const removeDuplicates = (arr) => {
  return Array.from(new Set(arr))
}

export const countItems = (arr, val, accessor) => {
  if (!arr) return undefined
  if (!Array.isArray(arr)) return undefined
  return arr.filter((item) => item[accessor] === val)?.length
}
