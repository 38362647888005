import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

import { Loading } from "../components"
import { withTranslation } from "react-i18next"
import React from "react"
import axios from "axios"

class ForgotPassword extends React.Component {
  state = { email: "", fetching: false, success: false, error: null }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  sendReset = (e) => {
    e.preventDefault()

    this.setState({ fetching: true, success: false, error: null })

    axios
      .post("/forgot-password", { email: this.state.email })
      .then(() => this.setState({ fetching: false, success: true }))
      .catch((err) => this.setState({ fetching: false, error: err.message }))
  }

  render() {
    const { t } = this.props
    return (
      <Container>
        <Row>
          <Col sm={{ size: 4, offset: 4 }}>
            <h1 className="text-center text-primary">{t("_forgotPassword")}</h1>
            <hr />
            <Card>
              <CardBody>
                <Form onSubmit={this.sendReset}>
                  <fieldset disabled={this.state.fetching}>
                    <FormGroup>
                      <Label for="email">{t("_email")}</Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>

                    {this.state.error ? (
                      <Alert color="danger">{t("_errorMsg_resetSend")}</Alert>
                    ) : null}

                    {this.state.success ? (
                      <Alert color="success">
                        {t("_successMsg_resetSend")}
                      </Alert>
                    ) : null}

                    <Button type="submit" block>
                      {this.state.fetching ? <Loading small /> : t("_submit")}
                    </Button>
                  </fieldset>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withTranslation()(ForgotPassword)
