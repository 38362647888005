import { useTranslation } from "react-i18next"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import axios from "axios"

import { Alert, Button } from "reactstrap"

import { Loading } from "."

const AzurePlan = (props) => {
  const [t] = useTranslation()
  const { orders } = props
  const user = useSelector((state) => state.user)
  const [status, setStatus] = useState(0) // 0: not started, 1: making an order 2: success 3: failed
  if (status === 1 || props.loading) {
    return (
      <div>
        <Loading />
      </div>
    )
  } else if (status === 2) {
    return (
      <div>
        <Alert color="success">{t("_azureSuccess")}</Alert>
      </div>
    )
  } else if (status === 3) {
    return (
      <div>
        <Alert color="danger">{t("_error")}</Alert>
      </div>
    )
  }
  const azurePlan = orders.find((order) => order?.productType?.id === "Azure")
  if (azurePlan) {
    if (azurePlan.status === "suspended") {
      return (
        <div>
          <Button
            color="primary"
            className="btn-block"
            onClick={() => {
              setStatus(1)
              axios
                .post(`/orders/${azurePlan.id}/reactivate`, {
                  customerId: user.isAdmin ? props.customerId : undefined,
                })
                .then(() => {
                  setStatus(2)
                })
                .catch((err) => {
                  setStatus(3)
                })
            }}
          >
            {t("_reactivateAzurePlan")}
          </Button>
        </div>
      )
    } else {
      return (
        <div>
          <p>{t("_alreadyHasAzurePlan")}</p>
        </div>
      )
    }
  } else {
    return (
      <div>
        <Button
          color="primary"
          className="btn-block"
          onClick={() => {
            setStatus(1)
            axios
              .post(`/orders/azureplan`, {
                customerId: user.isAdmin ? props.customerId : undefined,
              })
              .then(() => {
                setStatus(2)
              })
              .catch((err) => {
                setStatus(3)
              })
          }}
        >
          {t("_buyAzurePlan")}
        </Button>
      </div>
    )
  }
}

export default AzurePlan
