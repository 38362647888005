import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

/**
 * use webpacked languages
 */
const LoadTranslations = async () => {
  const en = await import("./lang/en.json" /* webpackChunkName: "lang-en" */)
  const fi = await import("./lang/fi.json" /* webpackChunkName: "lang-fi" */)
  const sv = await import("./lang/sv.json" /* webpackChunkName: "lang-sv" */)
  i18next.use(LanguageDetector).init({
    resources: {
      en: en,
      fi: fi,
      sv: sv,
    },
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  })
}
LoadTranslations()

export default i18next
