import React, { useEffect } from "react"

import RequireSignModal from "./components/RequireSignModal"
import Notifications from "./components/Notifications"
import Task from "./pages/Task/Task"
import MFAPage from "./pages/MFAPage"
import { useCookies } from "react-cookie"
import Consent from "./pages/Consent"

import Report from "./pages/Report"
import ResetPassword from "./pages/ResetPassword"
import Settings from "./pages/Settings"
import User from "./pages/User"
import Users from "./pages/Users"
import LicenseUsers from "./pages/LicenseUsers"
import LicenseUserDetail from "./pages/LicenseUsers/LicenseUserDetail"
import Software from "./pages/Software"

import { connect as connectWs } from "./actions/wsActions"
import Invoice from "./pages/Invoice"
import Invoices from "./pages/Invoices"
import License from "./pages/License"
import Logout from "./pages/Logout"
import ADLogin from "./pages/ADLogin"
import Offers from "./pages/Offers/OffersCustomer"
import OfferDetailCustomer from "./pages/Offers/OfferDetailCustomer"
import OfferDetailAdmin from "./pages/Offers/OfferDetailAdmin"
import Order from "./pages/Order"
import Orders from "./pages/Orders"
import { PreAuth, Activate } from "./pages/RegisterCustomer"
import ExistingCustomer from "./pages/RegisterCustomer/Existing"

import Customer from "./pages/Customer"
import Customers from "./pages/Customers"
import Dashboard from "./pages/Dashboard"
import ForgotPassword from "./pages/ForgotPassword"
import { BottomBar, TopNavbar, PrivateGate } from "./components"

import { HashRouter, Redirect, Route, Switch } from "react-router-dom"
import { connect, useDispatch } from "react-redux"

const AdminRoute = connect((s) => s.user)(
  ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        rest.isAdmin ? <Component {...props} /> : <Redirect to="/logout" />
      }
    />
  )
)
const SignedRoute = connect((s) => s.user)(
  ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        rest.signed === "completed" || rest.isAdmin || process.env.NODE_ENV === "development" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/sign" />
        )
      }
    />
  )
)
const CookieAccepted = (props) => {
  const [cookies, setCookie] = useCookies(["consent"])
  if (cookies.consent === "functional_consent") {
    return props.children
  }
  return <Consent />
}
const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(connectWs())
  }, [dispatch])
  return (
    <>
      <TopNavbar />
      <BottomBar />
      <CookieAccepted>
        <Notifications />
        <HashRouter>
          <Switch>
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/adlogin/:token" component={ADLogin} />
            <Route path="/register" component={PreAuth} />
            <Route path="/activate/new/:token" component={Activate} />
            <Route
              path="/activate/existing/:token"
              component={ExistingCustomer}
            />
            <PrivateGate>
              <Switch>
                <Route exact path="/sign" component={RequireSignModal} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/mfa" component={MFAPage} />
                <SignedRoute exact path="/" component={Dashboard} />
                <SignedRoute exact path="/offers" component={Offers} />
                <SignedRoute
                  exact
                  path="/offers/:offerId"
                  component={OfferDetailCustomer}
                />
                <SignedRoute exact path="/orders" component={Orders} />
                <SignedRoute exact path="/orders" component={Orders} />
                <SignedRoute
                  exact
                  path="/licenseUsers"
                  component={LicenseUsers}
                />
                <SignedRoute exact path="/report" component={Report} />
                <SignedRoute exact path="/settings" component={Settings} />
                <SignedRoute path="/licenses/:licenseId" component={License} />
                <SignedRoute
                  path="/customers/users/:userId"
                  component={LicenseUserDetail}
                />
                <SignedRoute path="/orders/:orderId" component={Order} />
                <SignedRoute path="/software/:skuId" component={Software} />
                <SignedRoute path="/tasks/:task" component={Task} />
                <AdminRoute exact path="/customers" component={Customers} />
                <AdminRoute exact path="/invoices" component={Invoices} />
                <AdminRoute exact path="/users" component={Users} />
                <AdminRoute
                  path="/customers/:customerId/offers/:offerId"
                  component={OfferDetailAdmin}
                />
                <AdminRoute
                  path="/customers/:customerId"
                  component={Customer}
                />
                <AdminRoute path="/invoices/:invoiceId" component={Invoice} />
                <AdminRoute path="/users/:userId" component={User} />
              </Switch>
            </PrivateGate>
            <Redirect to="/" />
          </Switch>
        </HashRouter>
      </CookieAccepted>
    </>
  )
}
export default App
