import {
  // Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  // NavbarToggler,
  UncontrolledDropdown,
  Toast,
  ToastHeader,
  ToastBody,
  Button,
  Progress,
  NavLink,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { getTaskStatus } from "../lib/task"
//import {Link} from "react-router-dom"

class TaskNotification extends React.Component {
  state = { isOpen: true }
  render() {
    const { task, t } = this.props
    const { status, subStatus, currentTask } = getTaskStatus(task)
    console.log(status, subStatus, currentTask)

    let taskProgress = 0
    const taskCount = task.tasks.length
    const percentPerTask = 100 / taskCount
    const currentTaskObject = task.tasks.find(
      (t) => t.taskNumber === currentTask
    )
    if (status === "completed") {
      taskProgress = 100
    } else {
      taskProgress =
        percentPerTask * (currentTask - 1) +
        (percentPerTask * currentTaskObject.progress) / 100
    }

    return (
      <Toast isOpen={this.state.isOpen}>
        <ToastHeader toggle={() => this.setState({ isOpen: false })}>
          <strong className="mr-auto">{t(`_${task.friendlyName}`)}</strong>
        </ToastHeader>
        <ToastBody>
          {t("_status")}: {t(`_${subStatus}`)}
          {subStatus === "resolved" && String.fromCharCode(10004)}
          {subStatus === "rejected" && String.fromCharCode(10060)}
          {subStatus !== "rejected" && (
            <div className="mt-2">
              <div className="text-center">
                {task.status === "resolved"
                  ? `${task.tasks.length}/${task.tasks.length}`
                  : `${currentTask === 0 ? 0 : currentTask - 1}/${
                      task.tasks.length
                    }`}
              </div>
              <Progress value={taskProgress}></Progress>
            </div>
          )}
          <a href={`/#/tasks/${task._id}`}>{t("_showMore")}</a>
        </ToastBody>
      </Toast>
    )
  }
}
export default connect((s) => s)(withTranslation()(TaskNotification))
