import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
  FormFeedback,
  FormText,
  InputGroup,
  InputGroupText,
  Collapse,
  ButtonGroup,
  Spinner,
} from "reactstrap"

import { Loading } from "../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import countryISOCodes from "../../res/countryISOCodes"
import VismaStatus from "./VismaStatus"
import { startSign, userRedirected } from "../../actions/visma"

import Validator from "../../lib/validator"
import { locales } from "moment"

const getState = (state) => {
  return {
    billing: {
      address1: state.address1,
      address2: state.address2,
      culture: state.culture,
      state: state.state,
      city: state.city,
      language: state.language,
      postalCode: state.postalCode,
    },
  }
}
const timeout = (s) =>
  new Promise((resolve, reject) => {
    setTimeout(() => resolve(), s * 1000)
  })
class Billing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      address1: "",
      address2: "",
      culture: "",
      state: "",
      city: "",
      language: props.i18n.language === "fi-FI" ? "fi" : "en",
      postalCode: "",
      formErrors: {},
      advanced: false,
      locale: {},
      fetching: true,
      fetchingVismaLink: true,
      vismaLink: "",
      companyRegistrationNumber: "",
    }
    this.state = {
      ...this.state,
      ...props.data,
      companyRegistrationNumber: props.companyRegistrationNumber,
    }
    this.validator = new Validator({
      address1: {
        required: true,
      },
    })
  }
  componentDidMount() {
    axios
      .get(`/validationrules/${this.props.country}`)
      .then((resp) => resp.data)
      .then((data) => {
        this.setState({
          locale: data,
          fetching: false,
          culture: data.defaultCulture,
        })
        if (data.isCityRequired) {
          this.validator.add("city", { required: true })
        }
        if (data.isStateRequired) {
          this.validator.add("state", { required: true })
        }
        if (data.isPostalCodeRequired) {
          this.validator.add("postalCode", {
            required: true,
            match: {
              regex: data.postalCodeRegex,
              msg: `${this.props.t("_postalCode")} ${this.props.t(
                "_isInvalid"
              )}`,
            },
          })
        }
      })
      .catch((err) => {
        this.setState({ fetching: false })
      })

    this.props.startSign({
      language: this.state.language,
      token: this.state.token,
      companyRegistrationNumber: this.state.companyRegistrationNumber,
    })
  }
  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  validate = () => {
    const formErrors = this.validator.validate(this.state, this.props.t)
    if (Object.keys(formErrors).length !== 0) {
      this.setState({ formErrors })
      return false
    }
    this.setState({ formErrors })
    if (this.props.visma.status !== "signed") {
      return false
    }
    return true
  }

  previous = (e) => {
    e.preventDefault()
    this.props.previous(getState(this.state))
  }
  done = (e) => {
    e.preventDefault()
    if (this.props.visma.status !== "signed" || !this.validate()) return
    this.props.done(getState(this.state))
  }
  SignToVisma = async (e) => {
    e.preventDefault()
    if (
      this.props.visma.status !== "fetched" &&
      this.props.visma.status !== "signing"
    )
      return
    const width = Math.min(window.screen.availWidth, 1001)
    const height = Math.min(window.screen.availHeight * 0.8, 1050)
    const left = (window.screen.availWidth - width) / 2
    const top = (window.screen.availHeight - height) / 2
    window.open(
      this.props.visma.signUrl,
      "visma",
      `width=${width},height=${height},left=${left},top=${top},toolbar=0,status=0,`
    )

    this.props.userRedirected()
  }

  render() {
    const { t } = this.props
    if (this.state.fetching) {
      return <Loading />
    }
    return (
      <div className="container-fluid">
        <Form>
          <h5>{t("_billingInformation")}</h5>
          <Row>
            <Col>
              <FormGroup>
                <Label for="address1">
                  {t("_address1")} <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  value={this.state.address1}
                  onChange={this.handleChange}
                  placeholder={t("_address1")}
                  invalid={"address1" in this.state.formErrors}
                />
                <FormFeedback>{this.state.formErrors.address1}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="address2">{t("_address2")}</Label>
                <Input
                  type="text"
                  name="address2"
                  id="address2"
                  value={this.state.address2}
                  onChange={this.handleChange}
                  placeholder={t("_address2")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="city">
                  {t("_city")}{" "}
                  {this.state.locale.isCityRequired ? (
                    <span style={{ color: "#FF0000" }}>*</span>
                  ) : (
                    ""
                  )}
                </Label>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  value={this.state.city}
                  onChange={this.handleChange}
                  placeholder={t("_city")}
                  invalid={"city" in this.state.formErrors}
                />
                <FormFeedback>{this.state.formErrors.city}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="postalCode">
                      {t("_postalCode")}&nbsp;
                      {this.state.locale.isPostalCodeRequired ? (
                        <span style={{ color: "#FF0000" }}>*</span>
                      ) : (
                        ""
                      )}
                    </Label>
                    <Input
                      type="number"
                      name="postalCode"
                      id="postalCode"
                      value={this.state.postalCode}
                      onChange={this.handleChange}
                      placeholder={t("_postalCode")}
                      invalid={"postalCode" in this.state.formErrors}
                    />
                    <FormText></FormText>
                    <FormFeedback>
                      {this.state.formErrors.postalCode}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="state">
                      {t("_state")}&nbsp;
                      {!("supportedStatesList" in this.state.locale) ||
                      this.state.locale.supportedStatesList.length === 0 ? (
                        <span style={{ color: "#FF0000" }}>*</span>
                      ) : (
                        ""
                      )}
                    </Label>
                    <Input
                      type="select"
                      name="state"
                      id="state"
                      value={this.state.state}
                      onChange={this.handleChange}
                      placeholder={t("_state")}
                      invalid={"state" in this.state.formErrors}
                      disabled={
                        !("supportedStatesList" in this.state.locale) ||
                        this.state.locale.supportedStatesList.length === 0
                      }
                    >
                      {"supportedStatesList" in this.state.locale &&
                        this.state.locale.supportedStatesList.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                    </Input>
                    <FormFeedback>{this.state.formErrors.state}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <VismaStatus
            status={this.props.visma.status}
            sign={this.SignToVisma}
          />
          <div
            className="dropdown-toggle mb-3"
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.setState({
                advanced: !this.state.advanced,
              })
            }
          >
            {t("_advanced")}
          </div>
          <Collapse isOpen={this.state.advanced}>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="language">{t("_language")}</Label>
                  <Input
                    type="select"
                    name="language"
                    id="language"
                    value={this.state.language}
                    onChange={this.handleChange}
                  >
                    {this.state.locale.supportedLanguagesList.map((lang) => (
                      <option key={lang} value={lang}>
                        {lang}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="culture">{t("_culture")}</Label>
                  <Input
                    type="select"
                    name="culture"
                    id="culture"
                    value={this.state.culture}
                    onChange={this.handleChange}
                  >
                    {this.state.locale.supportedCulturesList.map((culture) => (
                      <option key={culture} value={culture}>
                        {culture}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Collapse>
          <Button color="primary" onClick={this.previous}>
            {t("_previous")}
          </Button>
          <Button className="float-right" color="primary" onClick={this.done}>
            {t("_submit")}
          </Button>
        </Form>
      </div>
    )
  }
}

export default connect((s) => s, { startSign, userRedirected })(
  withTranslation()(Billing)
)
