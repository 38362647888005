import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Badge,
  Input,
} from "reactstrap"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { durationToString } from "../lib/date"

const differentPlans = (offer) => {
  const plans = []
  for (const sku of offer.skus) {
    for (const eoffer of sku.offers) {
      if (!plans.includes(eoffer.billingPlan)) {
        plans.push(eoffer.billingPlan)
      }
    }
  }
  return plans
}
const differentDurations = (offer) => {
  const termDurations = {}
  for (const sku of offer.skus) {
    for (const eoffer of sku.offers) {
      if (!Object.keys(termDurations).includes(eoffer.termDuration)) {
        termDurations[eoffer.termDuration] = eoffer.termDuration
      }
    }
  }
  return Object.values(termDurations)
}

class Offers extends React.Component {
  state = { loading: true, offers: [], search: "" }

  handleSearchBar = ({ target }) => {
    this.setState({ [target.name]: target.value }, this.filter)
  }
  filter = () => {
    let offers = []
    if (this.state.search.length >= 1) {
      const searchArray = this.state.search
        .split(" ")
        .map((str) => str.trim().toLowerCase())
        .filter((str) => str !== "")
      for (const offer of this.props.offers) {
        let o = {
          offer: { ...offer },
          weight: 0,
        }
        if (
          offer.productTitle
            .toLowerCase()
            .startsWith(this.state.search.trim().toLowerCase())
        ) {
          o.weight += 100
        }
        if (
          offer.productTitle
            .toLowerCase()
            .includes(this.state.search.trim().toLowerCase())
        ) {
          o.weight += 20
        }
        if (offer.productTitle.toLowerCase().startsWith(searchArray[0])) {
          o.weight += 10
        }
        for (const searchKeyword of searchArray) {
          if (
            offer.productTitle.toLowerCase().split(" ").includes(searchKeyword)
          ) {
            o.weight += 1
          }
          if (offer.productTitle.toLowerCase().includes(searchKeyword)) {
            o.weight += 0.1
          }
        }
        if (o.weight !== 0) {
          offers.push(o)
        }
      }
    }
    offers.sort((a, b) => b.weight - a.weight)
    this.setState({ offers })
  }
  render() {
    const { t } = this.props
    const offers =
      this.state.offers.length === 0
        ? this.props.offers
        : this.state.offers.map((offer) => offer.offer)
    return (
      <div>
        <Input
          type="text"
          name="search"
          placeholder={t("_search")}
          onChange={this.handleSearchBar}
          value={this.state.search}
          className="p-4 mb-2"
        />
        {offers.map((product) => (
          <Card key={product._id}>
            <CardBody>
              <CardTitle tag="h5">{product.productTitle}</CardTitle>
              <CardText>{product.skus[0].description}</CardText>
              <Badge color="primary" className="mr-2">
                NCE
              </Badge>
              {differentPlans(product).map((plan) => (
                <Badge color="primary" key={plan} className="mr-2">
                  {t(`_${plan}`)} {t("_invoicing")}
                </Badge>
              ))}
              {differentDurations(product).map((plan) => (
                <Badge color="primary" key={plan} className="mr-2">
                  {durationToString(plan, t)}
                </Badge>
              ))}
              <a
                href={
                  this.props.link != null
                    ? this.props.link(product)
                    : `#/offers/${product._id}`
                }
                className="stretched-link"
              ></a>
            </CardBody>
          </Card>
        ))}
        <hr />
      </div>
    )
  }
}

export default withTranslation()(Offers)
