import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "./res/styles/style.css"
import "bootstrap-icons/font/bootstrap-icons.css"

import { Loading } from "./components"
import { Provider } from "react-redux"
import React, { Suspense } from "react"
import { I18nextProvider } from "react-i18next"
import { PersistGate } from "redux-persist/lib/integration/react"
import ReactDOM from "react-dom"
import configureStore from "./configureStore"
import i18n from "./i18n"
import { CookiesProvider } from "react-cookie"
import App from "./App"

const { store, persistor } = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <CookiesProvider>
          <Suspense fallback={<Loading />}>
            <App />
          </Suspense>
        </CookiesProvider>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
  document.querySelector("div#root")
)
