import { Button, Spinner } from "reactstrap"
import React from "react"

const SpinnerButton = (props) => {
  const { children, spin, ...otherProps } = props
  return (
    <Button
      style={
        spin
          ? {
              ...otherProps.style,
            }
          : {}
      }
      {...otherProps}
    >
      {children}
      <Spinner
        size="sm ml-1"
        className={`${spin ? "d-inline-block" : "d-none"}`}
      />
    </Button>
  )
}
export default SpinnerButton
