import "react-table/react-table.css"

import React from "react"
import ReactTable from "react-table"
import selectTableHOC from "react-table/lib/hoc/selectTable"
import { withTranslation } from "react-i18next"
import { Button, Row, Col } from "reactstrap"
import * as xlsx from "xlsx"
import { autofitColumns } from "../lib/excel"

const SelectTable = selectTableHOC(ReactTable)
class TranslatedTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sorting: [{ id: "invoiceDate", desc: true }],
    }
    this.reactTable = React.createRef()
  }

  exportToExcel = () => {
    const reactTable = this.props.isSelect
      ? this.reactTable.current.getWrappedInstance()
      : this.reactTable.current
    const columns = reactTable
      .getResolvedState()
      .columns.filter(
        (column) => column.id !== "_selector" && !column.excludeFromExcel
      )
    const data = reactTable.getResolvedState().sortedData

    const headers = columns.map(
      (column) =>
        (typeof column?.Header === "string" && column.Header) ||
        (typeof column?.accessor === "string" && column.accessor) ||
        column.id
    )
    const accessors = columns.map(
      (column) =>
        column.id || (typeof column?.accessor === "string" && column.accessor)
    )
    const rows = data.map((row) => {
      const item = {}
      for (const header of accessors) {
        item[header] = row[header]
      }
      return item
    })

    const worksheet = xlsx.utils.json_to_sheet(rows)

    autofitColumns(rows, worksheet)

    const workbook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(workbook, worksheet, "Data")

    // download
    const blob = new Blob([xlsx.write(workbook, { type: "array" })], {
      type: "application/octet-stream",
    })
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, `data-${Date.now()}.xlsx`)
    } else {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = `data-${Date.now()}.xlsx`
      document.body.appendChild(a)
      a.click()
      setTimeout(() => {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }, 0)
    }
  }

  exportToCSV = () => {
    const reactTable = this.props.isSelect
      ? this.reactTable.current.getWrappedInstance()
      : this.reactTable.current
    const columns = reactTable.getResolvedState().columns
    const data = reactTable.getResolvedState().sortedData

    let dataToDownload = []
    for (let i = 0; i < data.length; i++) {
      let recordToDownload = {}
      for (let j = 0; j < columns.length; j++) {
        recordToDownload[columns[j].Header] = data[i][columns[j].accessor]
      }
      dataToDownload.push(recordToDownload)
    }

    if (dataToDownload.length !== 0) {
      let csv = Object.keys(dataToDownload[0]).join(";")
      csv += "\r\n"

      dataToDownload.forEach((object) => {
        csv += Object.values(object).join(";")
        csv += "\r\n"
      })

      let csvName = `csv${Date.now()}.csv`

      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
      if (window.navigator.msSaveOrOpenBlob)
        // IE10+
        window.navigator.msSaveOrOpenBlob(blob, csvName)
      else {
        // Others
        let a = document.createElement("a"),
          url = URL.createObjectURL(blob)
        a.href = url
        a.download = csvName
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      }
    }
  }

  render() {
    const { t, isSelect } = this.props

    const keywords = {
      previousText: t("_previous"),
      nextText: t("_next"),
      loadingText: t("_loading"),
      noDataText: t("_noRowsFound"),
      pageText: t("_page"),
      ofText: t("_of"),
      rowsText: t("_rows"),
    }

    const filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id
      var isBool = false
      if (typeof row[id] === "boolean") {
        isBool = true
      }
      if (typeof row[id] === "number") {
        return row[id] !== undefined
          ? String(row[id]).startsWith(filter.value)
          : true
      }
      if (isBool) {
        return row[id] !== undefined
          ? (filter.value.toLowerCase().startsWith("y") && row[id] === true) ||
              (filter.value.toLowerCase().startsWith("n") && row[id] === false)
          : true
      } else if (row[id] !== undefined)
        return row[id] !== undefined
          ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          : true
    }
    const Table = isSelect ? SelectTable : ReactTable

    return (
      <>
        <Table
          data={this.props.data}
          sorted={this.state.sorting}
          onSortedChange={(val) => {
            this.setState({ sortOptions: val })
          }}
          loading={this.props.fetching}
          columns={this.props.columns}
          pageSize={this.props.listSize}
          noDataText={this.props.noDataText}
          className="-striped -highlight"
          onPageSizeChange={(e) => this.props.handleresize(e)}
          {...keywords}
          filterable={this.props.filterable}
          defaultFilterMethod={(filter, row) =>
            filterCaseInsensitive(filter, row)
          }
          ref={this.reactTable}
          toggleAll={this.props.toggleAll}
          toggleSelection={this.props.toggleSelection}
          selected={this.props.selected}
          selectAll={this.props.selectAll}
          isSelected={this.props.isSelected}
          keyField={this.props.keyField || "_id"}
          SelectInputComponent={this.props.SelectInputComponent}
        />
        <Row>
          <Col sm="12">
            <Button
              className="float-left"
              color="primary"
              onClick={this.exportToExcel}
              disabled={this.props.fetching}
            >
              {t("_exportExcel")}
            </Button>
            {this.props.customButton}
          </Col>
        </Row>
      </>
    )
  }
}

export default withTranslation(undefined, { withRef: true })(TranslatedTable)
