export default class Validator {
  constructor(fieldRequirements) {
    this.fieldRequirements = fieldRequirements
  }

  add = (field, requirements) => (this.fieldRequirements[field] = requirements)

  validateOne = (state, t, formErrors, field) => {
    if (field in formErrors) {
      delete formErrors[field]
    }
    const requirements = this.fieldRequirements[field]
    if ("required" in requirements) {
      if (state[field] === "") {
        formErrors[field] = `${t("_" + field)} ${t("_cantBeEmpty")}`
        return formErrors
      }
    }
    if ("minLength" in requirements) {
      if (state[field].length < requirements.minLength) {
        formErrors[field] = `${t("_" + field)} ${t("_tooShort")}`
        return formErrors
      }
    }
    if ("match" in requirements) {
      if (state[field].match(requirements.match.regex) === null) {
        formErrors[field] = requirements.match.msg
        return formErrors
      }
    }
    if ("sameAs" in requirements) {
      if (state[field] !== state[requirements.sameAs]) {
        formErrors[field] = `${t("_" + field)} ${t("_sameAs")} ${t(
          "_" + requirements.sameAs
        )}`
        return formErrors
      }
    }
    if ("custom" in requirements) {
      if (!requirements.custom.func(state[field])) {
        formErrors[field] = requirements.custom.msg
        return formErrors
      }
    }
    return formErrors
  }

  validate = (state, t) => {
    const formErrors = {}
    for (const field of Object.keys(this.fieldRequirements)) {
      const requirements = this.fieldRequirements[field]
      if ("required" in requirements) {
        if (state[field] === "") {
          formErrors[field] = `${t("_" + field)} ${t("_cantBeEmpty")}`
          continue
        }
      }
      if ("minLength" in requirements) {
        if (state[field].length < requirements.minLength) {
          formErrors[field] = `${t("_" + field)} ${t("_tooShort")}`
          continue
        }
      }
      if ("match" in requirements) {
        if (state[field].match(requirements.match.regex) === null) {
          formErrors[field] = requirements.match.msg
          continue
        }
      }

      if ("checked" in requirements) {
        if (state[field] !== requirements.checked) {
          formErrors[field] = t("_mustBeChecked")
          return formErrors
        }
      }
      if ("sameAs" in requirements) {
        if (state[field] !== state[requirements.sameAs]) {
          formErrors[field] = `${t("_" + field)} ${t("_sameAs")} ${t(
            "_" + requirements.sameAs
          )}`
          continue
        }
      }
      if ("custom" in requirements) {
        if (!requirements.custom.func(state[field])) {
          formErrors[field] = requirements.custom.msg
          continue
        }
      }
    }
    return formErrors
  }
}
