import { Alert, Button, Col, Row } from "reactstrap"
import { fetchResources, setlistsize } from "../../actions"

import { Link } from "react-router-dom"
import {
  PageContainer,
  TranslatedTable,
  CreateUserModal,
} from "../../components"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class LicenseUsers extends React.Component {
  state = { creatingUser: false, message: "", success: false }

  componentDidMount = () => {
    this.props.fetchResources({ resource: "licenseusers", force: true })
  }

  toggle = (target) => {
    this.setState({
      [target]: !this.state[target],
    })
  }
  userCreated = (success, code = 0) => {
    this.toggle("creatingUser")
  }
  render() {
    const { t } = this.props
    const addUserButton = this.props.user.isClientAdmin ? (
      <Button
        className="float-right"
        color="primary"
        onClick={() => this.toggle("creatingUser")}
      >
        {t("_createUser")}
      </Button>
    ) : null
    const columnsUser = [
      {
        Header: t("_displayName"),
        accessor: "displayName",
        Cell: (props) => (
          <Link to={`/customers/users/${props.original.id}`}>
            {props.value}
          </Link>
        ),
      },
      {
        Header: t("_username"),
        accessor: "userPrincipalName",
      },
      {
        Header: t("_status"),
        accessor: "state",
      },
    ]
    return (
      <PageContainer
        title={t("_users")}
        loading={!this.props.licenseusers.licenseusers.length}
      >
        {this.state.message !== "" ? (
          <Alert color={this.state.success ? "success" : "danger"}>
            {this.state.message}
          </Alert>
        ) : null}
        <hr />
        <CreateUserModal
          modalOpen={this.state.creatingUser}
          userCreated={this.userCreated}
          toggle={() => this.toggle("creatingUser")}
        ></CreateUserModal>

        <h3>{t("_users")}</h3>
        <TranslatedTable
          columns={columnsUser}
          data={this.props.licenseusers.licenseusers.filter((u) => !u.isAdmin)}
          fetching={this.props.licenseusers.fetching}
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          customButton={addUserButton}
          filterable={true}
        />

        <hr />
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources, setlistsize })(
  withTranslation()(LicenseUsers)
)
