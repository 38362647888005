export const ACTION_TYPES = Object.freeze({
  NEW_TASK: "NEW_TASK",
  STATUS_UPDATE: "STATUS_UPDATE",
  SET_TASK: "SET_TASK",
})
export const initialState = {
  tasks: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.NEW_TASK:
      return {
        ...state,
        tasks: state.tasks.concat([
          {
            ...action.task,
            id: action.task._id,
            showNotification: action.task.showNotification,
          },
        ]),
      }
    case ACTION_TYPES.STATUS_UPDATE:
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task.id === action.id ? { ...task, ...action.task } : task
        ),
      }
    case ACTION_TYPES.SET_TASK:
      return {
        ...state,
        tasks: state.tasks
          .filter((task) => task._id !== action.task._id)
          .concat([action.task]),
      }
    case "LOGOUT":
      return {
        ...initialState,
      }
    default: {
      return state
    }
  }
}
