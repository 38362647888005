import { Spinner } from "reactstrap"

import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
//import countryISOCodes from "../../res/countryISOCodes"
import Validator from "../../lib/validator"

// status = initial | started | done
class VismaStatus extends React.Component {
  render() {
    const { t, title, text, onStart, status, waitingLink } = this.props
    return (
      <div>
        {title} <br />
        <a
          href="#"
          style={
            status === "done" ? { color: "gray", pointerEvents: "none" } : {}
          }
          onClick={onStart}
        >
          {text}
        </a>
        {(status === "started" || waitingLink) && (
          <Spinner size="sm" className="mr-1" />
        )}
        {status === "done" && String.fromCharCode(10004)}
      </div>
    )
  }
}

export default connect((s) => s)(withTranslation()(VismaStatus))
