export const ACTION_TYPES = Object.freeze({
  CUSTOMER_LISTSIZE_SET: "CUSTOMER_LISTSIZE_SET",
})

export const initialState = {
  listSetting: 20,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CUSTOMER_LISTSIZE_SET:
      return {
        ...state,
        listSetting: action.listsize,
      }
    default: {
      return state
    }
  }
}
