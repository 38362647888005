import React, { useEffect } from "react"
import VismaStatus from "./VismaStatus"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { startSign } from "../../actions/visma"
import { useDispatch } from "react-redux"

// use hooks
const redirectUser = (link) => {
  const width = Math.min(window.screen.availWidth, 1001)
  const height = Math.min(window.screen.availHeight * 0.8, 1050)
  const left = (window.screen.availWidth - width) / 2
  const top = (window.screen.availHeight - height) / 2
  window.open(
    link,
    "visma",
    `width=${width},height=${height},left=${left},top=${top},toolbar=0,status=0,`
  )
}

// // status: 'initial' | 'fetching' | 'fetched' | 'signing' | 'signed' | 'error'

const statusMapper = {
  initial: "initial",
  fetching: "initial",
  fetched: "initial",
  signing: "started",
  signed: "done",
  error: "initial",
}
const VismaSign = (props) => {
  const visma = useSelector((s) => s.visma)
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const language = i18n.language === "fi-FI" ? "fi" : "en"

  useEffect(() => {
    dispatch(
      startSign({
        language,
        token: props.token,
        companyRegistrationNumber: props.companyRegistrationNumber,
      })
    )
  }, [])

  return (
    <VismaStatus
      onStart={(e) => {
        e.preventDefault()
        if (visma.status !== "fetched" && visma.status !== "signing") return
        redirectUser(visma.signUrl)
      }}
      status={statusMapper[visma.status]}
      title={t("_signRequired")}
      text={t("_startSigningProcess")}
    />
  )
}

export default VismaSign
