import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { fail } from "../../actions"

function SoftwareDetails(props) {
  const { software, t } = props
  const { entitlement, sku } = software

  return (
    <dl>
      <dt>{t("_title")}</dt>
      <dd>{sku.title}</dd>
      <dt>{t("_productId")}</dt>
      <dd>{entitlement.productId}</dd>
      <dt>{"SKU"}</dt>
      <dd>{entitlement.skuId}</dd>
      <dt>{t("_quantity")}</dt>
      <dd>{entitlement.quantity}</dd>
      <dt>{t("_description")}</dt>
      <dd>{sku.description}</dd>
    </dl>
  )
}

export default connect(null, { fail })(withTranslation()(SoftwareDetails))
