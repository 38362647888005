import React, { useMemo, useState } from "react"
import Loading from "./Loading"

const MultiPageHandler = (props) => {
  const [page, setPage] = useState(0)
  const { loading } = props

  const component = useMemo(() => {
    return props.children[page]
  }, [page])

  const [data, setData] = useState({})

  const next = (d) => {
    setData({
      ...data,
      [page]: d,
    })

    if (page + 1 !== props.children.length) {
      setPage(page + 1)
    }
  }

  const previous = (d) => {
    setData({
      ...data,
      [page]: d,
    })
    setPage(page - 1)
  }

  if (loading) {
    return <Loading />
  }
  return (
    <div>
      {React.cloneElement(component, {
        ...props,
        next,
        previous,
        data: data[page],
        allData: data,
      })}
    </div>
  )
}

export default MultiPageHandler
