import {
  Container,
  ListGroup,
  ListGroupItem,
  Collapse,
  Button,
  Alert,
  Input,
  Spinner,
  Badge,
} from "reactstrap"

import { withTranslation } from "react-i18next"
import { PageContainer } from "../../components"
import { fetchResources, receive } from "../../actions"
import { connect, useDispatch } from "react-redux"
import React from "react"
import axios from "axios"
import moment from "moment"

const isLicenseAlreadyAssigned = (license, licenses) => {
  for (const assignedLicense of licenses) {
    if (assignedLicense.productSku.id === license.productSku.id) {
      return true
    }
  }
  return false
}

class LicenseUserDetail extends React.Component {
  state = {
    fetching: true,
    status: {
      msg: "",
      color: "",
    },
    user: {
      licenses: [],
    },
    licenses: {},
    actingOn: [],
    unavailableLicenses: {},
    orders: {},
  }

  componentDidMount = () => {
    this.updateLicenses()
  }

  componentDidUpdate = () => {}

  getOrdersForSubscription = (subscription) => {
    const orders = this.props.orders.orders.filter(
      (order) =>
        order.name.toLowerCase() === subscription.productSku.name.toLowerCase()
    )
    return orders
  }
  handleOrderChange = (e, license) => {
    this.setState(
      {
        orders: {
          ...this.state.orders,
          [license]: e.target.value,
        },
      },
      () => console.log(this.state.chosenOrders)
    )
  }

  updateLicenses = async () => {
    this.setState({
      fetching: true,
    })
    Promise.all([
      this.getLicenses(),
      this.props.fetchResources({ resource: "subscribed", force: true }),
      this.props.fetchResources({ resource: "orders", force: true }),
    ]).then((data) => {
      const licenses = {}
      const unavailableLicenses = {}
      const user = data[0].user
      const orders = {}
      this.props.subscribed.subscribed
        .filter((license) => !isLicenseAlreadyAssigned(license, user.licenses))
        .forEach((license) => {
          if (license.availableUnits === 0) {
            unavailableLicenses[license.productSku.id] = false
            const subOrders = this.getOrdersForSubscription(license)
            if (subOrders.length > 0) {
              orders[license.productSku.id] = subOrders[0].id
            }
          } else {
            licenses[license.productSku.id] = false
          }
        })
      this.setState({
        user,
        fetching: false,
        licenses,
        unavailableLicenses,
        orders,
      })
    })
  }

  getLicenses = async () => {
    const { data } = await axios.get(
      `/licenseusers/${this.props.match.params.userId}`,
      {
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,
        },
      }
    )
    return data
  }

  getAvailableLicenses = () => {
    return this.props.subscribed.subscribed.filter(
      (license) =>
        !isLicenseAlreadyAssigned(license, this.state.user.licenses) &&
        license.availableUnits > 0
    )
  }

  getUnavailableLicenses = () => {
    return this.props.subscribed.subscribed.filter(
      (license) =>
        !isLicenseAlreadyAssigned(license, this.state.user.licenses) &&
        license.availableUnits === 0
    )
  }

  assignLicenses = () => {
    const actingOn = this.state.actingOn
    const licenses = []
    const licensesChosen = { ...this.state.licenses }
    for (const id of Object.keys(this.state.licenses)) {
      if (this.state.licenses[id]) {
        licenses.push(id)
        licensesChosen[id] = false
        actingOn.push(id)
      }
    }
    if (licenses.length === 0) return
    this.setState({
      actingOn,
      licenses: licensesChosen,
    })
    axios
      .post(
        `/licenses/user/${this.props.match.params.userId}`,
        { licenses },
        {
          headers: {
            Authorization: `Bearer ${this.props.user.token}`,
          },
        }
      )
      .then(() => {
        const user = this.state.user
        user.licenses = [
          ...user.licenses,
          ...this.props.subscribed.subscribed.filter((s) =>
            licenses.includes(s.productSku.id)
          ),
        ]
        this.setState({
          user,
        })
      })
      .catch((err) =>
        this.setState({
          status: {
            color: "danger",
            msg: this.props.t("_error"),
          },
        })
      )
      .finally(() =>
        this.setState({
          actingOn: this.state.actingOn.filter((id) => !licenses.includes(id)),
        })
      )
  }
  buyLicenses = async () => {
    const licensesToBuy = Object.entries(this.state.unavailableLicenses)
      .filter(([licenseId, selected]) => selected)
      .map(([licenseId, selected]) => licenseId)
    this.setState({
      actingOn: [...this.state.actingOn, ...licensesToBuy],
      unavailableLicenses: Object.fromEntries(
        Object.entries(this.state.unavailableLicenses).map(
          ([licenseId, selected]) => [licenseId, false]
        )
      ),
    })
    try {
      const { data } = await axios.post("/tasks/create/orderandassign", {
        orders: licensesToBuy.map((licenseId) => ({
          license: licenseId,
          order: this.state.orders[licenseId],
        })),
        userId: this.props.match.params.userId,
      })
    } catch (err) {
      console.log(err)
    } finally {
      this.setState({
        actingOn: this.state.actingOn.filter(
          (id) => !licensesToBuy.includes(id)
        ),
      })
    }
  }

  removeLicense = (sku) => {
    const actingOn = this.state.actingOn
    actingOn.push(sku)
    this.setState({
      actingOn,
    })
    axios
      .delete(`/licenses/${sku}/user/${this.props.match.params.userId}`, {
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,
        },
      })
      .then((data) => {
        const user = this.state.user
        const licenses = this.state.licenses
        for (let i = 0; i < user.licenses.length; i++) {
          if (user.licenses[i].productSku.id === sku) {
            licenses[sku] = false
            user.licenses.splice(i, 1)
            break
          }
        }
        this.setState({
          licenses,
          user,
        })
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          status: {
            color: "danger",
            msg: this.props.t("_error"),
          },
        })
      })
      .finally(() => {
        this.setState({
          actingOn: this.state.actingOn.filter((id) => id !== sku),
        })
      })
  }

  render = () => {
    const { t } = this.props
    return (
      <PageContainer
        title={this.state.user.displayName}
        loading={this.state.fetching}
      >
        <Alert color={this.state.status.color}>{this.state.status.msg}</Alert>
        <h3>{t("_licenses")}</h3>
        <ListGroup className="mb-3">
          {this.state.user.licenses.length === 0 ? (
            <h5>{t("_none")}</h5>
          ) : (
            this.state.user.licenses.map((license) => (
              <ListGroupItem
                key={license.productSku.name}
                className="d-flex justify-content-between rounded"
              >
                <p className="text-center my-1">{license.productSku.name}</p>
                <Button
                  onClick={() => this.removeLicense(license.productSku.id)}
                  color="danger"
                  disabled={this.state.actingOn.includes(license.productSku.id)}
                >
                  <Spinner
                    size="sm"
                    className={`mr-1 ${
                      this.state.actingOn.includes(license.productSku.id)
                        ? "visible"
                        : "invisible"
                    }`}
                  />
                  {t("_removeLicense")}
                </Button>
              </ListGroupItem>
            ))
          )}
        </ListGroup>
        <h3 className="mb-3">{t("_availableLicenses")}</h3>
        {this.getAvailableLicenses().length === 0 ? (
          <h5>{t("_none")}</h5>
        ) : (
          this.getAvailableLicenses().map((license) => (
            <ListGroupItem
              disabled={this.state.actingOn.includes(license.productSku.id)}
              key={license.productSku.name}
              className="d-flex justify-content-between align-items-center border rounded"
              action
              tag="button"
              active={this.state.licenses[license.productSku.id]}
              onClick={() => {
                const licenses = this.state.licenses
                licenses[license.productSku.id] =
                  !this.state.licenses[license.productSku.id]
                this.setState({ licenses })
              }}
            >
              <p className="text-center my-1">{license.productSku.name}</p>
              <div>
                <Badge color="primary" pill>
                  {t("_availableLicensesAmount", {
                    count: license.availableUnits,
                  })}
                </Badge>
                <Spinner
                  size="sm"
                  className={`mr-1 ${
                    this.state.actingOn.includes(license.productSku.id)
                      ? "visible"
                      : "invisible"
                  }`}
                />
              </div>
            </ListGroupItem>
          ))
        )}
        <Button
          color="primary"
          className="mt-3 float-right"
          onClick={this.assignLicenses}
          disabled={
            Object.values(this.state.licenses).filter((s) => s).length === 0 ||
            this.state.actingOn.some((s) =>
              this.getAvailableLicenses()
                .map((license) => license.productSku.id)
                .includes(s)
            )
          }
        >
          {t("_assignChosenLicenses")}
        </Button>
        <br />

        <h3 className="my-3">{t("_unavailableLicenses")}</h3>
        {this.getUnavailableLicenses().length === 0 ? (
          <h5>{t("_none")}</h5>
        ) : (
          <ListGroup>
            {this.getUnavailableLicenses().map((license) => (
              <ListGroupItem
                key={license.productSku.name}
                className="d-flex justify-content-between align-items-center border rounded"
                disabled={this.state.actingOn.includes(license.productSku.id)}
                action
                tag="button"
                active={this.state.unavailableLicenses[license.productSku.id]}
                onClick={(e) => {
                  if (e.target.nodeName === "SELECT") return
                  const unavailableLicenses = this.state.unavailableLicenses
                  unavailableLicenses[license.productSku.id] =
                    !this.state.unavailableLicenses[license.productSku.id]
                  this.setState({ unavailableLicenses })
                }}
              >
                <p className="text-center my-1">{license.productSku.name}</p>
                <div className="d-flex justify-content-between align-items-center">
                  <Spinner
                    size="sm"
                    className={`mr-3 ${
                      this.state.actingOn.includes(license.productSku.id)
                        ? "visible"
                        : "invisible"
                    }`}
                  />
                  <Input
                    type="select"
                    name="order"
                    value={this.state.orders[license.productSku.id]}
                    onChange={(e) =>
                      this.handleOrderChange(e, license.productSku.id)
                    }
                  >
                    {this.getOrdersForSubscription(license).map((order) => (
                      <option key={order.id} value={order.id}>{`${
                        "productType" in order &&
                        order.productType.id === "OnlineServicesNCE"
                          ? "NCE "
                          : ""
                      }${order.termDuration} ${order.billingCycle}`}</option>
                    ))}
                  </Input>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        <Button
          color="primary"
          className="mt-3 float-right"
          onClick={this.buyLicenses}
          disabled={
            Object.values(this.state.unavailableLicenses).filter((s) => s)
              .length === 0
          }
        >
          {`${t("_buyLicenses")} ${t("_and")} ${t(
            "_assignChosenLicenses"
          ).toLowerCase()}`}
        </Button>
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources, receive })(
  withTranslation()(LicenseUserDetail)
)
