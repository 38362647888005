export const ACTION_TYPES = Object.freeze({
  LOGIN: "LOGIN",
  RELOGIN_REQUIRED: "RELOGIN_REQUIRED",
  UPDATE: "UPDATE",
  TOKEN_REQUIRED: "TOKEN_REQUIRED",
  LOGOUT: "LOGOUT",
})

export const initialState = {
  loggedIn: false,
  email: "",
  isAdmin: false,
  isClientAdmin: false,
  token: null,
  loggedOut: false,
  customer: null,
  signed: "",
  tokenRequired: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGOUT:
      return {
        loggedIn: false,
        loggedOut: true,
      }
    case ACTION_TYPES.TOKEN_REQUIRED:
      return {
        ...state,
        tokenRequired: true,
      }
    case ACTION_TYPES.LOGIN:
      return {
        loggedIn: true,
        loggedOut: false,
        email: action.email,
        isAdmin: action.isAdmin,
        isClientAdmin: action.isClientAdmin,
        token: action.token,
        customer: action.customer,
        signed: action.signed,
        tokenRequired: false,
      }
    case ACTION_TYPES.UPDATE:
      return {
        ...state,
        email: action.email,
        isAdmin: action.isAdmin,
        isClientAdmin: action.isClientAdmin,
        customer: action.customer,
        signed: action.signed,
      }
    case ACTION_TYPES.RELOGIN_REQUIRED:
      return {
        ...state,
        token: null,
      }
    default: {
      return state
    }
  }
}
