import { Loading } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { fetchResources } from "../../actions"
import moment from "moment"

import ReactChartkick, { ColumnChart } from "react-chartkick"

import Chart from "chart.js"

ReactChartkick.addAdapter(Chart)

class SummaryChart extends React.Component {
  componentDidMount = () => this.props.fetchResources({ resource: "summary" })

  render() {
    let { t, summary, fetching } = this.props
    let data = []
    if (fetching) {
      return <Loading />
    }
    if (!fetching && summary != null && Object.keys(summary).length !== 0) {
      data.push({
        name: "Azure",
        data: summary.history.map((x) => [x.invoiceMonth, x.azure]),
      })
      data.push({
        name: "O365",
        data: summary.history.map((x) => [x.invoiceMonth, x.office]),
      })
    }
    return (
      <div>
        <h3>{t("_lastInvoices")}</h3>
        <ColumnChart
          data={data}
          stacked={true}
          legend="bottom"
          empty="no data"
          suffix="€"
          colors={["DodgerBlue", "coral"]}
        />
      </div>
    )
  }
}

export default connect((s) => s.summary, { fetchResources })(
  withTranslation()(SummaryChart)
)
