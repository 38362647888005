import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Badge,
  Input,
  Label,
  FormGroup,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { durationToString } from "../lib/date"
import moment from "moment"

class OfferDetail extends React.Component {
  state = {
    sku: "",
    chosenPlan: "",
    quantity: "",
    fetching: false,
    msg: "",
    success: true,
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  order = async (e) => {
    e.preventDefault()
    const { offer } = this.props
    console.log(offer)
    const sku =
      offer.skus.filter((s) => s.skuId === this.state.sku)[0] || offer.skus[0]
    const order = sku.offers.filter((s) => s._id === this.state.chosenPlan)[0]
    const data = {
      nce: true,
      productId: offer.productId,
      sku: sku._id,
      offer: order._id,
      quantity: this.state.quantity,
    }
    this.setState({
      fetching: true,
    })
    try {
      await this.props.order(data)
      this.setState({
        fetching: false,
        success: true,
        msg: this.props.t("_successNCEbought"),
        chosenPlan: "",
        quantity: "0",
      })
    } catch (err) {
      this.setState({
        fetching: false,
        success: false,
        msg: this.props.t("_error"),
      })
    }
  }

  render() {
    const { t } = this.props
    const product = this.props.offer
    const sku =
      product.skus.filter((s) => s.skuId === this.state.sku)[0] ||
      product.skus[0]
    const offers = sku.offers
    offers.sort((a, b) => a.termDuration - b.termDuration)
    const monthlyPlan = sku.offers.filter((o) => o.termDuration === 1)[0]
    const offer = sku.offers.filter((s) => s._id === this.state.chosenPlan)[0]
    return (
      <div>
        {this.state.msg !== "" && (
          <Alert color={this.state.success ? "success" : "danger"}>
            {this.state.msg}
          </Alert>
        )}
        <Form>
          <FormGroup>
            <Input
              className="mb-3"
              name="sku"
              type="select"
              value={sku.skuId}
              onChange={this.handleChange}
            >
              {product.skus.map((sku) => (
                <option key={sku.skuId} value={sku.skuId}>
                  {sku.title}
                </option>
              ))}
            </Input>
          </FormGroup>
          <p>{sku.description}</p>
          <h4 className="mb-3">{t("_choosePlan")}</h4>
          {offers.map((offer, index, array) => {
            const lastOffer = array[index - 1] || null
            return (
              <div key={offer._id}>
                {(lastOffer === null ||
                  lastOffer.termDuration !== offer.termDuration) && (
                  <h5 className="my-3">
                    {durationToString(offer.termDuration, t)}
                  </h5>
                )}
                <Card
                  className={
                    this.state.chosenPlan === offer._id ? "border-primary" : ""
                  }
                >
                  <CardBody>
                    <CardTitle tag="h6">
                      {t(`_${offer.billingPlan}`)} {t("_invoicing")}
                    </CardTitle>
                    <CardText className="float-right">
                      {t("_unitPrice")}: {offer.erpPrice} {offer.currency}{" "}
                      <br />
                      {offer.termDuration > 1
                        ? `${
                            Math.round(
                              (offer.erpPrice / offer.termDuration +
                                Number.EPSILON) *
                                100
                            ) / 100
                          } ${offer.currency}/${t("_monthShort")}`
                        : ""}
                    </CardText>
                    <a
                      className="stretched-link btn m-0 p-0"
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({
                          chosenPlan:
                            this.state.chosenPlan === offer._id
                              ? ""
                              : offer._id,
                        })
                      }}
                    ></a>
                    {offer.termDuration !== 1 && monthlyPlan && (
                      <Badge color="primary">
                        {t("_saveMoney")}{" "}
                        {Math.round(
                          (1 -
                            offer.erpPrice /
                              offer.termDuration /
                              monthlyPlan.erpPrice) *
                            100
                        )}
                        %
                      </Badge>
                    )}
                  </CardBody>
                </Card>
              </div>
            )
          })}
          <Label className="mt-3 h4" for="quantity">
            {t("_quantity")}
          </Label>
          <Row className="justify-content-between">
            <Col>
              <Input
                id="quantity"
                name="quantity"
                type="number"
                onChange={this.handleChange}
              />
            </Col>
            <Col className="text-center d-flex justify-content-center">
              <div className="align-self-center">{`${
                this.state.chosenPlan !== "" &&
                this.state.quantity.match(/^\d+$/)
                  ? `${t("_total")} ${
                      Math.round(
                        (offer.erpPrice * this.state.quantity +
                          Number.EPSILON) *
                          100
                      ) / 100
                    } ${offer.currency}`
                  : ""
              }`}</div>
            </Col>
            <Col>
              <Button
                className="btn-block"
                onClick={this.order}
                color="primary"
                disabled={
                  this.state.chosenPlan === "" ||
                  !this.state.quantity.match(/^\d+$/) ||
                  this.state.fetching
                }
              >
                {t("_buy")}
                <Spinner
                  className="ml-3"
                  size="sm"
                  style={{
                    display: this.state.fetching ? "inline-block" : "none",
                  }}
                ></Spinner>
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default withTranslation()(OfferDetail)
