import {
  Alert,
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap"

import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

class AdminSettings extends React.Component {
  state = { fetching: false, logEnabled: false, nceState: 0 } // 0 not started 1 fetching 2 success 3 error

  componentDidMount() {
    this.updateState()
  }
  updateState = async () => {
    this.setState({ fetching: true })
    axios
      .get("/users/me/updated")
      .then((resp) =>
        this.setState({
          logEnabled: resp.data.user.logEnabled,
          fetching: false,
        })
      )
      .catch((err) => {
        console.log(err)
        this.setState({ fetching: false })
      })
  }

  sync = async (e) => {
    e.preventDefault()
    this.setState({ fetching: true })
    const checked = e.target.checked
    axios
      .patch(`/users/me`, {
        logEnabled: checked,
      })
      .then(() => this.setState({ fetching: false, logEnabled: checked }))
      .catch((err) => this.updateState())
  }
  render = () => {
    const { t } = this.props
    if (!this.props.user.isAdmin) {
      return ""
    }
    return (
      <Card className="mt-3">
        <CardBody>
          <h3>{t("_adminSettings")}</h3>
          <Form>
            <FormGroup check>
              <Label check>
                <Input
                  disabled={this.state.fetching}
                  type="checkbox"
                  checked={this.state.logEnabled}
                  onChange={this.sync}
                />
                {t("_logEnabled")}
              </Label>
            </FormGroup>
          </Form>
          <Button
            className="mt-3"
            disabled={this.state.nceState === 1}
            onClick={(e) => {
              this.setState({ nceState: 1 })
              axios
                .post("/tasks/updatenceoffers")
                .then(() => this.setState({ nceState: 2 }))
                .catch(() => this.setState({ nceState: 3 }))
            }}
            block
          >
            {t("_updateNCE")}
          </Button>
          {this.state.nceState > 1 && (
            <small
              className={
                this.state.nceState === 2 ? "text-success" : "text-danger"
              }
            >
              {this.state.nceState === 2 ? t("_success") : t("_fail")}
            </small>
          )}
        </CardBody>
      </Card>
    )
  }
}

export default connect((s) => s, {})(withTranslation()(AdminSettings))
