import Login from "../pages/Login"
import React from "react"
import { connect } from "react-redux"

class PrivateGate extends React.Component {
  render = () => {
    if (!this.props.loggedIn || (!this.props.token && this.props.loggedIn)) {
      return <Login />
    }

    return this.props.children
  }
}

export default connect((s) => s.user)(PrivateGate)
