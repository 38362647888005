import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import { useTranslation } from "react-i18next"

const ConfirmActionModal = ({ title, message, open, toggle, onConfirm }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          {t("_cancel")}
        </Button>
        <Button color="primary" onClick={onConfirm}>
          {t("_yes")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default ConfirmActionModal
