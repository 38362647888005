import { Col, Row } from "reactstrap"

import { PageContainer, MFA } from "../../components"
import PasswordChange from "./PasswordChange"
import AdminSettings from "./AdminSettings"
import React from "react"

export default () => (
  <PageContainer title="Settings">
    <Row>
      <Col sm="6">
        <PasswordChange />
      </Col>

      <Col sm="6">
        <MFA />
        <AdminSettings></AdminSettings>
      </Col>
    </Row>
  </PageContainer>
)
