import React, { useEffect } from "react"
import { Button, Container } from "reactstrap"
import VismaSign from "../VismaSign"
import { PageContainer } from "../../../components"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { fetchResources } from "../../../actions"
import VismaStatus from "../VismaStatus"
import { pollStatusAtInterval, redirected } from "../../../actions/register"

// initial | redirected | accepted | registering | success | error

const statusMapper = {
  initial: "initial",
  redirected: "started",
  accepted: "done",
  // these statuses below shouldn't happen here
  registering: "initial",
  success: "done",
  error: "initial",
}
const Sign = (props) => {
  const token = props.match.params.token
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchResources({ resource: "relationshiprequest" }))
    dispatch(pollStatusAtInterval({ token }))
  }, [])

  const relationshipRequest = useSelector(
    (s) => s.relationshiprequest.relationshiprequest
  )
  const status = useSelector((s) => s.register.status)
  const vismaStatus = useSelector((s) => s.visma.status)

  const { t } = useTranslation()
  return (
    <Container>
      <h5>{t("_sign")}</h5>
      <VismaSign
        companyRegistrationNumber={props.allData[0].companyRegistrationNumber}
        token={token}
      />
      <h5 className="my-4">{t("_relationshipRequest")}</h5>
      <VismaStatus
        title={t("_relationshipRequestText")}
        text={t("_acceptRelationshipRequest")}
        onStart={(e) => {
          e.preventDefault()
          if (status !== "redirected" && status !== "initial") return
          window.open(relationshipRequest.link, "_blank")
          dispatch(redirected())
        }}
        status={statusMapper[status]}
        waitingLink={relationshipRequest.status === "fetching"}
      />
      <Button 
        className="float-left mt-2"
        color="primary"
        onClick={() => props.previous()}
        style={{textTransform: 'capitalize'}}>
        {t("_previous")}
      </Button>
      <Button
        className="float-right mt-2"
        color="primary"
        onClick={() => props.next()}
        disabled={status !== "accepted" || vismaStatus !== "signed"}
      >
        {t("_done")}
      </Button>
    </Container>
  )
}

export default Sign
