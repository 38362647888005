import { PageContainer } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { fetchResources, setlistsize } from "../../actions"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import countryISOCodes from "../../res/countryISOCodes"
import Loading from "../../components/Loading"
import { Alert } from "reactstrap"

import AccountInformation from "./AccountInformation"
import Billing from "./Billing"
import axios from "axios"

const pageToString = (state) => {
  switch (state) {
    case 0:
      return "loading"
    case 1:
      return "billingInformation"
    case 2:
      return "billing"
    case 3:
      return "invalidToken"
    case 4:
      return "success"
  }
}
class Activate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      accountInformation: {},
      billing: {},
      error: {
        msg: "",
      },
    }
  }
  componentDidMount() {
    const token = this.props.match.params.token
    axios
      .get(`/activate/${token}`)
      .then((resp) => resp.data)
      .then((data) =>
        this.setState({
          accountInformation: {
            email: data.email,
          },
          page: 1,
        })
      )
      .catch((err) => {
        if (err.response.status === 401) {
          this.setState({
            page: 3,
          })
        }
        console.log(err)
      })
  }
  previous = (data) => {
    this.setState({
      ...data,
      page: this.state.page - 1,
    })
  }
  next = (data) => {
    this.setState({
      ...data,
      page: this.state.page + 1,
    })
  }
  done = ({ billing }) => {
    const { accountInformation } = this.state
    this.setState({
      page: 0, // loading
    })
    axios
      .post("/register", {
        culture: billing.culture,
        language: billing.language,
        companyRegistrationNumber: accountInformation.companyRegistrationNumber,
        address1: billing.address1,
        address2: billing.address2,
        city: billing.city,
        state: billing.state,
        postalCode: billing.postalCode,
        firstName: accountInformation.firstName,
        lastName: accountInformation.lastName,
        phoneNumber: accountInformation.phoneNumber,
        domain: accountInformation.domain,
        country: accountInformation.country,
        token: this.props.match.params.token,
      })
      .then(() =>
        this.setState({
          page: 4,
        })
      )
      .catch((err) => {
        this.setState({
          error: {
            msg: err.response.data,
          },
          page: 1,
        })
        console.log(err)
      })
  }
  render() {
    const { t } = this.props
    return (
      <PageContainer title={t("_register")} className="mb-5">
        {this.state.error.active && (
          <Alert color="danger">{this.state.error.msg}</Alert>
        )}
        {(() => {
          switch (pageToString(this.state.page)) {
            case "loading":
              return <Loading />
            case "billingInformation":
              return (
                <AccountInformation
                  data={{
                    ...this.state.accountInformation,
                    token: this.props.match.params.token,
                  }}
                  next={this.next}
                />
              )
            case "billing":
              return (
                <Billing
                  data={{
                    ...this.state.billing,
                    token: this.props.match.params.token,
                  }}
                  companyRegistrationNumber={
                    this.state.accountInformation.companyRegistrationNumber
                  }
                  previous={this.previous}
                  done={this.done}
                  country={this.state.accountInformation.country}
                />
              )
            case "invalidToken":
              return <h5 className="text-center">Invalid token</h5>
            case "success":
              return (
                <div>
                  <Alert color="success">
                    {t("_companyRegistrationSuccessful")}
                  </Alert>
                  <Link to="/">{t("_login")}</Link>
                </div>
              )
          }
        })()}
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources })(
  withTranslation()(Activate)
)
