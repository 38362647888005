import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap"

import { LoadingGate } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { fetchResources } from "../../actions"
import { withTranslation } from "react-i18next"
import moment from "moment"

class Licenses extends React.Component {
  componentDidMount = () => {
    this.props.fetchResources({ resource: "orders" })
    this.props.fetchResources({ resource: "software" })
  }

  getStatus(order) {
    if (
      order.commitmentEndDate &&
      moment(order.commitmentEndDate).isBefore(moment())
    )
      return "expired"
    if (order.status === "suspended") return "suspended"
    if (order.status === "deleted") return "deleted"
    if (order.status === "expired") return "expired"

    return "active"
  }

  getStatusText(order) {
    const { t } = this.props

    let status = this.getStatus(order)
    switch (status) {
      case "deleted":
        return ` - ${t("_deleted")}`
      case "suspended":
        return ` - ${t("_suspended")}`
      case "expired":
        return ` - ${t("_expired")}`
      default:
        return ""
    }
  }

  render() {
    const { t, software, orders } = this.props

    const filteredOrders = orders.orders
      .filter(
        (order) =>
          this.getStatus(order) === "active" ||
          this.getStatus(order) === "suspended"
      )
      .map((order) => ({
        ...order,
        nce: order?.productType?.id === "OnlineServicesNCE",
      }))
    return (
      <Card>
        <CardHeader>{t("_orders")}</CardHeader>
        <CardBody>
          <LoadingGate loading={orders.fetching}>
            {!filteredOrders.length ? (
              []
            ) : (
              <ListGroup flush>
                {filteredOrders.map((o) => (
                  <ListGroupItem
                    className={`
                    ${
                      this.getStatus(o) === "active"
                        ? "bg-white text-dark"
                        : "bg-secondary text-white"
                    } d-flex align-items-center justify-content-between`}
                    key={o.id}
                    tag="a"
                    href={`/#/${o.type === "azure" ? "orders" : "licenses"}/${
                      o.id
                    }`}
                  >
                    {`${o.name} ${this.getStatusText(o)}`}
                    {o.nce && (
                      <Badge pill color="primary">
                        NCE
                      </Badge>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
            <LoadingGate loading={software.fetching}>
              {software.software.length === 0 && filteredOrders.length === 0 ? (
                <span>{t("_noOrders")}</span>
              ) : (
                <ListGroup flush>
                  {software.software.map(
                    (ware) =>
                      ware.sku && (
                        <ListGroupItem
                          className={"text-dark"}
                          key={ware.sku.id}
                          tag="a"
                          href={`/#/${"software"}/${ware.sku.id}`}
                        >
                          {ware.sku.title}
                        </ListGroupItem>
                      )
                  )}
                </ListGroup>
              )}
            </LoadingGate>
          </LoadingGate>
        </CardBody>
      </Card>
    )
  }
}

export default connect((s) => ({ orders: s.orders, software: s.software }), {
  fetchResources,
})(withTranslation()(Licenses))
