import React from "react"
import axios from "axios"
import { connect } from "react-redux"
import { MFA, Loading } from "../components"
import { withTranslation } from "react-i18next"
import { Container, Button } from "reactstrap"

class MFAPage extends React.Component {
  state = { fetching: true }
  componentDidMount = async () => {
    const { data } = await axios.get("/users/me/mfa-status")
    this.setState({ fetching: false })
    if (data.twoFactorEnabled) {
      this.props.history.push("/")
    }
  }
  render() {
    if (this.state.fetching) {
      return <Loading />
    }
    const { t } = this.props
    return (
      <Container className="mt-3">
        <div className="m-3 d-flex justify-content-between">
          <h5>{t("_addTwoFactorAuth")}</h5>
          <a href="/#/" className="btn btn-primary">
            {t("_skip")}
          </a>
        </div>
        <MFA enable={true} redirect={{ page: "/", delay: 2000 }} />
      </Container>
    )
  }
}

export default connect((s) => s)(withTranslation()(MFAPage))
