import {
  Alert,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap"

import { Loading } from "."
import { withTranslation } from "react-i18next"
import QRCode from "qrcode"
import React from "react"
import axios from "axios"
import { connect } from "react-redux"
import { fail } from "../actions"
import { withRouter } from "react-router"

class MFA extends React.Component {
  state = {
    token: "",
    qrCodeData: null,
    fetching: true,
    twoFactorEnabled: null,
    success: false,
    error: null,
  }

  componentDidMount = async () => {
    const { data } = await axios.get("/users/me/mfa-status")
    if (this.props.enable && !data.twoFactorEnabled) {
      this.setState({ twoFactorEnabled: false })
      return await this.enable()
    }

    this.setState({
      fetching: false,
      twoFactorEnabled: data.twoFactorEnabled,
    })
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  enable = async () => {
    this.setState({ fetching: true })

    try {
      const { data } = await axios.post("/users/me/mfa-enable")

      QRCode.toDataURL(data.dataUrl, (err, qrCodeData) => {
        if (err) throw err
        this.setState({ qrCodeData, fetching: false })
      })
    } catch (err) {
      this.props
        .fail(null, err)
        .then(() => this.setState({ error: err.message, fetching: false }))
    }
  }

  verify = async (e) => {
    e.preventDefault()
    this.setState({ fetching: true })

    try {
      await axios.post("/users/me/mfa-verify", { token: this.state.token })

      this.setState({
        dataUrl: null,
        success: true,
        twoFactorEnabled: true,
        fetching: false,
      })
      if (this.props.redirect != null) {
        await new Promise((resolve, reject) =>
          setTimeout(resolve, this.props.redirect.delay)
        )
        this.props.history.push(this.props.redirect.page)
      }
    } catch (err) {
      this.props
        .fail(null, err)
        .then(() => this.setState({ error: err.message, fetching: false }))
    }
  }

  disable = async () => {
    this.setState({ fetching: true })

    try {
      await axios.post("/users/me/mfa-disable")

      this.setState({ twoFactorEnabled: false, fetching: false })
    } catch (err) {
      this.props
        .fail(null, err)
        .then(() => this.setState({ error: err.message, fetching: false }))
    }
  }

  render() {
    const { t } = this.props
    return this.state.twoFactorEnabled === null ? (
      <Card>
        <CardBody>
          <Loading small />
        </CardBody>
      </Card>
    ) : (
      <Card>
        <CardBody>
          {this.state.error ? (
            <Alert color="danger">{t("_2FAauthFailed")}</Alert>
          ) : this.state.success ? (
            <Alert color="success">{t("_2FAenabled")}</Alert>
          ) : null}

          {this.state.twoFactorEnabled ? (
            <Button
              onClick={this.disable}
              disabled={this.state.fetching}
              block
              color="primary"
            >
              {t("_disable2FA")}
            </Button>
          ) : this.state.qrCodeData ? (
            <Form onSubmit={this.verify}>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <img
                      src={this.state.qrCodeData}
                      alt="2FA verification QR code"
                    />
                  </FormGroup>
                </Col>
                <Col lg="8">
                  <p>{t("_mfaHelper1")}</p>
                  <p>{t("_mfaHelper2")}</p>
                  <p>{t("_mfaHelper3")}</p>
                  <p>{t("_mfaHelper4")}</p>
                  <p>{t("_mfaHelper5")}</p>
                  <p>{t("_mfaHelper6")}</p>
                  <p>{t("_mfaHelper7")}</p>
                </Col>
              </Row>
              <FormGroup>
                <Label>{t("_2FAverificationToken")}</Label>
                <Input
                  type="text"
                  name="token"
                  id="token"
                  value={this.state.token}
                  onChange={this.handleChange}
                  minLength={6}
                  maxLength={6}
                  placeholder="123456"
                />
              </FormGroup>
              <Button
                type="submit"
                disabled={this.state.fetching}
                block
                color="primary"
              >
                {t("_submit")}
              </Button>
            </Form>
          ) : (
            <Button
              onClick={this.enable}
              disabled={this.state.fetching}
              block
              color="primary"
            >
              {t("_enable2FA")}
            </Button>
          )}
        </CardBody>
      </Card>
    )
  }
}

export default connect(null, { fail })(withTranslation()(withRouter(MFA)))
