import { PageContainer } from "../../components"
import React from "react"
import ReportForm from "./ReportForm"
import { connect } from "react-redux"
import { fetchResources } from "../../actions"

class Report extends React.Component {
  componentDidMount = () => {
    this.props.fetchResources({ resource: "invoices" })
    this.props.fetchResources({ resource: "orders" })
  }

  render = () => (
    <PageContainer
      title="Report"
      loading={!this.props.invoices.invoices.length}
    >
      <ReportForm
        invoices={this.props.invoices.invoices}
        orders={this.props.orders.orders}
      />
    </PageContainer>
  )
}

export default connect((s) => s, { fetchResources })(Report)
