import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next"
import React from "react"

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
const Consent = (props) => {
  const [cookies, setCookie] = useCookies(["consent"])
  const [t] = useTranslation()
  const expires = new Date()
  expires.setFullYear(expires.getFullYear() + 2)
  return (
    <Modal isOpen={true}>
      <ModalHeader>{t("_cookies")}</ModalHeader>
      <ModalBody>
        <p>{t("_cookieRequired")}</p>
        <a href="/privacypolicy-fi.pdf" target="_blank">
          {t("_privacyPolicy")}
        </a>
        <Button
          className="btn-rounded btn-block"
          color="primary"
          onClick={() =>
            setCookie("consent", "functional_consent", { expires })
          }
        >
          {t("_continueToSite")}
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default Consent
