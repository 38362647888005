import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  ListGroup,
  ListGroupItem,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"

import { Loading } from "../../components"
import { withTranslation } from "react-i18next"
import React from "react"
import axios from "axios"
import moment from "moment"
import { connect } from "react-redux"
import { fail } from "../../actions"

class ReportForm extends React.Component {
  state = {
    invoiceId: null,
    unitType: "azure",
    subscriptionId: "all",
    error: null,
    creating: false,
    page: 0,
    pageSize: 10,
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  submit = async (e) => {
    e.preventDefault()
    this.setState({ error: null, creating: true })

    try {
      let url = `/invoices/${this.state.invoiceId}/report?type=${this.state.unitType}`

      if (this.state.subscriptionId !== "all") {
        url += `&order=${this.state.subscriptionId}`
      }

      const { data, status } = await axios.get(url, {
        responseType: "arraybuffer",
      })
      if (status === 204) {
        throw new Error("Report is empty")
      }

      // save as excel file
      const blob = new Blob([data], { type: "application/vnd.ms-excel" })
      const objUrl = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = objUrl
      link.download = `report_${this.state.invoiceId}_${moment().format(
        "YYYYMMDDHHmmss"
      )}.xlsx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(objUrl)
    } catch (err) {
      this.props
        .fail(null, err)
        .then(() => this.setState({ error: err.message, fetching: false }))
    }

    this.setState({ creating: false })
  }

  render = () => {
    const { t } = this.props
    this.props.invoices.sort(function (a, b) {
      if (a.invoiceDate > b.invoiceDate) {
        return -1
      }
      if (b.invoiceDate > a.invoiceDate) {
        return 1
      }
      return 0
    })
    return (
      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <Form onSubmit={this.submit}>
            <fieldset disabled={this.state.creating}>
              <FormGroup>
                <Label>{t("_downloadReport")}</Label>
                <ListGroup>
                  {[...Array(this.state.pageSize)].map((_, index) => {
                    const pagedIndex =
                      this.state.page * this.state.pageSize + index
                    const i = this.props.invoices[pagedIndex]
                    if (!i) {
                      if (this.state.page === 0) {
                        return null
                      }
                      return (
                        <ListGroupItem
                          style={{
                            visibility: "hidden",
                          }}
                        >
                          some text
                        </ListGroupItem>
                      )
                    }
                    return (
                      <ListGroupItem
                        key={i.id}
                        value={i.id}
                        onClick={() => {
                          if (this.state.invoiceId !== i.id) {
                            this.setState({ invoiceId: i.id })
                          } else {
                            this.setState({ invoiceId: null })
                          }
                        }}
                        className="text-center"
                        color={
                          this.state.invoiceId === i.id ? "primary" : "inherit"
                        }
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {`${i.id} ${moment(i.billingPeriodStartDate).format(
                          "MMM Do YYYY"
                        )} - ${moment(i.billingPeriodEndDate).format(
                          "MMM Do YYYY"
                        )}`}
                      </ListGroupItem>
                    )
                  })}
                </ListGroup>
                <Pagination
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <PaginationItem disabled={this.state.page === 0}>
                    <PaginationLink
                      type="button"
                      first
                      onClick={() =>
                        this.setState({
                          page: 0,
                        })
                      }
                    />
                  </PaginationItem>
                  <PaginationItem disabled={this.state.page === 0}>
                    <PaginationLink
                      type="button"
                      previous
                      onClick={() =>
                        this.setState({
                          page: Math.max(this.state.page - 1, 0),
                        })
                      }
                    />
                  </PaginationItem>
                  {[
                    ...Array(
                      Math.ceil(
                        this.props.invoices.length / this.state.pageSize
                      )
                    ),
                  ].map((_, index) => (
                    <PaginationItem
                      key={index}
                      active={this.state.page === index}
                    >
                      <PaginationLink
                        type="button"
                        onClick={() =>
                          this.setState({
                            page: index,
                          })
                        }
                      >
                        {index + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem
                    disabled={
                      this.state.page ===
                      Math.ceil(
                        this.props.invoices.length / this.state.pageSize
                      ) -
                        1
                    }
                  >
                    <PaginationLink
                      type="button"
                      next
                      onClick={() =>
                        this.setState({
                          page: Math.min(
                            this.state.page + 1,
                            Math.ceil(
                              this.props.invoices.length / this.state.pageSize
                            ) - 1
                          ),
                        })
                      }
                    />
                  </PaginationItem>
                  <PaginationItem
                    disabled={
                      this.state.page ===
                      Math.ceil(
                        this.props.invoices.length / this.state.pageSize
                      ) -
                        1
                    }
                  >
                    <PaginationLink
                      type="button"
                      last
                      onClick={() =>
                        this.setState({
                          page:
                            Math.ceil(
                              this.props.invoices.length / this.state.pageSize
                            ) - 1,
                        })
                      }
                    />
                  </PaginationItem>
                </Pagination>
              </FormGroup>
              <FormGroup>
                <Label>{t("_reportType")}</Label>
                <Input
                  type="select"
                  name="unitType"
                  id="unitType"
                  onChange={this.handleChange}
                  value={this.state.unitType}
                  required
                >
                  <option value="azure">{t("_usageBasedOrders")}</option>
                  <option value="office">{t("_licenses")}</option>
                </Input>
              </FormGroup>

              {this.state.unitType !== "all" ? (
                <FormGroup>
                  <Label>{t("_order")}</Label>
                  <Input
                    type="select"
                    name="subscriptionId"
                    id="subscriptionId"
                    onChange={this.handleChange}
                    value={this.state.subscriptionId}
                    required
                  >
                    <option value="all">{t("_all")}</option>
                    {this.props.orders
                      .filter((o) =>
                        this.state.unitType === "azure"
                          ? o.unitType === "Usage-based"
                          : o.unitType === "Licenses"
                      )
                      .map((o) => (
                        <option key={o.id} value={o.id}>
                          {o.name}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              ) : null}

              {this.state.error ? (
                <Alert color="danger">{this.state.error}</Alert>
              ) : null}
              {this.state.creating ? (
                <Loading small />
              ) : (
                <Button
                  type="submit"
                  color="primary"
                  block
                  disabled={this.state.invoiceId == null}
                >
                  {t("_downloadExcel")}
                </Button>
              )}
            </fieldset>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default connect(null, { fail })(withTranslation()(ReportForm))
