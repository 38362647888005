import { Loading, PageContainer, Offers, AzurePlan } from "../../components"

import Order from "./Order"
import Offer from "./Offer"
import PriceUpdater from "./PriceUpdater"
import APISettings from "./APISettings"
import Agreement from "./Agreement"
import PurchaseRights from "./PurchaseRights"
import React from "react"
import { Table, Input, Button, ButtonGroup, Container } from "reactstrap"
import axios from "axios"
import { connect } from "react-redux"
import { fetchResources, fail } from "../../actions"
import { withTranslation } from "react-i18next"

const customFields = [
  "autoRenewDate",
  "type",
  "isTrial",
  "autoRenewEnabled",
  "creationDate",
]

class Customer extends React.Component {
  state = {
    orders: [],
    offers: [],
    fetching: true,
    salesOrderCompanyId: "",
    discountPercent: 0,
    successId: false,
    updatingId: false,
    errorId: false,
    termsOfPayment: 0,
    updatingTerms: false,
    customColumn: "autoRenewDate",
    /*token: this.props.user.token*/
  }

  /*static getDerivedStateFromProps = (props, state) => {
    if (state.token !== props.user.token) {
      this.props.fetchResources({ resource: "customers" })
      return { token: props.user.token }
    } else {
      return null
    }
  }*/

  componentDidMount = async () => {
    this.props.fetchResources({ resource: "customers", force: true })
    this.props.fetchResources({ resource: "listprices" })
    this.props.fetchResources({ resource: "nceoffers" })

    const response = await axios
      .get(`/customers/${this.props.match.params.customerId}`)
      .catch((error) =>
        this.props
          .fail(null, error)
          .then(() => this.setState({ fetching: false }))
      )
    let orders = []

    if (response !== undefined) {
      orders = response.data.orders

      orders.forEach((order) => {
        if (order.type === "azure") {
          order.currentPercentage = order.marginPercentage
        } else {
          order.currentUnitPrice = order.unitPrice
          order.currentPercentage = (
            (1 - order.unitPrice / this.getPrice(order)) *
            100
          ).toFixed(1)
        }
      })
    }

    let salesId = ""
    if (this.props.customer.salesOrderCompanyId !== null) {
      salesId = this.props.customer.salesOrderCompanyId
    }
    let termsOfPayment = 0
    if (this.props.customer.termsOfPayment !== null) {
      termsOfPayment = this.props.customer.termsOfPayment
    }
    let discount = 0
    if (this.props.customer.discountPercent !== null) {
      discount = this.props.customer.discountPercent
    }

    this.setState({
      orders: orders,
      salesOrderCompanyId: salesId,
      discountPercent: discount,
      termsOfPayment: termsOfPayment,
      fetching: false,
    })
    const awaitti = axios
      .get(`/offers/${this.props.match.params.customerId}`)
      .catch((error) =>
        this.props
          .fail(null, error)
          .then(() => this.setState({ fetching: false }))
      )

    const resp2 = await awaitti
    let offers = []
    if (resp2 !== undefined) {
      offers = resp2.data.offers
      this.setState({ offers: offers })
    }
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  updateSalesOrderCompanyId = async () => {
    this.setState({ updatingId: true })

    try {
      await axios.post(
        `/customers/${this.props.customer.id}/salesordercompanyid`,
        {
          salesId: this.state.salesOrderCompanyId,
        }
      )
      this.setState({ updatingId: false, successId: true })
    } catch (err) {
      this.setState({ updatingId: false, errorId: true })
    }
  }

  updateDiscount = async () => {
    this.setState({ updatingId: true })

    try {
      await axios.post(
        `/customers/${this.props.customer.id}/discountPercentage`,
        {
          discount: this.state.discountPercent,
        }
      )
      this.setState({ updatingId: false, successId: true })
    } catch (err) {
      this.setState({ updatingId: false, errorId: true })
    }
  }

  updateTermsofPayment = async (event) => {
    const newTerms = parseInt(event.target.value)
    if (newTerms === this.state.termsOfPayment) return

    this.setState({ updatingTerms: true })

    try {
      await axios.post(`/customers/${this.props.customer.id}/termsofpayment`, {
        termsOfPayment: newTerms,
      })
      this.setState({ updatingTerms: false, termsOfPayment: newTerms })
    } catch (err) {
      this.setState({ updatingTerms: false })
      alert(err.message)
    }
  }

  getPrice(order) {
    if (order && order.listPrice) return order.listPrice
    if (!this.props.listprices) return ""
    let listPrices = this.props.listprices.listprices
    let productPrice = listPrices.filter(
      (price) => price.offerId.toLowerCase() === order.offerId.toLowerCase()
    )
    return productPrice.length !== 0 ? productPrice[0].offerListPrice : ""
  }

  changeOrderQuantity(orderId, value) {
    let orders = [...this.state.orders]
    let order = orders.find((item) => item.id === orderId)

    order.quantity = value

    order.success = false
    order.error = null
    this.setState({ orders })
  }

  async updateOrderQuantity(orderId, value) {
    let orders = [...this.state.orders]
    let order = orders.find((item) => item.id === orderId)
    let customerId = this.props.customer.id
    order.quantity = value

    try {
      await axios.post(`/licenses/${order.id.toLowerCase()}/quantity`, {
        customerId,
        quantity: value,
      })

      this.setState({ success: true })
    } catch (err) {
      this.props
        .fail(null, err)
        .then(() => this.setState({ error: true, fetching: false }))
    }

    order.success = false
    order.error = null
    this.setState({ orders })
  }

  changeUnitPriceOrPercentage(orderId, value, options) {
    const { isPercentage = false, applyOnlyToEmpty = false } = options || {}

    let orders = [...this.state.orders]
    let order = orders.find((item) => item.id === orderId)

    if (order.type === "azure") {
      if (!applyOnlyToEmpty || !order.currentPercentage) {
        order.currentPercentage = value
      }
    } else {
      const listPrice = this.getPrice(order)
      if (isPercentage) {
        if (listPrice && (applyOnlyToEmpty ? !order.currentUnitPrice : true)) {
          order.currentPercentage = value
          order.currentUnitPrice = (listPrice * ((100 - value) / 100)).toFixed(
            3
          )
        }
      } else {
        // set the unit price visible in the UI
        order.currentUnitPrice = value
        order.currentPercentage = listPrice
          ? ((1 - value / listPrice) * 100).toFixed(1)
          : ""
      }
    }

    order.success = false
    order.error = null
    this.setState({ orders })
  }

  async updateUnitPriceOrPercentage(orderId, value) {
    let orders = [...this.state.orders]
    let order = orders.find((item) => item.id === orderId)
    try {
      order.updating = true
      this.setState({ orders })

      const update = {
        customerId: this.props.customer.id,
        type: order.type,
      }

      if (update.type === "azure") {
        update.marginPercentage = value
      } else {
        update.unitPrice = value
      }

      await axios
        .post(`/orders/${order.id.toLowerCase()}`, update)
        .catch((error) =>
          this.props
            .fail(null, error)
            .then(() => this.setState({ fetching: false }))
        )

      //If the update is a success, change the order values in state to reflect the change in database.
      if (update.type === "azure") {
        order.marginPercentage = value
      } else {
        order.unitPrice = value
      }

      order.updating = false
      order.success = true
      order.error = null
      this.setState({ order })
    } catch (err) {
      order.updating = false
      order.success = false
      order.error = err.message
      this.setState({ order })
    }
  }

  applyPrice(normalPrice, azurePrice, applyOnlyToEmpty) {
    let orders = [...this.state.orders]
    try {
      orders.forEach((order) => {
        if (order.type === "azure") {
          if (azurePrice !== "") {
            let convertedAzurePrice =
              azurePrice > 15 ? 0 : azurePrice < 0 ? 15 : 15 - azurePrice
            this.changeUnitPriceOrPercentage(order.id, convertedAzurePrice, {
              applyOnlyToEmpty,
            })
          }
        } else {
          if (normalPrice !== "") {
            this.changeUnitPriceOrPercentage(order.id, normalPrice, {
              isPercentage: true,
              applyOnlyToEmpty,
            })
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  updateAll() {
    this.state.orders.forEach((order) => {
      if (this.canOrderBeUpdated(order)) {
        if (order.type === "azure") {
          this.updateUnitPriceOrPercentage(order.id, order.currentPercentage)
        } else {
          this.updateUnitPriceOrPercentage(order.id, order.currentUnitPrice)
        }
      }
    })
  }

  canOrderBeUpdated(order) {
    return order.type === "azure"
      ? order.currentPercentage !== order.marginPercentage
      : order.currentUnitPrice !== order.unitPrice
  }

  getUpdateAllDisabledStatus() {
    return !this.state.orders.some((order) => this.canOrderBeUpdated(order))
  }

  render() {
    const { t, customer } = this.props

    return (
      <PageContainer
        fluid
        style={{ width: "min(90%, 1400px)" }}
        loading={!customer}
        title={customer && customer.companyName}
      >
        {this.state.fetching ? (
          <Loading />
        ) : (
          <>
            <h3>{t("_orders")}</h3>
            <Table bordered striped responsive>
              <thead>
                <tr>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {t("_name")}
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {t("_listPrice")}
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {t("_billingPlan")}
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    <Input
                      type="select"
                      name="customColumn"
                      className="border-0 shadow-none"
                      onChange={this.handleChange}
                      style={{
                        width: "auto",
                        color: "#212529",
                        fontWeight: "bold",
                      }}
                    >
                      {customFields.map((name) => (
                        <option key={name} value={name}>
                          {t(`_${name}`)}
                        </option>
                      ))}
                    </Input>
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {t("_quantity")}
                  </th>
                  <th
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                    className="text-center pr-3"
                  >
                    {t("_unitPrice")}
                  </th>
                  <th
                    style={{ verticalAlign: "middle", textAlign: "center" }}
                    className="text-center pr-3"
                  >
                    {t("_percentage")}
                  </th>
                  <th>
                    {
                      <Button
                        disabled={this.getUpdateAllDisabledStatus()}
                        onClick={this.updateAll.bind(this)}
                        color={"primary"}
                        block
                      >
                        {t("_updateAll")}
                      </Button>
                    }
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.orders.map((o) => (
                  <Order
                    key={o.id}
                    order={o}
                    updateUnitPriceOrPercentage={this.updateUnitPriceOrPercentage.bind(
                      this
                    )}
                    changeUnitPriceOrPercentage={this.changeUnitPriceOrPercentage.bind(
                      this
                    )}
                    changeOrderQuantity={this.changeOrderQuantity.bind(this)}
                    updateOrderQuantity={this.updateOrderQuantity.bind(this)}
                    listPrice={this.getPrice(o)}
                    customer={this.props.customer}
                    customColumn={this.state.customColumn}
                  />
                ))}
              </tbody>
            </Table>
            <hr />
            <Container>
              <h3>{t("_orderDiscounts")}</h3>
              <PriceUpdater applyPrice={this.applyPrice.bind(this)} />
              <h3 className="my-3">{t("_azurePlan")}</h3>
              <AzurePlan
                className="my-5"
                customerId={this.props.customer.id}
                orders={this.state.orders}
              />
              <h3 className="mt-4">{t("_customerDetails")}</h3>
              <Table bordered striped responsive>
                <tbody>
                  <tr>
                    <td>
                      {t("_salesOrderCompanyId")}
                      <Input
                        type="text"
                        name="salesOrderCompanyId"
                        value={this.state.salesOrderCompanyId}
                        onChange={this.handleChange}
                        placeholder={t("_notSet")}
                      />
                    </td>
                    <td className="align-bottom">
                      {this.state.updatingId ? (
                        <Loading small />
                      ) : (
                        <Button
                          color={
                            this.state.successId
                              ? "success"
                              : this.state.errorId
                              ? "danger"
                              : "primary"
                          }
                          block
                          onClick={this.updateSalesOrderCompanyId}
                        >
                          {t("_update")}
                        </Button>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{`${t("_termsOfPayment")} (${t("_days")})`}</td>
                    <td>
                      {this.state.updatingTerms ? (
                        <Loading small />
                      ) : (
                        <ButtonGroup style={{ width: "100%" }}>
                          <Button
                            color={
                              this.state.termsOfPayment === 14
                                ? "success"
                                : "primary"
                            }
                            onClick={this.updateTermsofPayment}
                            value={14}
                          >
                            14
                          </Button>
                          <Button
                            color={
                              this.state.termsOfPayment === 30
                                ? "success"
                                : "primary"
                            }
                            onClick={this.updateTermsofPayment}
                            value={30}
                          >
                            30
                          </Button>
                        </ButtonGroup>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <hr />
              <Agreement customer={customer} />
              <PurchaseRights customer={customer} />
              <h3>{t("_ApiSettings")}</h3>
              <APISettings customer={customer} />
              <hr />
              <h3>{t("_offers")}</h3>
              <Table bordered striped responsive>
                <thead>
                  <tr>
                    <th>{t("_name")}</th>
                    <th>{t("_unitPrice")}</th>
                    <th>{t("_quantity")}</th>
                    {/* <th>Effective starting</th> */}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.state.offers.map((o) => (
                    <Offer key={o.id} order={o} customer={customer} />
                  ))}
                </tbody>
              </Table>
            </Container>
          </>
        )}
        {!this.props.nceoffers.fetching && (
          <Offers
            offers={this.props.nceoffers.nceoffers}
            link={(product) =>
              `#/customers/${this.props.customer.customerId}/offers/${product._id}`
            }
          />
        )}
      </PageContainer>
    )
  }
}

const mapStateToProps = (state, props) => {
  const customer = state.customers.customers.find(
    (i) => i.id.toString() === props.match.params.customerId
  )
  const listprices = state.listprices
  return { customer, listprices, nceoffers: state.nceoffers }
}

export default connect(mapStateToProps, { fetchResources, fail })(
  withTranslation()(Customer)
)
