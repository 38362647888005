import { PageContainer, TranslatedTable } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { fetchResources, setlistsize } from "../../actions"
import { withTranslation, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import moment from "moment"

const selectFilter = (data, field) => {
  const allOptions = data.map((item) => item[field])
  const uniqueOptions = [...new Set(allOptions)]
  return {
    filterMethod: (filter, row) => {
      if (filter.value === "all") {
        return true
      }
      if (row[field] === undefined) {
        return true
      }
      return row[field] === filter.value
    },
    Filter: ({ filter, onChange }) => {
      return (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          {<option value="all"></option>}
          {uniqueOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      )
    },
  }
}

class Orders extends React.Component {
  componentDidMount = () => {
    if (this.props.user.isAdmin) {
      this.props.fetchResources({ resource: "ordercache", force: true })
      this.props.fetchResources({ resource: "customers", force: true })
    } else {
      this.props.fetchResources({ resource: "orders" })
      this.props.fetchResources({ resource: "software" })
    }
  }

  addRequiredFieldsToSoftware(allSoftware) {
    allSoftware.forEach((software) => {
      software.name = software.sku.title
      software.quantity = software.entitlement.quantity
      software.unitType = "Software"
    })

    return allSoftware
  }

  render() {
    const { t } = this.props

    let software = this.props.software.software
      ? this.addRequiredFieldsToSoftware(this.props.software.software)
      : []
    let orderAndSoftware = software.concat(this.props.orders.orders)

    let columns = [
      {
        Header: t("_name"),
        accessor: "name",
        Cell: (props) =>
          props.original.type ? (
            props.original.type === "azure" ? (
              <Link to={`/orders/${props.original.id}`}>{props.value}</Link>
            ) : (
              <Link to={`/licenses/${props.original.id}`}>{props.value}</Link>
            )
          ) : (
            <Link to={`/software/${props.original.sku.id}`}>{props.value}</Link>
          ),
      },
      {
        Header: t("_quantity"),
        accessor: "quantity",
      },
      {
        Header: t("_unitType"),
        accessor: "unitType",
      },
    ]

    if (this.props.user.isAdmin) {
      columns = [
        {
          Header: t("_companyName"),
          accessor: "companyName",
          Cell: (props) => (
            <Link to={`/customers/${props.original.customerId}`}>
              {props.value}
            </Link>
          ),
        },
        {
          Header: t("_name"),
          accessor: "name",
        },
        {
          Header: t("_billingCycle"),
          accessor: "billingCycle",
          ...selectFilter(this.props.ordercache.ordercache, "billingCycle"),
        },
        {
          Header: t("_status"),
          accessor: "status",
          ...selectFilter(this.props.ordercache.ordercache, "status"),
        },
        {
          Header: t("_commitmentEndDate"),
          accessor: "commitmentEndDate",
          Cell: (props) =>
            props.value ? moment(props.value).format("DD.MM.YYYY") : "",
        },
        {
          Header: t("_quantity"),
          accessor: "quantity",
        },
        {
          Header: t("_termDuration"),
          accessor: "termDuration",
          ...selectFilter(this.props.ordercache.ordercache, "termDuration"),
        },
      ]
    }

    return (
      <PageContainer title={t("_orders")}>
        <h3>{t("_productsAndLicenses")}</h3>
        <TranslatedTable
          columns={columns}
          data={
            this.props.user.isAdmin
              ? this.props.ordercache.ordercache.map((s) => {
                  return {
                    ...s,
                    companyName: this.props.customers.customers.find(
                      (c) => c.id === s.customerId
                    )?.companyName,
                  }
                })
              : orderAndSoftware
          }
          fetching={
            this.props.user.isAdmin
              ? this.props.ordercache.fetching
              : this.props.orders.fetching || this.props.software.fetching
          }
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          filterable={true}
        />
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources, setlistsize })(
  withTranslation()(Orders)
)
