import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap"
import { withTranslation } from "react-i18next"
import { Loading } from "../../components"
import React from "react"
import axios from "axios"
import { connect } from "react-redux"
import { fail, fetchResources } from "../../actions"
import { MultiSelect } from "react-multi-select-component"

class LicenseQuantityForm extends React.Component {
  state = {
    quantity: this.props.license.quantity,
    originalQuantity: this.props.license.quantity,
    updating: false,
    success: false,
    error: false,
    selectedUsers: [],
  }

  handleQuantityChange = ({ target }) =>
    this.setState({
      [target.name]: target.value,
      selectedUsers:
        target.value - this.state.originalQuantity <
        this.state.selectedUsers.length
          ? this.state.selectedUsers.slice(
              0,
              target.value - this.state.originalQuantity
            )
          : this.state.selectedUsers,
    })
  componentDidMount() {
    this.props.fetchResources({ resource: "licenseusers" })
  }
  update = async (e) => {
    e.preventDefault()
    this.setState({ updating: true, success: false, error: false })

    try {
      if (this.state.selectedUsers.length === 0) {
        await axios.post(`/licenses/${this.props.license.id}/quantity`, {
          quantity: this.state.quantity,
        })
        this.setState({ success: true })
      } else {
        const { data } = await axios.post(`/tasks/updatequantityandassign`, {
          quantity: this.state.quantity,
          order: {
            order: this.props.license.id,
          },
          userIds: this.state.selectedUsers.map((user) => user.value),
        })
      }
    } catch (err) {
      this.props
        .fail(null, err)
        .then(() => this.setState({ error: true, fetching: false }))
    }

    this.setState({ updating: false })
  }

  render() {
    const { t, license } = this.props
    const nce = license.productType?.id === "OnlineServicesNCE"
    return (
      <div>
        <h3>{t("_updateQTY")}</h3>
        <Form onSubmit={this.update}>
          <FormGroup>
            <Label for="quantity">{t("_quantity")}</Label>
            <Input
              type="number"
              value={this.state.quantity}
              onChange={this.handleQuantityChange}
              name="quantity"
              id="quantity"
              min={1}
              disabled={this.state.updating || license.status !== "active"}
            />
            {this.state.quantity - this.state.originalQuantity > 0 &&
              !this.state.updating &&
              license.status === "active" && (
                <div className="mt-2">
                  <p className="mt-1">
                    {t("_assignLicensesWhenChangingQuantity")}:
                  </p>
                  <MultiSelect
                    // disable if amount of users selected is greater than the change in quantity
                    options={
                      this.props.licenseusers.fetching
                        ? []
                        : this.props.licenseusers.licenseusers
                            .filter(
                              (user) =>
                                !user.licenses
                                  .map((license) => license.productSku.name)
                                  .includes(this.props.license.offerName)
                            )
                            .map((user) => ({
                              value: user.id,
                              label: user.userPrincipalName,
                              disabled:
                                this.state.quantity -
                                  this.state.originalQuantity <=
                                  this.state.selectedUsers.length &&
                                !this.state.selectedUsers
                                  .map((user) => user.value)
                                  .includes(user.id),
                            }))
                    }
                    isLoading={this.props.licenseusers.fetching}
                    onChange={(selectedUsers) =>
                      this.setState({ selectedUsers })
                    }
                    value={this.state.selectedUsers}
                    hasSelectAll={false}
                    valueRenderer={(selected, _) =>
                      `${selected.length} users selected`
                    }
                  />
                </div>
              )}
          </FormGroup>

          {this.state.success ? (
            <Alert color="success">{t("_successMsg_LicQuaUpdate")}</Alert>
          ) : this.state.error ? (
            <Alert color="danger">{t("_errorMsg_LicQuaUpdate")}</Alert>
          ) : null}
          {nce && license.status === "active" && (
            <Alert color="info">{t("_nceInfo")}</Alert>
          )}
          {license.status !== "active" && (
            <Alert color="warning">{t("_licenseIsNotActive")}</Alert>
          )}

          {this.state.updating ? (
            <Loading small />
          ) : (
            <Button
              type="submit"
              name="submit"
              id="submit"
              disabled={license.status !== "active"}
            >
              {t("_update")}
            </Button>
          )}
        </Form>
      </div>
    )
  }
}

export default connect(
  (s) => {
    return {
      licenseusers: s.licenseusers,
    }
  },
  { fail, fetchResources }
)(withTranslation()(LicenseQuantityForm))
