module.exports = {
  durationToString: (duration, t) => {
    const years = Math.floor(duration / 12)
    let yearString = ""
    if (years !== 0) {
      yearString = `${years} ${t("_yearCommitment")}`
    }
    const months = duration - 12 * years
    let monthString = ""
    if (months !== 0) {
      monthString = `${months} ${t("_monthCommitment")}`
    }
    return `${yearString} ${monthString}`
  },
}
