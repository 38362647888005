import { Col, Row } from "reactstrap"

import Orders from "./Orders"
import { PageContainer, AzurePlan, ExpandableList } from "../../components"
import Offer from "./Offer"
import { Table } from "reactstrap"
import React from "react"
import SummaryChart from "./SummaryChart"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import axios from "axios"
import { fetchResources } from "../../actions"
import Licenses from "./Licenses"

class Dashboard extends React.Component {
  state = {
    offers: [],
  }
  componentDidMount = async () => {
    const resp2 = await axios.get(`/offers`)
    this.props.fetchResources({ resource: "orders" })
    if (this.props.user.isAdmin) {
      this.props.fetchResources({ resource: "partnerstats", force: true })
    }

    let offers = []
    if (resp2 !== undefined) {
      offers = resp2.data.offers
      this.setState({ offers: offers })
    }
  }

  render = () => {
    const { t, user, partnerstats } = this.props
    console.log(this.props)
    return (
      <PageContainer title={user.isAdmin ? t("_welcome") : user.customer}>
        {user.isAdmin ? (
          <div
            className="d-flex flex-column"
            style={{
              gap: "10px",
            }}
          >
            <SummaryChart />
            <ExpandableList
              title={`${t("_ordersWithNoPrice")} (${
                partnerstats.fetching
                  ? `${t("_loading")}...`
                  : partnerstats.partnerstats?.ordersMissingPrice?.length | 0
              })`}
              emptyText={t("_noOrders")}
              loading={partnerstats.fetching}
              items={
                partnerstats.partnerstats?.ordersMissingPrice != null
                  ? partnerstats.partnerstats.ordersMissingPrice.map((o) => ({
                      link: `/#/customers/${o.customerId}`,
                      text: `${o.companyName} - ${o.name}`,
                    }))
                  : []
              }
            />
            <ExpandableList
              title={`${t("_ordersSoldAtLoss")} (${
                partnerstats.fetching
                  ? `${t("_loading")}...`
                  : partnerstats.partnerstats?.soldAtLoss?.length | 0
              })`}
              emptyText={t("_noOrders")}
              loading={partnerstats.fetching}
              items={
                partnerstats.partnerstats?.soldAtLoss != null
                  ? partnerstats.partnerstats.soldAtLoss.map((o) => ({
                      link: `/#/customers/${o.customerId}`,
                      text: `${o.companyName} - ${o.name} CSP price ${o.cspPrice} - unit price ${o.unitPrice}`,
                    }))
                  : []
              }
            />
          </div>
        ) : (
          <Col>
            <Row>
              <Col sm="6">
                <SummaryChart />
              </Col>
              <Col sm="6">
                <Orders />
              </Col>
            </Row>
            <Row>
              <Col>
                <Licenses />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="my-3">{t("_azurePlan")}</h3>
                <AzurePlan
                  className="my-5"
                  orders={this.props.orders.orders}
                  loading={this.props.orders.fetching}
                />
              </Col>
            </Row>
          </Col>
        )}
        {/*<Row>
          <h3>{t("_offers")}</h3>
          <Table bordered striped responsive>
            <thead>
              <tr>
                <th>{t("_name")}</th>
                <th>{t("_unitPrice")}</th>
                <th>{t("_quantity")}</th>
                { <th>Effective starting</th> }
                <th />
              </tr>
            </thead>
            <tbody>
              {this.state.offers.map((o) => (
                <Offer key={o.id} order={o} customer={user.customer} />
              ))}
            </tbody>
          </Table>
        </Row>*/}
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources })(
  withTranslation()(Dashboard)
)
