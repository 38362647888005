import {
  Alert,
  Button,
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap"

import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"

const initialState = {
  password: "",
  passwordAgain: "",
  fetching: false,
  success: false,
  error: null,
}

class PasswordChange extends React.Component {
  state = { ...initialState }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  submit = async (e) => {
    e.preventDefault()

    try {
      this.setState({ fetching: true })

      await axios.put("/users/me", { password: this.state.password })

      this.setState({ ...initialState, success: true })
    } catch (err) {
      this.setState({ ...initialState, error: err.message })
    }
  }

  render = () => {
    const { password, passwordAgain, fetching } = this.state
    const { t } = this.props

    const bothPasswordsTyped = password.length >= 8 && passwordAgain.length >= 8
    const passwordsDontMatch = bothPasswordsTyped && password !== passwordAgain
    const disableSubmit = fetching || passwordsDontMatch

    return (
      <Card>
        <CardBody>
          <Form onSubmit={this.submit}>
            <FormGroup>
              <Label for="password">{t("_changePassword")}</Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="minimum 8 characters"
                value={password}
                onChange={this.handleChange}
                minLength={8}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="passwordAgain">{t("_confirmPassword")}</Label>
              <Input
                type="password"
                name="passwordAgain"
                id="passwordAgain"
                value={passwordAgain}
                onChange={this.handleChange}
                minLength={8}
                invalid={passwordsDontMatch}
                required
              />
              {passwordsDontMatch ? (
                <FormFeedback>{t("_passwordMissMatch")}</FormFeedback>
              ) : null}
            </FormGroup>

            {this.state.error ? (
              <Alert color="danger">{t("_errorMsg_passUpdate")}</Alert>
            ) : this.state.success ? (
              <Alert color="success">{t("_successMsg_passUpdate")}</Alert>
            ) : null}

            <Button type="submit" disabled={disableSubmit} block>
              {t("_update")}
            </Button>
          </Form>
        </CardBody>
      </Card>
    )
  }
}

export default withTranslation()(PasswordChange)
