import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

import React from "react"
import axios from "axios"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { fail } from "../../actions"
import { ACTION_TYPES as USER_TYPE } from "../../reducers/user"

class UserInfoForm extends React.Component {
  state = {
    password: "",
    error: null,
    success: false,
  }

  onChange = ({ target }) => this.setState({ [target.id]: target.value })

  onToggle = ({ target }) => this.setState({ [target.id]: target.checked })

  update = async (e) => {
    e.preventDefault()

    const update = {
      password: this.state.password,
    }

    axios
      .put(`/users/${this.props.user.id}`, update)
      .then(() =>
        this.setState({ success: true, password: "", reset2FA: false })
      )
      .catch((err) =>
        this.props
          .fail(null, err)
          .then(() => this.setState({ success: false, error: err.message }))
      )
  }

  delete = async () => {
    this.setState({ error: null })

    if (
      window.confirm(
        `Delete user ${this.props.user.companyName} (${this.props.user.email})?`
      )
    ) {
      axios
        .delete(`/users/${this.props.user.id}`)
        .then(() => this.props.history.push("/users"))
        .catch((err) =>
          this.props
            .fail(null, err)
            .then(() => this.setState({ error: err.message }))
        )
    }
  }

  imitate = () =>
    axios.post(`/users/imitate/${this.props.user.id}`).then((res) => {
      const { data } = res
      this.props.history.push("/")
      this.props.dispatch({
        type: USER_TYPE.LOGIN,
        isAdmin: data.user.isAdmin,
        isClientAdmin: data.user.isClientAdmin,
        email: data.user.email,
        token: data.accessToken,
        customer: data.user.customer,
        signed: data.user.signed,
      })
      window.location.href = "/#/"
      window.location.reload()
    })

  mfaReset = () =>
    axios
      .put(`/users/${this.props.user.id}/mfa-reset`)
      .then(() => this.setState({ success: true, error: null }))
      .catch((err) => this.setState({ error: err.message }))

  render() {
    const { t } = this.props
    return (
      <>
        {this.state.error ? (
          <Alert color="danger">{this.state.error}</Alert>
        ) : null}

        {this.state.success ? (
          <Alert color="success">{t("_updateSuccess")}</Alert>
        ) : null}

        <Row>
          <Col sm={6}>
            <Form onSubmit={this.update}>
              <FormGroup>
                <Label for="password">{t("_password")}</Label>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </FormGroup>
              <Button type="submit" color="primary">
                {t("_update")}
              </Button>
            </Form>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={6}>
            <Button onClick={this.delete} color="danger">
              {t("_deleteUser")}
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={6}>
            <Button onClick={this.imitate}>{t("_loginAsUser")}</Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={6}>
            <Button onClick={this.mfaReset}>{t("_reset2FA")}</Button>
          </Col>
        </Row>
      </>
    )
  }
}

export default connect(null, (dispatch) => ({
  dispatch,
  ...bindActionCreators({ fail }, dispatch),
}))(withRouter(withTranslation()(UserInfoForm)))
