import { Col, Container, Row } from "reactstrap"

import React from "react"
import { ACTION_TYPES as USER_TYPE } from "../reducers/user"
import axios from "axios"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { adlogin } from "../actions"

class ADLogin extends React.Component {
  componentDidMount = async () => {
    let token = this.props.match.params.token
    await this.props.dispatch(this.props.adlogin({ token }))

    this.props.history.push("/")
  }

  render() {
    return <Container>Logging in...</Container>
  }
}

export default connect(
  (s) => s,
  (dispatch) => ({ dispatch, adlogin })
)(withTranslation()(ADLogin))
