import React, { useState, useEffect } from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { TranslatedTable } from "../../components"
import { connect } from "react-redux"
import { setlistsize, fail, fetchResources } from "../../actions"
import { useDebounce } from "../../hooks"
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Tooltip,
} from "reactstrap"
import {
  Loading,
  SelectInputComponent,
  ConfirmActionModal,
} from "../../components"
import { combineByField, countItems, removeDuplicates } from "../../lib"

import moment from "moment"

class InvoiceDetail extends React.Component {
  state = {
    items: [],
    originalItems: [],
    customerTotals: [],
    statuses: [],
    fetching: true,
    customerId: "all",
    modalOpen: false,
    modalMessage: "",
    modalOptions: {},
    saving: false,
    savingRows: false,
    force: false,
    calculating: false,
    filter: "",
    selectedRows: [],
    selectedAll: false,
    confirmActionModalOpen: false,
  }
  constructor(props) {
    super(props)
    this.reactTable = React.createRef()
  }

  componentDidMount = async () => {
    this.fetchItems()
    this.props.fetchResources({ resource: "customers", force: true })
  }
  fetchItems = async () => {
    axios
      .get(`/invoices/${this.props.invoice.id}`)
      .then(({ data }) =>
        this.setState({
          originalItems: JSON.parse(JSON.stringify(data.items)),
          items: data.items,
          customerTotals: data.customerTotals,
          statuses: data.statuses,
          fetching: false,
        })
      )
      .catch((error) =>
        this.props
          .fail(null, error)
          .then(() => this.setState({ fetching: false }))
      )
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  calculateRows = async (customerIds) => {
    // this could be a task?
    this.setState({ calculating: true })
    try {
      await axios.post(`/invoice/${this.props.invoice.id}/calculate`, {
        customerIds,
      })
      // fetch new items
      await this.fetchItems()
      this.setState({ selectedRows: [], selectedAll: false })
    } catch (err) {
      this.setState({
        modalOpen: true,
        modalMessage: "_calculate_invoice_error",
        modalOptions: {},
        saving: false,
      })
    } finally {
      this.setState({ calculating: false })
    }
  }
  saveFivaldi = async () => {
    // Let's check if the selected customer has any invoices this month
    /*  if (
      this.state.customerId !== "all" &&
      !this.state.customerTotals
        .map((customer) => customer.customerId)
        .includes(this.state.customerId)
    ) {
      this.setState({ modalOpen: true, modalMessage: "_fivaldiNoInvoices",
      modalOptions: {} })
      setTimeout(() => {
        this.setState({ modalOpen: false })
      }, 2000)
      return
    }*/

    this.setState({ saving: true })
    try {
      /*const response = await axios.get(
        `generateInvoiceXML/${this.props.invoice.id}&${this.state.customerId}`
      )*/
      const {
        data: { statuses },
      } = await axios.post(
        `/invoice/${this.props.invoice.id}/save?force=${this.state.force}`,
        {
          customerIds: this.state.selectedRows,
        }
      )
      await this.fetchItems()
      this.setState({
        modalOpen: true,
        modalMessage: "_fivaldiSaved",
        modalOptions: {
          successes: countItems(statuses, "billed", "status"),
          total: statuses.length,
        },
        saving: false,
        selectedAll: false,
        selectedRows: [],
      })
    } catch (err) {
      this.setState({
        modalOpen: true,
        modalMessage: "_fivaldiError",
        modalOptions: {},
        saving: false,
      })
    } finally {
      setTimeout(() => {
        this.setState({ modalOpen: false })
      }, 2000)
    }
  }

  testInvoice = async () => {
    // Let's check if the selected customer has any invoices this month
    if (
      this.state.customerId !== "all" &&
      !this.state.customerTotals
        .map((customer) => customer.customerId)
        .includes(this.state.customerId)
    ) {
      this.setState({
        modalOpen: true,
        modalMessage: "_fivaldiNoInvoices",
        modalOptions: {},
      })
      setTimeout(() => {
        this.setState({ modalOpen: false })
      }, 2000)
      return
    }

    this.setState({ saving: true })
    try {
      const response = await axios.get(
        `testInvoiceXML/${this.props.invoice.id}&${this.state.customerId}`
      )
      console.log(response)
      if (response.length > 0)
        this.setState({
          modalOpen: true,
          modalMessage: "Console error log added",
          modalOptions: {},
          saving: false,
        })
      else
        this.setState({
          modalOpen: true,
          modalMessage: "Successfull",
          modalOptions: {},
          saving: false,
        })
    } catch (err) {
      this.setState({
        modalOpen: true,
        modalMessage: "_xmlError",
        modalOptions: {},
        saving: false,
      })
    } finally {
      setTimeout(() => {
        this.setState({ modalOpen: false })
      }, 2000)
    }
  }
  saveRows = async () => {
    // save all rows
    try {
      // check for modified rows
      const changedRows = {}
      for (const row of this.state.items) {
        const other = this.state.originalItems.find((x) => x._id === row._id)
        if (other) {
          if (
            row.cost !== other.cost ||
            row.fivaldiProductName !== other.fivaldiProductName
          ) {
            if (!(row.customerId in changedRows)) {
              changedRows[row.customerId] = []
            }
            changedRows[row.customerId].push({
              id: row._id,
              cost: row.cost !== other.cost ? row.cost : undefined,
              fivaldiProductName:
                row.fivaldiProductName !== other.fivaldiProductName
                  ? row.fivaldiProductName
                  : undefined,
            })
          }
        }
      }
      if (Object.keys(changedRows).length === 0) {
        console.log("no changes")
        return
      }

      this.setState({ savingRows: true })
      const { data } = await axios.patch(
        `/invoice/${this.props.invoice.id}/rows`,
        {
          rows: changedRows,
        }
      )
      await this.fetchItems()
      this.setState({
        modalOpen: true,
        modalMessage: "_rowsSaved",
        modalOptions: {
          count: Object.keys(changedRows).length,
          modified: data.modifiedCount,
        },
        savingRows: false,
      })
    } catch (err) {
      this.setState({
        modalOpen: true,
        modalMessage: "_saveInvoiceError",
        modalOptions: {},
        savingRows: false,
      })
    }
  }

  render() {
    const { t } = this.props
    const nce = this.props.invoice.id[0] === "G"
    let customerTotals = []
    for (const invoiceRow of this.state.items) {
      if (!(invoiceRow.customerId in customerTotals)) {
        customerTotals[invoiceRow.customerId] = {
          customerId: invoiceRow.customerId,
          companyName: invoiceRow.companyName,
          total: 0,
          azure: 0,
          office: 0,
        }
      }

      customerTotals[invoiceRow.customerId].total += invoiceRow.cost
      if (invoiceRow.isOffice) {
        customerTotals[invoiceRow.customerId].office += invoiceRow.cost
      } else {
        customerTotals[invoiceRow.customerId].azure += invoiceRow.cost
      }
    }
    customerTotals = Object.values(customerTotals)

    const columnsTotals = [
      {
        Header: t("_customer"),
        accessor: "companyName",
      },
      {
        Header: t("_total"),
        accessor: "total",
        width: 120,
        Cell: (props) => (props.value !== null ? props.value.toFixed(2) : 0),
      },
      {
        Header: "O365",
        accessor: "office",
        width: 120,
        Cell: (props) => (props.value !== null ? props.value.toFixed(2) : 0),
      },
      {
        Header: "Azure",
        accessor: "azure",
        width: 120,
        Cell: (props) => (props.value !== null ? props.value.toFixed(2) : 0),
      },
      {
        Header: t("_billedBy"),
        accessor: "billedBy.email",
      },
      {
        Header: t("_billedAt"),
        accessor: "billedAt",
        width: 130,
        Cell: (props) =>
          props.value ? moment(props.value).format("DD.MM.YYYY HH:mm") : "",
      },
      {
        Header: t("_status"),
        accessor: "status",
        width: 100,
        Cell: (props) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {(() => {
                switch (props.value) {
                  case "created":
                    return (
                      <span className="icon bi-plus-circle-fill text-primary m-auto" />
                    )
                  case "billed":
                    return (
                      <span className="icon bi-check-circle-fill text-success m-auto" />
                    )
                  case "failed":
                    return (
                      <span className="icon bi-x-circle-fill text-danger m-auto" />
                    )
                }
                return ""
              })()}
            </div>
          )
        },

        filterMethod: (filter, row) => {
          if (filter.value === "") {
            return true
          }
          return row[filter.id] === filter.value
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
          >
            <option value="">{t("_all")}</option>
            <option value="created">{t("_created")}</option>
            <option value="billed">{t("_billed")}</option>
            <option value="failed">{t("_failed")}</option>
          </select>
        ),
      },
      {
        Header: t("_errorMessage"),
        accessor: "errorCode",
        width: 230,
        Cell: (props) => {
          const [tooltipOpen, setTooltipOpen] = useState(false)
          const toggle = () => setTooltipOpen(!tooltipOpen)
          console.log()
          const error =
            t("_invoicingError", { returnObjects: true })[props.value] || ""
          const description =
            t("_invoicingErrorDescription", { returnObjects: true })[
              props.value
            ] || ""

          return (
            <>
              <span id={`error-message-${props.index}`}>{error}</span>
              {description && (
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpen}
                  target={`error-message-${props.index}`}
                  toggle={toggle}
                >
                  {description}
                </Tooltip>
              )}
            </>
          )
        },
      },
    ]

    const columnsItems = [
      {
        Header: t("_customer"),
        accessor: "companyName",
      },
      {
        Header: t("_name"),
        id: "name",
        accessor: (row) =>
          row.fivaldiProductName ||
          row.subscriptionName ||
          (!row.isOffice && row.subscriptionDescription) ||
          row.skuName,

        Cell: (cellInfo) => {
          const [value, setValue] = useState(cellInfo.value)
          // const [debouncedValue, value, setValue, cancelDebounce, setImmediately] = useDebounce(cellInfo.value, 500)
          useEffect(() => {
            setValue(cellInfo.value, true)
          }, [cellInfo.value])
          return (
            <Input
              disabled={
                this.state.statuses.find(
                  (s) => s.customerId === cellInfo.original.customerId
                )?.status === "billed"
              }
              style={{
                // make the input look like a normal cell
                outline: "none",
                backgroundColor: "inherit",
                border: "none",
                height: "20px",
                marginY: "auto",
                boxSizing: "border-box",
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.fivaldiProductName = value
                      }
                      return item
                    }),
                  })
                }
              }}
              onBlur={(e) => {
                if (value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.fivaldiProductName = value
                      }
                      return item
                    }),
                  })
                }
              }}
            />
          )
        },
      },
      {
        Header: t("_serviceName"),
        id: "serviceName",
        accessor: (row) => (row.isOffice ? "" : row.serviceName || row.skuName),
      },
      {
        Header: t("_chargeType"),
        id: "chargeType",
        accessor: "chargeType",
        width: 110,
      },
      {
        Header: t("_calcType"),
        id: "calcType",
        accessor: "calculationType",
      },
      {
        Header: t("_internalUnitPrice"),
        id: "effectiveUnitPrice",
        accessor: (row) => {
          if (row.isOffice && nce) {
            // nce office
            return row.effectiveUnitPrice
          } else if (row.isOffice && !nce) {
            // legacy office
            return (
              Math.round(
                (row.totalForCustomer / 1.24 / row.quantity) * 100000
              ) / 100000
            )
          } else if (!row.isOffice && nce) {
            // nce azure
            return (
              Math.round(
                ((row.totalForCustomer - row.taxTotal) / row.quantity) * 100000
              ) / 100000
            )
          } else if (!row.isOffice && !nce) {
            // legacy azure
            return Math.round((row.postTaxTotal / 1.24) * 100000) / 100000
          }
          return 0
        },
        width: 170,
      },
      {
        Header: t("_unitPrice"),
        id: "unitPrice",
        width: 170,
        accessor: (row) =>
          Math.round((100000 * row.cost) / (row.quantity || 1)) / 100000,

        filterMethod: (filter, row) => {
          if (filter.value === "") {
            return true
          }
          if (filter.value === "noProfit") {
            return (
              row.effectiveUnitPrice > 0 &&
              row.unitPrice - // this is the calculated unit price, not the one from the request
                row.effectiveUnitPrice <
                0
            )
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
          >
            <option value="">{t("_allRows")}</option>
            <option value="noProfit">{t("_unprofitableRows")}</option>
          </select>
        ),
        Cell: (cellInfo) => {
          const [value, setValue] = useState(cellInfo.value)
          // const [debouncedValue, value, setValue, cancelDebounce, setImmediately] = useDebounce(cellInfo.value, 500)
          useEffect(() => {
            setValue(cellInfo.value, true)
          }, [cellInfo.value])

          /*
          useEffect(() => {
            if (value !== cellInfo.value) {
              this.setState({
                items: this.state.items.map((item) => {
                  if (item._id === cellInfo.original._id) {
                    item.cost = parseFloat(debouncedValue)
                  }
                  return item
                })
              })
            }
          }, [debouncedValue])
          */

          return (
            <Input
              disabled={
                this.state.statuses.find(
                  (s) => s.customerId === cellInfo.original.customerId
                )?.status === "billed"
              }
              style={{
                // make the input look like a normal cell
                outline: "none",
                backgroundColor: "inherit",
                border: "none",
                height: "20px",
                marginY: "auto",
                boxSizing: "border-box",
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.cost = parseFloat(value) * item.quantity
                      }
                      return item
                    }),
                  })
                }
              }}
              onBlur={(e) => {
                if (value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.cost = parseFloat(value) * item.quantity
                      }
                      return item
                    }),
                  })
                }
              }}
            />
          )
        },
      },
      {
        Header: t("_quantity"),
        id: "quantity",
        accessor: (row) => row.quantity || 1,
      },
      {
        Header: t("_total"),
        accessor: "cost",
        Cell: (cellInfo) => {
          const [value, setValue] = useState(cellInfo.value)
          // const [debouncedValue, value, setValue, cancelDebounce, setImmediately] = useDebounce(cellInfo.value, 500)
          useEffect(() => {
            setValue(cellInfo.value, true)
          }, [cellInfo.value])

          /*
          useEffect(() => {
            if (value !== cellInfo.value) {
              this.setState({
                items: this.state.items.map((item) => {
                  if (item._id === cellInfo.original._id) {
                    item.cost = parseFloat(debouncedValue)
                  }
                  return item
                })
              })
            }
          }, [debouncedValue])
          */

          return (
            <Input
              disabled={
                this.state.statuses.find(
                  (s) => s.customerId === cellInfo.original.customerId
                )?.status === "billed"
              }
              style={{
                // make the input look like a normal cell
                outline: "none",
                backgroundColor: "inherit",
                border: "none",
                height: "20px",
                marginY: "auto",
                boxSizing: "border-box",
              }}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.cost = parseFloat(value)
                      }
                      return item
                    }),
                  })
                }
              }}
              onBlur={(e) => {
                if (value !== cellInfo.value) {
                  this.setState({
                    items: this.state.items.map((item) => {
                      if (item._id === cellInfo.original._id) {
                        item.cost = parseFloat(value)
                      }
                      return item
                    }),
                  })
                }
              }}
            />
          )
        },
      },
    ]
    return (
      <>
        <div className="d-flex justify-content-between py-2">
          <h3>{t("_customerTotals")}</h3>
          <div
            className="d-flex"
            style={{
              gap: "10px",
            }}
          >
            <Button
              color="primary"
              disabled={
                this.state.saving || this.state.selectedRows.length === 0
              }
              onClick={this.saveFivaldi}
            >
              {t("_saveFivaldi", {
                chosenCustomers: this.state.selectedRows.length,
                numCustomers: Object.keys(customerTotals).length,
              })}
            </Button>
            <Button
              color="primary"
              disabled={
                this.state.calculating || this.state.selectedRows.length === 0
              }
              onClick={() => this.calculateRows(this.state.selectedRows)}
            >
              {t("_calculateRows", {
                chosenCustomers: this.state.selectedRows.length,
                numCustomers: Object.keys(customerTotals).length,
              })}
            </Button>
            <Button
              color="primary"
              disabled={this.state.calculating}
              onClick={() => this.setState({ confirmActionModalOpen: true })}
            >
              {t("_calculateAllRows")}
            </Button>
            <ConfirmActionModal
              title={t("_calculateRowsConfirmModal")}
              message={t("_calculateRowsConfirmModalMessage")}
              open={this.state.confirmActionModalOpen}
              toggle={() =>
                this.setState({
                  confirmActionModalOpen: !this.state.confirmActionModalOpen,
                })
              }
              onConfirm={() => {
                this.setState({ confirmActionModalOpen: false })
                this.calculateRows("all")
              }}
            />
          </div>
        </div>
        <TranslatedTable
          data={combineByField(
            customerTotals,
            this.state.statuses,
            "customerId"
          )}
          fetching={
            this.state.fetching || this.state.calculating || this.state.saving
          }
          columns={columnsTotals}
          noDataText="No items"
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          filterable={true}
          isSelect
          keyField="customerId"
          isSelected={(row) => this.state.selectedRows.includes(row)}
          selectAll={this.state.selectedAll}
          SelectInputComponent={SelectInputComponent}
          toggleAll={() => {
            if (this.state.selectedAll) {
              // deselect all
              this.setState({
                selectedAll: !this.state.selectedAll,
                selectedRows: [],
              })
            } else {
              // select all data that is not filtered
              this.setState({
                selectedAll: !this.state.selectedAll,
                selectedRows: removeDuplicates([
                  ...this.reactTable.current.reactTable.current.wrappedInstance.state.sortedData
                    .filter((x) => x.status !== "billed")
                    .map((x) => x._original.customerId),
                  ...this.state.selectedRows,
                ]),
              })
            }
          }}
          toggleSelection={(row) => {
            const rowId = row.match(/select-(.*)/)[1]
            this.setState({
              selectedRows: this.state.selectedRows.includes(rowId)
                ? this.state.selectedRows.filter((r) => r !== rowId)
                : [...this.state.selectedRows, rowId], // match after select-
              selectedAll:
                this.state.selectedRows.length +
                  (this.state.selectedRows.includes(rowId) ? -1 : 1) ===
                this.state.statuses.filter((x) => x.status !== "billed").length,
            })
          }}
          ref={this.reactTable}
        />
        <hr />
        <h3>{t("_items")}</h3>
        <TranslatedTable
          data={this.state.items}
          fetching={this.state.fetching}
          columns={columnsItems}
          noDataText="No items in invoice"
          handleresize={this.props.setlistsize}
          listSize={this.props.list.listSetting}
          filterable={true}
          customButton={
            <Button
              disabled={this.state.savingRows}
              onClick={this.saveRows}
              color="primary"
              className="float-right"
            >
              {t("_save")}
            </Button>
          }
        />
        <hr />

        <Modal
          isOpen={this.state.modalOpen}
          toggle={() =>
            this.setState((prevState) => ({
              modalOpen: !prevState.modalOpen,
            }))
          }
          centered={true}
        >
          <ModalBody>
            {t(this.state.modalMessage, this.state.modalOptions)}
          </ModalBody>
        </Modal>

        <hr />
      </>
    )
  }
}

export default connect((s) => s, { setlistsize, fail, fetchResources })(
  withTranslation()(InvoiceDetail)
)
