export const ACTION_TYPES = Object.freeze({
  RESET: "VISMA_RESET",
  STATUS: "VISMA_STATUS",
  ERROR: "VISMA_ERROR",
  FETCHED_URL: "VISMA_FETCHED_URL",
})

export const initialState = {
  status: "initial",
  error: null,
  signUrl: null,
}

// status: 'initial' | 'fetching' | 'fetched' | 'signing' | 'signed' | 'error'

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RESET:
      return {
        ...state,
        ...initialState,
      }
    case ACTION_TYPES.STATUS:
      return {
        ...state,
        status: action.status,
      }
    case ACTION_TYPES.ERROR:
      return {
        ...state,
        error: action.error,
        status: "error",
      }
    case ACTION_TYPES.FETCHED_URL:
      return {
        ...state,
        signUrl: action.signUrl,
        status: "fetched",
      }
    default:
      return state
  }
}
