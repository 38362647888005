import { Col, Row } from "reactstrap"

import SoftwareDetails from "./SoftwareDetails"
import SoftwareActions from "./SoftwareActions"
import { PageContainer } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { fetchResources } from "../../actions"

class Software extends React.Component {
  componentDidMount = () => this.props.fetchResources({ resource: "software" })

  render() {
    console.log(this.props.software)
    return (
      <PageContainer title="Details" loading={!this.props.software}>
        <Row>
          <Col sm="7">
            <SoftwareDetails software={this.props.software} />
          </Col>
          <Col sm="5">
            <SoftwareActions software={this.props.software} />
          </Col>
        </Row>
      </PageContainer>
    )
  }
}

const mapStateToProps = (state, props) => {
  const software = state.software.software.find(
    (o) => o.entitlement.skuId.toString() === props.match.params.skuId
  )
  return { software }
}

export default connect(mapStateToProps, { fetchResources })(Software)
