export const ACTION_TYPES = Object.freeze({
  APP_TASK_SET: "APP_TASK_SET",
})

export const initialState = {
  taskArray: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.APP_TASK_SET:
      let newlist = []
      if (action.taskArray) {
        newlist = state.taskArray
        newlist.push(action.taskArray)
      }
      return {
        ...state,
        taskArray: newlist,
      }
    case "LOGOUT":
      return {
        ...initialState,
      }
    default: {
      return state
    }
  }
}
