import { useTranslation } from "react-i18next"
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"

import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap"

import { Loading, SpinnerButton } from "."

import moment from "moment"

const getPriceDifference = (
  customerPrice,
  oldListPrice,
  newCustomerPrice,
  newListPrice
) => {
  if (customerPrice == null) customerPrice = oldListPrice

  const discountMultiplier = customerPrice / oldListPrice
  const newCost = newCustomerPrice
    ? newCustomerPrice
    : newListPrice * discountMultiplier

  return {
    difference: Math.round((newCost - customerPrice) * 100) / 100,
    newCost: Math.round(newCost * 100) / 100,
  }
}

const state = {
  loading: 0,
  loaded: 1,
  updating: 2,
  success: 3,
  failed: 4,
}
const startTransition = async (
  order,
  transition,
  eligibility,
  quantity,
  customerId = undefined
) =>
  axios.post(`/tasks/transition/${order.id}`, {
    fromCatalogItemId: order.offerId,
    toCatalogItemId: transition.catalogItemId,
    transitionType: eligibility,
    quantity,
    customerId: customerId,
  })

const Transitions = (props) => {
  const [t] = useTranslation()
  const { order, customerId, orders } = props

  const [transitions, setTransitions] = useState([])
  const [currentState, setState] = useState(state.loading)
  const [chosenTransition, setChosenTransition] = useState("")
  const [eligibility, setEligibility] = useState("")
  const [quantity, setQuantity] = useState("1")
  const [unitPrice, setUnitPrice] = useState(null)
  const [oldOfferListPrice, setOldOfferListPrice] = useState(null)
  const [newOfferListPrice, setNewOfferListPrice] = useState({
    calculated: false,
    offerPrice: null,
    customerPrice: null,
  })
  const newOrder = orders.find((o) => o.offerId === chosenTransition)
  // load transitions
  useEffect(() => {
    let isSubscribed = true
    const loadTransitions = async () => {
      try {
        const { data } = await axios.get(`/orders/${order.id}/transitions`)
        if (isSubscribed) {
          setTransitions(data.transitions)
          setState(state.loaded)
        }
      } catch (err) {
        console.error(err)
        // if we error we just leave the state as is
      }
    }
    loadTransitions()
    // cleanup if unmounted
    return () => {
      isSubscribed = false
    }
  }, [])

  // load unit price
  useEffect(() => {
    let isSubscribed = true
    const loadUnitPrice = async () => {
      try {
        const { data } = await axios.get(`/licenses/${order.id}`)
        if (isSubscribed) {
          setUnitPrice(data.license.unitPrice)
        }
      } catch (err) {
        console.error(err)
        // if we error we just leave the state as is
      }
    }
    loadUnitPrice()
    // cleanup if unmounted
    return () => {
      isSubscribed = false
    }
  }, [])

  // load old offer list price
  useEffect(() => {
    let isSubscribed = true
    const loadOldOfferListPrice = async () => {
      try {
        const termDuration = moment.duration(order.termDuration)
        let startDateString = ""
        if (order.commitmentEndDate) {
          const endDate = moment(order.commitmentEndDate)

          const startDate = endDate.subtract(termDuration)

          startDateString = startDate.toISOString()
        } else {
          // safe fallback
          startDateString = order.effectiveStartDate
        }

        const { data } = await axios.get(
          `/listprices/${order.offerId}/${order.billingCycle}/${order.termDuration}/${startDateString}`
        )
        if (isSubscribed) {
          setOldOfferListPrice(data.price)
        }
      } catch (err) {
        console.error(err)
        // if we error we just leave the state as is
      }
    }
    loadOldOfferListPrice()
    // cleanup if unmounted
    return () => {
      isSubscribed = false
    }
  }, [])

  // load new offer list price and customerPrice if we have a transition or it changes
  useEffect(() => {
    let isSubscribed = true
    const loadNewOfferListPrice = async () => {
      try {
        // reset the new offer list price
        if (isSubscribed)
          setNewOfferListPrice({
            calculated: false,
            offerPrice: null,
            customerPrice: null,
          })
        const promises = [
          axios.get(
            `/listprices/${chosenTransition}/${order.billingCycle}/${
              order.termDuration
            }/${new Date().toISOString()}`
          ),
          newOrder ? axios.get(`/licenses/${newOrder.id}`) : undefined,
        ]
        const res = await Promise.all(promises)
        if (isSubscribed) {
          console.log(res)
          setNewOfferListPrice({
            calculated: true,
            offerPrice: res[0]?.data?.price || null,
            customerPrice: res[1]?.data?.license?.unitPrice || null,
          })
        }
      } catch (err) {
        console.error(err)
        // if we error we just leave the state as is
      }
    }
    if (chosenTransition) {
      loadNewOfferListPrice()
    }
    // cleanup if unmounted
    return () => {
      isSubscribed = false
    }
  }, [chosenTransition])

  // return loading if loading
  if (currentState === state.loading) return <Loading />

  const transition = transitions.find(
    (transition) => transition.catalogItemId === chosenTransition
  )

  let eligibilities = []
  if (transition) {
    eligibilities = transition.eligibilities.filter(
      (eligibility) => eligibility.isEligible
    )
  }
  const prices = getPriceDifference(
    unitPrice,
    oldOfferListPrice,
    newOfferListPrice.customerPrice,
    newOfferListPrice.offerPrice
  )
  // else render the transitions form
  return (
    <div>
      <h3>{t("_upgrades")}</h3>
      <Form>
        <FormGroup>
          <Input
            type="select"
            onChange={(e) => setChosenTransition(e.target.value)}
            value={chosenTransition}
          >
            <option value="">{t("_chooseTarget")}</option>
            {transitions.map((transition) => (
              <option
                value={transition.catalogItemId}
                key={transition.catalogItemId}
              >
                {transition.title}
              </option>
            ))}
          </Input>
        </FormGroup>
        {transition && <div className="mb-2">{transition.description}</div>}
        {transition && (
          <div>
            <FormGroup>
              <Label for="quantity">
                {t("_quantityUpgrade", {
                  quantity: transition.quantity,
                })}
              </Label>
              <Input
                type="number"
                id="quantity"
                name="quantity"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
                min="1"
                max={transition.quantity}
              />
            </FormGroup>
            <h4>{t("_upgradePrice")}</h4>
            {!newOfferListPrice.calculated ? (
              <Loading />
            ) : (
              <div className="mb-2">
                {t("_newUnitPrice")} {prices.newCost} €
                <br />
                {t("_comparedToOld")} {prices.difference > 0 && "+"}
                {prices.difference /
                  moment.duration(order.termDuration).asMonths()}{" "}
                €/{t("_monthShort")}
              </div>
            )}
          </div>
        )}

        {transition && eligibilities.length > 1 && (
          <FormGroup>
            <Label for="transitionType">{t("_transitionType")}</Label>
            <Input
              type="select"
              id="transitionType"
              name="transitionType"
              onChange={(e) => setEligibility(e.target.value)}
              value={eligibility}
              min={1}
            >
              <option value="">{t("_selectTransitionType")}</option>
              {eligibilities.map((eligibility) => (
                <option
                  value={eligibility.transitionType}
                  key={eligibility.transitionType}
                >
                  {eligibility.transitionType}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}
      </Form>
      <SpinnerButton
        className="mb-2"
        color="primary"
        onClick={async () => {
          try {
            setState(state.updating)
            const { data } = await startTransition(
              order,
              transition,
              eligibility || eligibilities[0].transitionType,
              quantity,
              customerId
            )
            setState(state.success)
          } catch (err) {
            console.error(err)
            setState(state.failed)
          }
        }}
        spin={currentState === state.updating}
        disabled={
          !transition ||
          (eligibility === "" && eligibilities.length !== 1) ||
          currentState === state.updating
        }
      >
        {t("_upgrade")}
      </SpinnerButton>
      {currentState === state.success && (
        <Alert color="success">{t("_success")}</Alert>
      )}
      {currentState === state.failed && (
        <Alert color="danger">{t("_error")}</Alert>
      )}
    </div>
  )
}

export default Transitions
