import React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import { LoadingGate } from "."

const ExpandableList = (props) => {
  const { items, loading, title, emptyText } = props
  const [isHidden, setIsHidden] = useState(true)
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader>
        <div className="d-flex justify-content-between">
          <span>{title}</span>
          <span
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsHidden(!isHidden)}
          >
            {isHidden ? t("_show") : t("_hide")}
          </span>
        </div>
      </CardHeader>
      <CardBody hidden={isHidden}>
        <ListGroup>
          <LoadingGate loading={loading}>
            {items.length === 0 ? (
              <ListGroupItem>{emptyText}</ListGroupItem>
            ) : (
              items.map((o, index) => (
                <ListGroupItem key={index} tag="a" href={o.link}>
                  {o.text}
                </ListGroupItem>
              ))
            )}
          </LoadingGate>
        </ListGroup>
      </CardBody>
    </Card>
  )
}

export default ExpandableList
