import axios from "axios"
import { ACTION_TYPES as APP_TYPE } from "../reducers/app"
import { ACTION_TYPES as LIST_TYPE } from "../reducers/lists"
import { ACTION_TYPES as USER_TYPE } from "../reducers/user"
import { ACTION_TYPES as TASK_TYPE } from "../reducers/tasks"
import { wsLogin } from "./wsActions"

export const fetchResources =
  ({ resource = "", force = false } = {}) =>
  async (dispatch, getState) => {
    try {
      console.log("fetchResources", resource)
      if (!resource) return
      resource = resource.toLowerCase()

      if (!force && !shouldFetch(resource, getState())) return

      dispatch(request(resource))
      console.log("resource", resource)
      const { data } = await axios.get(resource)

      dispatch(receive(resource, data))
    } catch (err) {
      dispatch(setTaskList(resource))
      dispatch(fail(resource, err))
    }
  }

export const shouldFetch = (resource = "", state = {}, seconds = 60) => {
  if (!resource) return false
  resource = resource.toLowerCase()

  if (!state[resource]) return false

  if (state[resource].fetching) return false

  if (Date.now() > state[resource].updateTime + seconds * 1000) return true

  return false
}

export const request = (resource = "") => ({
  type: `${resource.toUpperCase()}_REQUESTED`,
})

export const receive = (resource = "", payload = {}) => ({
  type: `${resource.toUpperCase()}_RECEIVED`,
  [resource.toLowerCase()]: payload[resource.toLowerCase()],
})

export const fail =
  (resource = "", err = {}) =>
  async (dispatch) => {
    if (err.response && err.response.status === 401) {
      dispatch(reloginRequired())
    }
    if (resource) {
      dispatch({
        type: `${resource.toUpperCase()}_ERROR`,
        error: err.message,
      })
    }
  }

export const fetchTask = (taskId) => async (dispatch, getState) => {
  try {
    const { data } = await axios.get(`/tasks/${taskId}`)
    dispatch(
      addTask({
        ...data,
        showNotification: false,
      })
    )
  } catch (err) {
    dispatch(fail(err))
  }
}

export const addTask = (task) => ({
  type: TASK_TYPE.NEW_TASK,
  task,
})

export const updateTask = (id, task) => ({
  type: TASK_TYPE.STATUS_UPDATE,
  id,
  task,
})

export const setTask = (task) => ({
  type: TASK_TYPE.SET_TASK,
  task,
})

export const clearError = (resource = "") => ({
  type: `${resource.toUpperCase()}_ERROR_CLEAR`,
})

export const setlistsize = (listsize = 20) => ({
  type: LIST_TYPE.CUSTOMER_LISTSIZE_SET,
  listsize,
})

export const reloginRequired = () => ({
  type: USER_TYPE.RELOGIN_REQUIRED,
})

export const setTaskList = (taskArray) => ({
  type: APP_TYPE.APP_TASK_SET,
  taskArray,
})

export const executeTasks = () => async (dispatch, getState) => {
  try {
    const state = getState()
    if (state.app.taskArray && state.app.taskArray.length > 0) {
      state.app.taskArray.forEach((task) => {
        dispatch(fetchResources({ resource: task, force: true }))
      })
    }
    dispatch(setTaskList(null))
  } catch (err) {
    // dispatch(fail(resource, err))
  }
}
export const adlogin =
  ({ token }) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post("/adlogin", { token })
      dispatch({
        type: USER_TYPE.LOGIN,
        isAdmin: data.user.isAdmin,
        isClientAdmin: data.user.isClientAdmin,
        email: data.user.email,
        token: data.accessToken,
        customer: data.user.customer,
        signed: data.user.signed,
      })
      await dispatch(wsLogin(data.accessToken))
    } catch (err) {
      console.log(err)
    }
  }

export const login =
  ({ email, password, token }) =>
  async (dispatch, getState) => {
    try {
      const request = {
        email,
        password,
        token, // mfa token
      }

      const { data } = await axios.post("/login", request)
      if (data.tokenRequired) {
        dispatch({
          type: USER_TYPE.TOKEN_REQUIRED,
        })
        return
      }
      dispatch(executeTasks())
      dispatch({
        type: USER_TYPE.LOGIN,
        isAdmin: data.user.isAdmin,
        isClientAdmin: data.user.isClientAdmin,
        email: data.user.email,
        token: data.accessToken,
        customer: data.user.customer,
        signed: data.user.signed,
      })
      await dispatch(wsLogin(data.accessToken))
    } catch (err) {
      throw err
    }
  }

// TODO: this could update the token too
export const update = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const { data } = await axios.get("/users/me")
    dispatch(executeTasks())
    dispatch({
      type: USER_TYPE.UPDATE,
      isAdmin: data.user.isAdmin,
      isClientAdmin: data.user.isClientAdmin,
      email: data.user.email,
      customer: data.user.customer,
      signed: data.user.signed,
    })
  } catch (err) {
    dispatch(fail(null, err))
    throw err
  }
}
