import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"

import { Loading } from "../../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
//import countryISOCodes from "../../res/countryISOCodes"
import Validator from "../../../lib/validator"
import jwt from "jsonwebtoken"
import VismaSign from "../VismaSign"
import { fetchCompanyInfo, resetCompanyInfo } from "../../../actions/prh"

const countryISOCodes = {
  FI: "Finland",
}

const statusToColor = {
  initial: "primary",
  fetching: "primary",
  success: "success",
  error: "danger",
}

class AccountInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      companyRegistrationNumber: "",
      formErrors: {},
    }
    this.state = {
      ...this.state,
      ...props.data,
    }
    this.validator = new Validator({
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      companyRegistrationNumber: {
        required: true,
      },
    })
  }
  handleChange = ({ target }) => {
    const formErrors = { ...this.state.formErrors }
    delete formErrors[target.name]
    this.setState({
      [target.name]: target.value,
      formErrors,
    })
    if (target.name === "companyRegistrationNumber") {
      this.props.resetCompanyInfo()
    }
  }

  validate = () => {
    const formErrors = this.validator.validate(this.state, this.props.t)
    if (Object.keys(formErrors).length !== 0) {
      this.setState({ formErrors })
      return false
    }
    this.setState({ formErrors })
    return true
  }

  render() {
    const { t } = this.props
    const token = this.props.match.params.token
    const decoded = jwt.decode(token)
    const { sub: email } = decoded

    return (
      <div className="container-fluid">
        <Form onSubmit={(e) => e.preventDefault()}>
          <h5>{t("_userDetails")}</h5>
          <Row>
            <Col>
              <FormGroup>
                <Label for="firstName">
                  {t("_firstName")} <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  placeholder="John"
                  invalid={"firstName" in this.state.formErrors}
                />
                <FormFeedback>{this.state.formErrors.firstName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="lastName">
                  {t("_lastName")} <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  placeholder="Doe"
                  invalid={"lastName" in this.state.formErrors}
                />
                <FormFeedback>{this.state.formErrors.lastName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="phoneNumber">{t("_phoneNumber")}</Label>
                <Input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                  placeholder=""
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="email">{t("_email")}</Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  readOnly
                />
                <FormFeedback>{this.state.formErrors.email}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label for="companyRegistrationNumber">
                  {t("_companyRegistrationNumber")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="companyRegistrationNumber"
                    id="companyRegistrationNumber"
                    value={this.state.companyRegistrationNumber}
                    onChange={this.handleChange}
                    invalid={
                      "companyRegistrationNumber" in this.state.formErrors
                    }
                  />
                  <InputGroupAddon addonType="append" color="">
                    <Button
                      color={statusToColor[this.props.prh.status]}
                      onClick={(e) => {
                        e.preventDefault()
                        this.props.fetchCompanyInfo({
                          companyRegistrationNumber:
                            this.state.companyRegistrationNumber,
                        })
                      }}
                      disabled={this.props.prh.status === "fetching"}
                    >
                      {t("_check")}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback>
                  {this.state.formErrors.companyRegistrationNumber}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="companyName">
                  {t("_companyName")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={this.props.prh.companyInfo?.name || ""}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button
            className="float-right"
            color="primary"
            onClick={() => {
              if (!this.validate()) return
              this.props.next({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                phoneNumber: this.state.phoneNumber,
                companyRegistrationNumber: this.state.companyRegistrationNumber,
              })
            }}
            disabled={
              Object.keys(this.state.formErrors).length !== 0 ||
              this.props.prh.status !== "success"
            }
            style={{textTransform: 'capitalize'}}
          >
            {t("_next")}
            
          </Button>
        </Form>
      </div>
    )
  }
}

export default connect((s) => s, { fetchCompanyInfo, resetCompanyInfo })(
  withTranslation()(AccountInformation)
)
