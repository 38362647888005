import { combineReducers } from "redux"
import list from "./lists"
import r from "./resource"
import user from "./user"
import app from "./app"
import tasks from "./tasks"
import visma from "./visma"
import prh from "./prh"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import register from "./register"

/*
export default (state, action) => {
  if (action.type === "LOGOUT") {
    state = { user: { loggedIn: false, loggedOut: true } }
  }

  return combineReducers({
    app,
    licenseusers: r("licenseusers"),
    customers: r("customers"),
    invoices: r("invoices"),
    invoicesbilled: r("invoicesbilled"),
    invoicesbilleddb: r("invoicesbilleddb"),
    list,
    listprices: r("listprices"),
    orders: r("orders"),
    software: r("software"),
    summary: r("summary", { isObj: true }),
    user: persistReducer({
      key: "user",
      storage,
      blacklist: ["tokenRequired"],
    }, user),
    users: r("users"),
    subscribed: r("subscribed"),
    nceoffers: r("nceoffers"),
    tasks,
  })(state, action)
}*/
export default combineReducers({
  app,
  licenseusers: r("licenseusers"),
  customers: r("customers"),
  invoices: r("invoices"),
  invoicesbilled: r("invoicesbilled"),
  invoicesbilleddb: r("invoicesbilleddb"),
  list,
  listprices: r("listprices"),
  orders: r("orders"),
  software: r("software"),
  summary: r("summary", { isObj: true }),
  ordercache: r("ordercache"),
  user: persistReducer(
    {
      key: "user",
      storage,
      blacklist: ["tokenRequired"],
    },
    user
  ),
  users: r("users"),
  subscribed: r("subscribed"),
  partnerstats: r("partnerstats"),
  nceoffers: r("nceoffers"),
  tasks,
  visma,
  prh,
  relationshiprequest: r("relationshiprequest", { isObj: true }),
  register,
})
