import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  FormFeedback,
  InputGroup,
  InputGroupText,
  Spinner,
  InputGroupAddon,
} from "reactstrap"

import { Loading } from "../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
//import countryISOCodes from "../../res/countryISOCodes"
import Validator from "../../lib/validator"
import { fetchCompanyInfo, resetCompanyInfo } from "../../actions/prh"

const countryISOCodes = {
  FI: "Finland",
}

const statusToColor = {
  initial: "primary",
  fetching: "primary",
  success: "success",
  error: "danger",
}

class AccountInformation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      country: "FI",
      domain: "",
      companyRegistrationNumber: "",
      formErrors: {},
      domainState: {
        checking: false,
        valid: false,
        touched: false,
      },
    }
    this.state = {
      ...this.state,
      ...props.data,
    }
    this.validator = new Validator({
      firstName: {
        required: true,
      },
      lastName: {
        required: true,
      },
      email: {
        required: true,
        match: {
          // http://emailregex.com/
          regex:
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
          msg: `${props.t("_email")} ${props.t("_isInvalid")}`,
        },
      },
      domain: {
        required: true,
      },
      companyRegistrationNumber: {
        required: true,
      },
    })
  }
  isDomainAvailable = async (e) => {
    const domain = e.target.value
    if (domain === "") {
      return this.setState({
        domainState: {
          checking: false,
          valid: false,
          touched: true,
        },
        formErrors: this.validator.validateOne(
          {
            ...this.state,
            domain,
          },
          this.props.t,
          this.state.formErrors,
          "domain"
        ),
      })
    }
    this.setState({
      domainState: {
        checking: true,
        valid: false,
        touched: true,
      },
    })
    try {
      const { data } = await axios.get(`/domainavailable/${domain}`)
      if (this.state.domain === domain) {
        this.setState({
          domainState: {
            checking: false,
            valid: data.available,
            touched: true,
          },
          formErrors: this.validator.validateOne(
            this.state,
            this.props.t,
            this.state.formErrors,
            "domain"
          ),
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  handleChange = ({ target }) => {
    const formErrors = { ...this.state.formErrors }
    delete formErrors[target.name]
    this.setState({
      [target.name]: target.value,
      formErrors,
    })
    if (target.name === "companyRegistrationNumber") {
      this.props.resetCompanyInfo()
    }
  }

  validate = () => {
    const formErrors = this.validator.validate(this.state, this.props.t)
    if (Object.keys(formErrors).length !== 0) {
      this.setState({ formErrors })
      return false
    }
    this.setState({ formErrors })
    if (
      this.state.domainState.checking ||
      !this.state.domainState.valid ||
      this.props.prh.status !== "success"
    ) {
      return false
    }
    return true
  }

  next = (e) => {
    e.preventDefault()
    if (!this.validate()) return
    this.props.next({
      accountInformation: {
        ...this.state,
      },
    })
  }
  updateName = async () => {
    this.props.fetchCompanyInfo({
      companyRegistrationNumber: this.state.companyRegistrationNumber,
    })
  }

  render() {
    const { t } = this.props
    return (
      <div className="container-fluid">
        <Form>
          <h5>{t("_userDetails")}</h5>
          <Row>
            <Col>
              <FormGroup>
                <Label for="firstName">
                  {t("_firstName")} <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  placeholder="John"
                  invalid={"firstName" in this.state.formErrors}
                />
                <FormFeedback>{this.state.formErrors.firstName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="lastName">
                  {t("_lastName")} <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  placeholder="Doe"
                  invalid={"lastName" in this.state.formErrors}
                />
                <FormFeedback>{this.state.formErrors.lastName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="phoneNumber">{t("_phoneNumber")}</Label>
                <Input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={this.state.phoneNumber}
                  onChange={this.handleChange}
                  placeholder=""
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="email">{t("_email")}</Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={this.state.email}
                  readOnly
                />
                <FormFeedback>{this.state.formErrors.email}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <h5>{t("_companyDetails")}</h5>
          <Row>
            <Col>
              <FormGroup>
                <Label for="companyRegistrationNumber">
                  {t("_companyRegistrationNumber")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="companyRegistrationNumber"
                    id="companyRegistrationNumber"
                    value={this.state.companyRegistrationNumber}
                    onChange={this.handleChange}
                    invalid={
                      "companyRegistrationNumber" in this.state.formErrors
                    }
                  />
                  <InputGroupAddon addonType="append" color="">
                    <Button
                      color={statusToColor[this.props.prh.status]}
                      onClick={this.updateName}
                      disabled={this.props.prh.status === "fetching"}
                    >
                      {t("_check")}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback>
                  {this.state.formErrors.companyRegistrationNumber}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="companyName">
                  {t("_companyName")}{" "}
                  <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={this.props.prh.companyInfo?.name || ""}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="domain">
                  {t("_domain")} <span style={{ color: "#FF0000" }}>*</span>
                  {this.state.domainState.checking && (
                    <Spinner size="sm"></Spinner>
                  )}
                </Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="domain"
                    id="domain"
                    value={this.state.domain}
                    onChange={(e) => {
                      this.handleChange(e)
                      this.isDomainAvailable(e)
                    }}
                    placeholder="e.g. mycompany"
                    invalid={
                      "domain" in this.state.formErrors ||
                      (this.state.domainState.touched &&
                        !this.state.domainState.valid &&
                        !this.state.domainState.checking)
                    }
                  />
                  <InputGroupText style={{ borderRadius: "0px" }}>
                    .onmicrosoft.com
                  </InputGroupText>
                </InputGroup>
                <FormFeedback
                  style={{
                    display:
                      "domain" in this.state.formErrors ||
                      (this.state.domainState.touched &&
                        !this.state.domainState.valid &&
                        !this.state.domainState.checking)
                        ? "block"
                        : "none",
                  }}
                >
                  {"domain" in this.state.formErrors
                    ? this.state.formErrors.domain
                    : "Domain not available"}
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="country">
                  {t("_country")} <span style={{ color: "#FF0000" }}>*</span>
                </Label>
                <Input
                  type="select"
                  name="country"
                  id="country"
                  value={this.state.country}
                  onChange={this.handleChange}
                >
                  {Object.keys(countryISOCodes).map((code) => (
                    <option key={code} value={code}>
                      {countryISOCodes[code]}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button
            className="float-right"
            color="primary"
            onClick={this.next}
            disabled={
              Object.keys(this.state.formErrors).length !== 0 ||
              !this.state.domainState.valid ||
              this.props.prh.status !== "success"
            }
          >
            {t("_next")}
          </Button>
        </Form>
      </div>
    )
  }
}

export default connect((s) => s, { fetchCompanyInfo, resetCompanyInfo })(
  withTranslation()(AccountInformation)
)
