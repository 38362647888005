import { fetchResources, setlistsize } from "../../actions"

import { PageContainer, Offers } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

class OffersPage extends React.Component {
  componentDidMount = () => {
    this.props.fetchResources({ resource: "nceoffers" })
  }

  render() {
    const { t } = this.props
    return (
      <PageContainer
        title={t("_offers")}
        loading={this.props.nceoffers.fetching}
      >
        <Offers offers={this.props.nceoffers.nceoffers} />
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources })(
  withTranslation()(OffersPage)
)
