import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap"

import React from "react"
import { connect } from "react-redux"
import navbarLogo from "../res/navbar.svg"
import { withTranslation } from "react-i18next"

class TopNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
    }
    this.toggleNavbar = this.toggleNavbar.bind(this)
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }
  render = () => {
    const { t } = this.props
    return (
      <Navbar
        className="bg-dark"
        color="dark"
        expand="xl"
        style={{ marginBottom: "20px" }}
        dark
      >
        <Container>
          <NavbarBrand href="/#/" className="px-2">
            <img
              src={navbarLogo}
              className="avanio-logo"
              style={{ marginRight: "1em" }}
              alt="Avanio logo"
            />
            Partner Center
          </NavbarBrand>
          {this.props.loggedIn ? (
            <NavbarToggler onClick={this.toggleNavbar} />
          ) : null}
          {this.props.loggedIn ? (
            <Collapse navbar isOpen={!this.state.collapsed}>
              <Nav className="mr-auto" navbar>
                {this.props.isAdmin ? (
                  <>
                    <NavItem>
                      <NavLink href="/#/customers">
                        {t("_customers").toUpperCase()}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/#/users">
                        {t("_users").toUpperCase()}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/#/invoices">
                        {t("_invoices").toUpperCase()}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/#/orders">
                        {t("_orders").toUpperCase()}
                      </NavLink>
                    </NavItem>
                  </>
                ) : (
                  <>
                    <NavItem>
                      <NavLink href="/#/">
                        {t("_dashboard").toUpperCase()}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/#/offers">
                        {t("_offers").toUpperCase()}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/#/orders">
                        {t("_orders").toUpperCase()}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/#/licenseUsers">
                        {t("_users").toUpperCase()}
                      </NavLink>
                    </NavItem>
                  </>
                )}

                <NavItem>
                  <NavLink href="/#/report">
                    {t("_report").toUpperCase()}
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {this.props.email}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href="/#/settings">
                      {t("_settings")}
                    </DropdownItem>
                    <DropdownItem href="/#/logout">{t("_logout")}</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          ) : null}
        </Container>
      </Navbar>
    )
  }
}

export default connect((s) => s.user)(withTranslation()(TopNavbar))
