import React from "react"

const SelectInputComponent = (props) => {
  return (
    <input
      type={props.selectType || "checkbox"}
      aria-label={`${props.checked ? "Un-select" : "Select"} row with id:${
        props.id
      }`}
      checked={props.checked}
      disabled={props.row.status === "billed"}
      id={props.id}
      onClick={(e) => {
        const { shiftKey } = e
        e.stopPropagation()
        props.onClick(props.id, shiftKey, props.row)
      }}
      onChange={() => {}}
    />
  )
}

export default SelectInputComponent
