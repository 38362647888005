import React, { useState } from "react"
import {
  Button,
  Input,
  Row,
  Label,
  Col,
  Container,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

function PriceUpdater(props) {
  const [normalPrice, setNormalPrice] = useState("")
  const [azurePrice, setAzurePrice] = useState("")
  const [popoverAzureOpen, setPopoverAzureOpen] = useState(false)
  const [popoverOtherOpen, setPopoverOtherOpen] = useState(false)
  const { t } = props
  const PopoverLabelColor = "#007bff"

  const applyEmpty = () => {
    setNormalPrice("")
    setAzurePrice("")
    props.applyPrice(normalPrice, azurePrice, true)
  }

  const applyAll = () => {
    setNormalPrice("")
    setAzurePrice("")
    props.applyPrice(normalPrice, azurePrice, false)
  }

  const changeAzurePrice = (e) => {
    let value = e.target.value
    //value is between 0-15
    value = value > 15 ? 15 : value < 0 ? 0 : value
    setAzurePrice(value)
  }

  return (
    <Container className={"d-flex justify-content-end"}>
      <Row className={"d-flex align-items-end"}>
        <Col>
          <Label
            id="azureLabel"
            onMouseEnter={() => setPopoverAzureOpen(true)}
            onMouseLeave={() => setPopoverAzureOpen(false)}
            for="azure"
          >
            <u style={{ color: PopoverLabelColor }}>{t("_azureOrders")}</u>
          </Label>
          <Popover
            placement="top"
            isOpen={popoverAzureOpen}
            target="azureLabel"
          >
            <PopoverHeader>{t("_azureOrders")}</PopoverHeader>
            <PopoverBody>{t("_azureOrdersPopoverDescription")}</PopoverBody>
          </Popover>
          <Input
            type="number"
            name="azure"
            id="azure"
            value={azurePrice}
            onChange={(e) => changeAzurePrice(e)}
          />
        </Col>
        <Col>
          <Label
            id="otherLabel"
            onMouseEnter={() => setPopoverOtherOpen(true)}
            onMouseLeave={() => setPopoverOtherOpen(false)}
            for="other"
          >
            {" "}
            <u style={{ color: PopoverLabelColor }}>{t("_otherOrders")}</u>
          </Label>
          <Popover
            placement="top"
            isOpen={popoverOtherOpen}
            target="otherLabel"
          >
            <PopoverHeader>{t("_otherOrders")}</PopoverHeader>
            <PopoverBody>{t("_otherOrdersPopoverDescription")}</PopoverBody>
          </Popover>
          <Input
            type="number"
            name="other"
            id="other"
            value={normalPrice}
            onChange={(e) => setNormalPrice(e.target.value)}
          />
        </Col>
        <Col
          sm="5"
          className={"d-flex align-items-end justify-content-end mr-1"}
        >
          <Button
            className={"mr-1"}
            disabled={azurePrice === "" && normalPrice === ""}
            color={"primary"}
            onClick={applyEmpty}
          >
            {t("_applyToEmpty")}
          </Button>
          <Button
            disabled={azurePrice === "" && normalPrice === ""}
            onClick={applyAll}
            color={"primary"}
          >
            {t("_applyToAll")}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default connect(null)(withTranslation()(PriceUpdater))
