import {
  // Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  // NavbarToggler,
  UncontrolledDropdown,
  Toast,
  ToastHeader,
  ToastBody,
  Button,
  Progress,
  NavLink,
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap"

import ReactTable from "react-table"
import PageContainer from "../../components/PageContainer"
import React, { useEffect } from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { fetchTask } from "../../actions"
import axios from "axios"

const Task = (props) => {
  const task = useSelector((state) =>
    state.tasks.tasks.find((task) => props.match.params.task === task._id)
  )
  const [t] = useTranslation()
  const dispatch = useDispatch()

  // if task is not found, try to fetch it
  useEffect(() => {
    if (!task) {
      dispatch(fetchTask(props.match.params.task))
    }
  }, [props.match.params.task])

  if (task == null) {
    return <div></div>
  }

  return (
    <PageContainer title={t(`_${task.friendlyName}`)}>
      <div id="accordion">
        {task.tasks.map((task, index) => (
          <Card key={index}>
            <CardHeader id={`heading${index}`}>
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target={`#collapse${index}`}
                  aria-expanded="true"
                  aria-controls={`#collapse${index}`}
                >
                  {t(`_${task.type}`)}
                </button>
                <span className="text-center">{t(`_${task.subStatus}`)}</span>
              </div>
            </CardHeader>
            <div
              id={`collapse${index}`}
              className="collapse"
              aria-labelledby={`#heading${index}`}
              data-parent="#accordion"
            >
              <CardBody>
                <h5>{t("_messages")}</h5>
                <ListGroup>
                  {task.messages.map((message, index) => (
                    <ListGroupItem
                      color={
                        message.messageType === "error" ? "danger" : "success"
                      }
                      key={index}
                    >
                      {message.text
                        .split(" ")
                        .map((word) => (word[0] === "_" ? t(word) : word))
                        .join(" ")}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </div>
          </Card>
        ))}
      </div>
    </PageContainer>
  )
}
export default Task
