import {
  Alert,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap"

import { Loading } from "../../components"
import { withTranslation } from "react-i18next"
import React from "react"
import axios from "axios"
import { connect } from "react-redux"
import { fail } from "../../actions"

class CreateAccount extends React.Component {
  state = {
    fetching: false,
    error: null,
    email: "",
    password: "",
    customerId: "",
    isClientAdmin: false,
    sendResetEmail: false,
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  handleCheck = ({ target }) => this.setState({ [target.name]: target.checked })

  submit = (e) => {
    e.preventDefault()

    this.setState({ fetching: true })

    const { email, password, customerId, isClientAdmin, sendResetEmail } =
      this.state

    const request = {
      email,
      password,
      customerId,
      isClientAdmin,
      sendResetEmail,
    }

    axios
      .post("/users", request)
      .then(this.props.onClose)
      .catch((error) =>
        this.props
          .fail(null, error)
          .then(() => this.setState({ error, fetching: false }))
      )
  }

  render() {
    const { t } = this.props
    return (
      <Card>
        <CardBody>
          {this.state.fetching ? (
            <Loading small />
          ) : (
            <Form onSubmit={this.submit}>
              <FormGroup>
                <Label for="email">{t("_email")}</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="john.doe@example.com"
                  value={this.state.email}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label for="password">{t("_password")}</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                  placeholder="••••••••"
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label for="customerId">{t("_customer")}</Label>
                <Input
                  type="select"
                  name="customerId"
                  id="customerId"
                  onChange={this.handleChange}
                  value={this.state.customerId}
                >
                  {this.props.customers.map((c) => (
                    <option value={c.id} key={c.id}>
                      {c.companyName}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              {this.state.customerId ? (
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      id="isClientAdmin"
                      name="isClientAdmin"
                      onChange={this.handleCheck}
                    />
                    {t("_clientAdmin")}
                  </Label>
                </FormGroup>
              ) : null}

              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="sendResetEmail"
                    name="sendResetEmail"
                    onChange={this.handleCheck}
                  />
                  {t("_sendResetMail")}
                </Label>
              </FormGroup>

              <hr />

              {this.state.error ? (
                <Alert color="danger">{t("_errorMsg_addAccount")}</Alert>
              ) : null}

              <Button type="submit" block>
                {t("_createAccount")}
              </Button>
            </Form>
          )}
        </CardBody>
      </Card>
    )
  }
}

export default connect(null, { fail })(withTranslation()(CreateAccount))
