import { fetchResources, setlistsize } from "../../actions"

import axios from "axios"
import { Link } from "react-router-dom"
import { PageContainer, OfferDetail } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { durationToString } from "../../lib/date"
import moment from "moment"

class OfferDetailCustomer extends React.Component {
  state = {
    sku: "",
    chosenPlan: "",
    quantity: "",
    fetching: false,
    msg: "",
    success: true,
  }

  componentDidMount = () => {
    this.props.fetchResources({ resource: "nceoffers" })
  }

  order = async (data) => {
    await axios.post(`/ordersUser`, data)
  }

  render() {
    const { t } = this.props
    const filteredOffers = this.props.nceoffers.nceoffers.filter(
      (offer) => offer._id === this.props.match.params.offerId
    )
    if (filteredOffers.length === 0) {
      return <h3 className="text-center">Not Found</h3>
    }
    const product = filteredOffers[0]
    return (
      <PageContainer
        title={product.productTitle}
        loading={this.props.nceoffers.fetching}
      >
        <OfferDetail offer={product} order={this.order} />
      </PageContainer>
    )
  }
}

export default connect((s) => s, { fetchResources })(
  withTranslation()(OfferDetailCustomer)
)
