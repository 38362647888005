import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { login } from "../actions"

class LoginModal extends React.Component {
  state = {
    email: "",
    password: "",
    error: null,
    modalOpen: false,
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  login2 = async (e) => {
    e.preventDefault()
    setTimeout(function () {
      window.location = "https://partners.avanio.com/auth"
    }, 500)
  }

  login = async (e) => {
    e.preventDefault()
    const { email, password } = this.state
    this.props.login({ email: email, password: password })
  }

  render = () => {
    const { t } = this.props
    return (
      <Modal
        isOpen={!this.props.user.token && this.props.user.loggedIn}
        toggle={this.toggle}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggle}>{t("_login")}</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.login}>
            <FormGroup>
              <Label for="email">{t("_email")}</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={this.state.email}
                onChange={this.handleChange}
                placeholder="john.doe@example.com"
              />
            </FormGroup>

            <FormGroup>
              <Label for="password">{t("_password")}</Label>
              <Input
                type="password"
                name="password"
                id="password"
                value={this.state.password}
                onChange={this.handleChange}
                placeholder="••••••••"
              />
            </FormGroup>

            {this.state.error ? (
              <Alert color="danger">{t("_loginFailed")}</Alert>
            ) : null}

            {this.props.user.loggedOut ? (
              <Alert color="primary">{t("_logoutsuccess")}</Alert>
            ) : null}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.login}>{t("_login")}</Button>
          <br />
          <Button onClick={this.login2}>AD Login</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default connect((s) => s, { login })(withTranslation()(LoginModal))
