import { Button, Form, Input, Badge } from "reactstrap"

import { Loading } from "../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { fail } from "../../actions"

const statusToColor = (status) => {
  switch (status) {
    case "active":
      return "success"
    case "suspended":
      return "danger"
    default:
      return "secondary"
  }
}

class Order extends React.Component {
  changePercentageOrPrice = (e) => {
    const { order, changeUnitPriceOrPercentage } = this.props
    let value = e.target.value
    if (order.type === "azure") {
      //value is between 0-15
      //value will be converted into: value = 15-value
      value = value > 15 ? 0 : value < 0 ? 15 : 15 - value
    }
    changeUnitPriceOrPercentage(order.id, value)
  }
  changeOfficePercentage = (e) => {
    const { order, changeUnitPriceOrPercentage } = this.props
    let value = e.target.value
    changeUnitPriceOrPercentage(order.id, value, {
      isPercentage: true,
    })
  }

  changeQuantity = (e) => {
    const { order, changeOrderQuantity } = this.props
    let value = e.target.value
    changeOrderQuantity(order.id, value)
  }

  getPercentage = (value) => {
    //The value that is stored in the database for Azure subscriptions is the margin for that subscription
    //The value displayed in the UI is how many percentage points are reduced from the max margin of 15.
    //This function converts the database value to the UI value.
    let returnValue = 15 - value
    if (isNaN(returnValue)) return ""
    return returnValue
  }

  update = (e) => {
    e.preventDefault()
    const { order, updateUnitPriceOrPercentage } = this.props
    updateUnitPriceOrPercentage(
      order.id,
      order.type === "azure" ? order.currentPercentage : order.currentUnitPrice
    )
  }

  updateQty = (e) => {
    e.preventDefault()
    const { quantity, order, updateOrderQuantity } = this.props
    updateOrderQuantity(order.id, order.quantity)
  }

  customColumnToString = (order, column) => {
    const { t } = this.props
    switch (column) {
      case "isTrial":
        return order.isTrial ? t("_true") : t("_false")
      case "status":
        return t(`_${order.status}`)
      case "termDuration":
        return order.termDuration
      case "autoRenewEnabled":
        return order.autoRenewEnabled ? t("_true") : t("_false")
      case "creationDate":
        return new Date(order.creationDate).toLocaleDateString()
      case "autoRenewDate":
        return order.autoRenewEnabled
          ? new Date(order.commitmentEndDate).toLocaleDateString()
          : "-"
      case "type":
        return order.type
    }
    return ""
  }

  render = () => {
    const { order, t, listPrice, percentageOrPrice } = this.props
    const originalPrice =
      order.type === "azure" ? order.marginPercentage : order.unitPrice
    return (
      <tr>
        <td title={order.id} style={{ verticalAlign: "middle" }}>
          <Form onSubmit={this.update} id={order.id}>
            {order.friendlyName}
            <Badge color={statusToColor(order.status)}>
              {t(`_${order.status}`)}
            </Badge>
            {order?.productType?.id === "OnlineServicesNCE" && (
              <Badge color="primary">NCE</Badge>
            )}
          </Form>
          <Form onSubmit={this.updateQty} id={order.id + "Quantity"}></Form>
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          {order.type === "azure" ? "" : listPrice}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          {`${order.termDuration} ${order.billingCycle}`}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          {this.customColumnToString(order, this.props.customColumn)}
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          <Input
            className="text-right"
            style={{ width: 150 }}
            form={order.id + "Quantity"}
            disabled={order.type === "azure"}
            type="number"
            name="percentageOrPrice"
            value={order.type === "azure" ? "" : order.quantity}
            onChange={this.changeQuantity}
            min={1}
            step={1}
          />
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          <Input
            className="text-right"
            style={{ width: 150 }}
            form={order.id}
            disabled={order.type === "azure"}
            type="number"
            name="percentageOrPrice"
            value={order.type === "azure" ? "" : order.currentUnitPrice || ""}
            onChange={this.changePercentageOrPrice}
            min={0}
            step={0.001}
          />
        </td>
        <td style={{ verticalAlign: "middle", textAlign: "center" }}>
          <Input
            className="text-right"
            style={{ width: 150 }}
            form={order.id}
            type="number"
            name="percentageOrPrice"
            value={
              order.type === "azure"
                ? this.getPercentage(order.currentPercentage)
                : order.currentPercentage
            }
            onChange={
              order.type === "azure"
                ? this.changePercentageOrPrice
                : this.changeOfficePercentage
            }
            min={0}
            step={0.1}
          />
        </td>
        {/* <td>
              <Input
                type="date"
                name="effectiveStarting"
                value={this.state.effectiveStarting}
                onChange={this.handleChange}
              />
            </td> */}
        <td>
          {order.updating ? (
            <Loading small />
          ) : (
            <Button
              form={order.id}
              disabled={
                originalPrice ==
                (order.type === "azure"
                  ? order.currentPercentage
                  : order.currentUnitPrice)
              }
              color={
                order.success ? "success" : order.error ? "danger" : "primary"
              }
              type="submit"
              block
            >
              {t("_update")}
            </Button>
          )}
        </td>
        <td>
          {order.updating2 ? (
            <Loading small />
          ) : (
            <Button
              form={order.id + "Quantity"}
              disabled={order.type === "azure"}
              color={
                order.success2 ? "success" : order.error2 ? "danger" : "primary"
              }
              type="submit"
              block
            >
              {t("_update") + " qty"}
            </Button>
          )}
        </td>
      </tr>
    )
  }
}

export default connect(null, { fail })(withTranslation()(Order))
