import OrderDetails from "./OrderDetails"
import { PageContainer } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { fetchResources } from "../../actions"

class Order extends React.Component {
  componentDidMount = () => {
    this.props.fetchResources({ resource: "orders" })
    this.props.fetchResources({ resource: "invoices" })
  }

  render = () => (
    <PageContainer
      title={`Details: ${this.props.order && this.props.order.name}`}
      loading={!this.props.order || !this.props.invoices.length}
    >
      <OrderDetails order={this.props.order} invoices={this.props.invoices} />
    </PageContainer>
  )
}

const mapStateToProps = (state, props) => {
  const order = state.orders.orders.find(
    (o) => o.id.toString() === props.match.params.orderId
  )
  return { order, invoices: state.invoices.invoices }
}

export default connect(mapStateToProps, { fetchResources })(Order)
