import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

import { Loading } from "../components"
import React from "react"
import axios from "axios"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

class ResetPassword extends React.Component {
  state = {
    password: "",
    passwordAgain: "",
    fetching: false,
    success: false,
    error: null,
  }

  handleChange = ({ target }) => this.setState({ [target.name]: target.value })

  resetPassword = (e) => {
    e.preventDefault()

    this.setState({ fetching: true, success: false, error: null })

    const { password, passwordAgain } = this.state
    const { token } = this.props.match.params

    axios
      .post("/reset-password", { token, password, passwordAgain })
      .then(() => this.setState({ fetching: false, success: true }))
      .catch((err) => this.setState({ fetching: false, error: err.message }))
  }

  render() {
    const { t } = this.props
    return (
      <Container>
        <Row>
          <Col sm={{ size: 4, offset: 4 }}>
            <h1 className="text-center text-primary">{t("_resetPassword")}</h1>
            <hr />
            <Card>
              <CardBody>
                <Form onSubmit={this.resetPassword}>
                  <fieldset disabled={this.state.fetching}>
                    <FormGroup>
                      <Label for="password">{t("_password")}</Label>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        minLength={6}
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="passwordAgain">{t("_passwordAgain")}</Label>
                      <Input
                        type="password"
                        name="passwordAgain"
                        id="passwordAgain"
                        value={this.state.passwordAgain}
                        onChange={this.handleChange}
                        minLength={6}
                        required
                      />
                    </FormGroup>

                    {this.state.error ? (
                      <Alert color="danger">{t("_errorMsg_passReset")}</Alert>
                    ) : null}

                    {this.state.success ? (
                      <Alert color="success">
                        {t("_successMsg_passReset")}
                      </Alert>
                    ) : null}

                    {this.state.success ? (
                      <Button color="primary" href="/#/" block>
                        {t("_goToLogin")}
                      </Button>
                    ) : (
                      <Button type="submit" block>
                        {this.state.fetching ? <Loading small /> : t("_submit")}
                      </Button>
                    )}
                  </fieldset>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(withTranslation()(ResetPassword))
