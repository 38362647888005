import { ACTION_TYPES } from "../reducers/visma"
import axios from "axios"

export const reset = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.RESET,
  })
}

export const startSign =
  ({ language, token, companyRegistrationNumber }) =>
  async (dispatch, getState) => {
    if (
      getState().visma.status === "fetching" ||
      getState().visma.status === "signing"
    ) {
      return
    }

    dispatch({
      type: ACTION_TYPES.STATUS,
      status: "fetching",
    })

    try {
      const { data } = await axios.post(`/vismasign/${language}/${token}`, {
        companyRegistrationNumber,
      })
      dispatch({
        type: ACTION_TYPES.FETCHED_URL,
        signUrl: data.signUrl,
      })
      // start polling
      dispatch(pollStatusAtInterval({ token }))
    } catch (err) {
      dispatch({
        type: ACTION_TYPES.ERROR,
        error: err,
      })
    }
  }

export const pollStatusAtInterval =
  ({ token, interval = 5 }) =>
  async (dispatch, getState) => {
    if (
      getState().visma.status !== "signing" &&
      getState().visma.status !== "fetched"
    ) {
      return
    }

    const poll = async () => {
      if (
        getState().visma.status !== "signing" &&
        getState().visma.status !== "fetched"
      ) {
        return
      }
      try {
        const { data } = await axios.get(`/pollstatus/${token}`)
        if (data.status.status === "signed") {
          dispatch({
            type: ACTION_TYPES.STATUS,
            status: "signed",
          })
          return
        }
      } catch (err) {
        console.log(err)
      }
      setTimeout(poll, interval * 1000)
    }

    poll()
  }

export const userRedirected = () => async (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.STATUS,
    status: "signing",
  })
}
