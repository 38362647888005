/**
 * Json should be an array of objects, where the key is always the accessor and value has the value of the field
 * The first object should have all possible headers even if the value is undefined
 * @param {Array.Object.<string, any>} json
 * @param {import("xlsx").WorkSheet} ws
 */
export const autofitColumns = (json, ws) => {
  if (json.length === 0) {
    return undefined
  }
  const headers = Object.keys(json[0])
  const rowLengths = []
  headers.forEach((header, i) => {
    const column = headers.map((header) => json.map((row) => row[header]))
    let maxLength =
      Math.max(
        ...column[i].map((item) => (item != null ? item.toString().length : 0))
      ) + 1
    if (maxLength < header.length) maxLength = header.length
    rowLengths.push(maxLength)
  })

  ws["!cols"] = rowLengths.map((length) => ({ wch: length + 5 }))
}
