import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Badge,
  Input,
  Label,
  FormGroup,
  Form,
  Alert,
  Spinner,
} from "reactstrap"
import { fetchResources, setlistsize } from "../../actions"

import axios from "axios"
import { Link } from "react-router-dom"
import { PageContainer, OfferDetail } from "../../components"
import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { durationToString } from "../../lib/date"
import moment from "moment"

/*
        <FormGroup className="flex-fill px-2">
            <Label size="lg" for="termDuration">{t("_termDuration")}</Label>
              <Input id="termDuration" type="select" name="termDuration" value={this.state.termDuration} onChange={this.handleChange}>
                {Object.keys(terms).map((term) => 
                <option key={term} value={term}>
                  {terms[term]}
                </option>)}
            </Input> 
        </FormGroup>
        <FormGroup className="flex-fill px-2">
            <Label size="lg" for="billingPlan">{t("_billingPlan")}</Label>
              <Input id="billingPlan" type="select" name="billingPlan" value={this.state.billingPlan} onChange={this.handleChange}>
                {}
            </Input> 
        </FormGroup>
        <FormGroup className="flex-fill px-2">
            <Label size="lg" for="termDuration">{t("_termDuration")}</Label>
              <Input id="termDuration" type="select" name="termDuration" value={this.state.termDuration} onChange={this.handleChange}>
                {Object.keys(terms).map((term) => 
                <option key={term} value={term}>
                  {terms[term]}
                </option>)}
            </Input> 
        </FormGroup>*/

class OfferDetailAdmin extends React.Component {
  state = {
    sku: "",
    chosenPlan: "",
    quantity: "",
    fetching: false,
    msg: "",
    success: true,
  }

  componentDidMount = () => {
    this.props.fetchResources({ resource: "nceoffers" })
    this.props.fetchResources({ resource: "customers" })
  }

  order = async (data) => {
    await axios.post(`/ordersUser`, {
      ...data,
      customerId: this.props.match.params.customerId,
    })
  }

  render() {
    const { t } = this.props
    const filteredOffers = this.props.nceoffers.nceoffers.filter(
      (offer) => offer._id === this.props.match.params.offerId
    )
    if (filteredOffers.length === 0) {
      return <h3 className="text-center">Not Found</h3>
    }
    const product = filteredOffers[0]
    return (
      <PageContainer
        title={`${product.productTitle} - ${
          this.props.customer == null ? "" : this.props.customer.companyName
        }`}
        loading={this.props.nceoffers.fetching}
      >
        <OfferDetail offer={product} order={this.order} />
      </PageContainer>
    )
  }
}
const mapStateToProps = (state, props) => {
  const customer = state.customers.customers.find(
    (i) => i.id.toString() === props.match.params.customerId
  )
  return { customer, nceoffers: state.nceoffers }
}
export default connect(mapStateToProps, { fetchResources })(
  withTranslation()(OfferDetailAdmin)
)
