import { ACTION_TYPES } from "../reducers/register"
import axios from "axios"

export const reset = () => ({
  type: ACTION_TYPES.RESET,
})

const status = (status) => ({
  type: ACTION_TYPES.STATUS,
  status,
})

const error = (error) => ({
  type: ACTION_TYPES.ERROR,
  error,
})

const accept = () => status("accepted")

export const redirected = () => status("redirected")

export const register =
  ({ token, firstName, lastName, phoneNumber }) =>
  async (dispatch) => {
    try {
      dispatch(status("registering"))
      await axios.post(
        "/register-existing",
        {
          firstName,
          lastName,
          phoneNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      dispatch(status("success"))
    } catch (err) {
      dispatch(error(err))
    }
  }

export const pollStatusAtInterval =
  ({ token, interval = 5 }) =>
  async (dispatch, getState) => {
    const poll = async () => {
      if (
        getState().register.status === "accepted" ||
        getState().register.status === "error" ||
        getState().register.status === "success"
      ) {
        return
      }
      try {
        const { data } = await axios.get(`/relationshiprequest/status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        if (data.status === "accepted") {
          dispatch(accept())
          return
        }
      } catch (err) {
        console.log(err)
      }
      setTimeout(poll, interval * 1000)
    }
    poll()
  }
