import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap"
import ReactChartkick, { PieChart } from "react-chartkick"

import Chart from "chart.js"
import { withTranslation } from "react-i18next"
import { Loading, TranslatedTable } from "../../components"
import { setlistsize, fail } from "../../actions"
import { connect } from "react-redux"
import React from "react"
import axios from "axios"
import moment from "moment"

ReactChartkick.addAdapter(Chart)

class OrderDetails extends React.Component {
  state = {
    fetching: true,
    invoices: [
      {
        id: "latest",
        index: 0,
        label: "Current month",
      },
    ],
    invoiceIndex: 0,
    invoiceId: "latest",
    items: [],
    usageFetching: true,
    chartGroup: {
      id: "category",
      translation: "_category",
    },
    subscriptionChartData: {},
    categoryChartData: {},
    resourceGroupChartData: {},
    activeTab: "1",
  }

  componentDidMount = async () => {
    try {
      const { data } = await axios
        .get(`/orders/${this.props.order.id}`)
        .catch((error) =>
          this.props
            .fail(null, error)
            .then(() => this.setState({ usageFetching: false }))
        )
      this.setState({ items: data.items })

      this.getRgUsage("latest")

      this.updateTotal()
      this.makeChartData()
    } catch (err) {
      console.error(err)
    }

    const invoices = [
      ...this.state.invoices,
      ...this.props.invoices.invoices
        .filter(
          (i) =>
            i.id.toLowerCase().startsWith("g") ||
            this.props.order.offerName !== "Azure plan"
        )
        .map((i, index) => ({
          id: i.id,
          index: index + 1,
          label: `${moment(i.billingPeriodStartDate).format(
            "MMM Do YYYY"
          )} - ${moment(i.billingPeriodEndDate).format("MMM Do YYYY")}`,
        })),
    ]

    this.setState({ fetching: false, invoices })
  }

  getRgUsage = (invoiceId) =>
    axios
      .get(`/orders/${this.props.order.id}/${invoiceId}/resourcegroup`)
      .then(({ data }) => {
        if (this.state.invoiceId === invoiceId) {
          const subscriptionChartData = {}
          data.items.forEach((i) => {
            subscriptionChartData[i.entitlementName]
              ? (subscriptionChartData[i.entitlementName] += i.totalCost)
              : (subscriptionChartData[i.entitlementName] = i.totalCost)
          })

          Object.keys(subscriptionChartData).forEach(
            (k) =>
              (subscriptionChartData[k] = subscriptionChartData[k].toFixed(2))
          )

          // get resource group data from usage
          const resourceGroupChartData = {}

          data.items.forEach((i) => {
            resourceGroupChartData[i.resourceGroupName]
              ? (resourceGroupChartData[i.resourceGroupName] += i.totalCost)
              : (resourceGroupChartData[i.resourceGroupName] = i.totalCost)
          })
          Object.keys(resourceGroupChartData).forEach(
            (k) =>
              (resourceGroupChartData[k] = resourceGroupChartData[k].toFixed(2))
          )
          this.setState({
            subscriptionChartData,
            resourceGroupChartData,
            usageFetching: false,
          })
        }
      })
      .catch((error) =>
        this.props
          .fail(null, error)
          .then(() => this.setState({ usageFetching: false }))
      )

  updateTotal = () => {
    const totalCost = this.state.items.reduce(
      (acc, curr) => (acc += curr.totalCost),
      0
    )

    this.setState({ totalCost: totalCost.toFixed(2) })
  }

  makeChartData = () => {
    const { items } = this.state
    const categoryChartData = {}
    items.forEach((i) => {
      categoryChartData[i.category]
        ? (categoryChartData[i.category] += i.totalCost)
        : (categoryChartData[i.category] = i.totalCost)
    })

    Object.keys(categoryChartData).forEach(
      (k) => (categoryChartData[k] = categoryChartData[k].toFixed(2))
    )
    this.setState({
      categoryChartData,
    })
  }

  changeMonth = async (direction) => {
    this.setState({ fetching: true, usageFetching: true, usage: null })
    const increment = direction === "prev" ? 1 : -1

    // Clamp to invoices list length
    const newIndex = Math.min(
      Math.max(0, this.state.invoiceIndex + increment),
      this.state.invoices.length - 1
    )

    this.setState({ invoiceIndex: newIndex })

    if (newIndex === 0) {
      return this.componentDidMount()
    }

    const invoice = this.state.invoices.find((i) => i.index === newIndex)

    try {
      const { data } = await axios.get(
        `/orders/${this.props.order.id}/invoice/${invoice.id}`
      )

      this.setState({ items: data.items, invoiceId: invoice.id })

      this.getRgUsage(invoice.id)

      this.updateTotal()
      this.makeChartData()
    } catch (err) {
      this.props
        .fail(null, err)
        .then(() => this.setState({ error: true, fetching: false }))
    }

    this.setState({ fetching: false })
  }

  toggleTab = (tab) =>
    this.state.activeTab !== tab ? this.setState({ activeTab: tab }) : null

  toggleChart = (group) => this.setState({ chartGroup: group })

  render = () => {
    const { t } = this.props
    const invoice = this.state.invoices.find(
      (i) => i.index === this.state.invoiceIndex
    )

    const columns = [
      {
        Header: t("_name"),
        accessor: "name",
      },
      {
        Header: t("_category"),
        accessor: "category",
      },
      {
        Header: t("_subcategory"),
        accessor: "subcategory",
      },
      {
        Header: t("_quantityUsed"),
        accessor: "quantityUsed",
      },
      {
        Header: t("_unit"),
        accessor: "unit",
      },
      {
        Header: t("_totalCost"),
        accessor: "totalCost",
        Cell: (props) =>
          props.original.totalCost < 0.01
            ? "< 0.01"
            : props.original.totalCost.toFixed(2),
      },
    ]

    return (
      <>
        <Row>
          <Col sm={{ size: 4, offset: 4 }}>
            {this.state.fetching ? (
              <Loading small />
            ) : (
              <h1 className="text-center">{this.state.totalCost} EUR</h1>
            )}
          </Col>
        </Row>
        <fieldset disabled={this.state.fetching}>
          <Row>
            <Col
              sm={{ size: 4, offset: 4 }}
              className="d-flex justify-content-between align-items-center"
            >
              <Button
                color="primary"
                onClick={() => this.changeMonth("prev")}
                disabled={
                  this.state.invoiceIndex === this.state.invoices.length - 1
                }
              >
                &lt;
              </Button>
              <span>{invoice.label}</span>
              <Button
                color="primary"
                onClick={() => this.changeMonth("next")}
                disabled={this.state.invoiceIndex === 0}
              >
                &gt;
              </Button>
            </Col>
          </Row>
        </fieldset>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={this.state.activeTab === "1" ? "active" : ""}
              onClick={() => {
                this.toggleTab("1")
              }}
            >
              {t("_chart")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={this.state.activeTab === "2" ? "active" : ""}
              onClick={() => {
                this.toggleTab("2")
              }}
            >
              {t("_items")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <br />
          <TabPane tabId="1">
            <Card>
              <CardHeader>
                {t("_usageGroupedBy")}{" "}
                <UncontrolledDropdown tag="span">
                  <DropdownToggle
                    tag="span"
                    style={{ cursor: "pointer" }}
                    caret
                  >
                    {t(this.state.chartGroup.translation)}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() =>
                        this.toggleChart({
                          translation: "_category",
                          id: "category",
                        })
                      }
                    >
                      {t("_category")}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.toggleChart({
                          translation: "_subscription",
                          id: "subscription",
                        })
                      }
                    >
                      {t("_subscription")}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.toggleChart({
                          translation: "_resourceGroup",
                          id: "resource group",
                        })
                      }
                    >
                      {t("_resourceGroup")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                {this.state.fetching ? (
                  <Loading />
                ) : this.state.chartGroup.id === "subscription" ? (
                  <PieChart
                    data={this.state.subscriptionChartData}
                    messages={{
                      empty: t("_noBillingCycle"),
                    }}
                    legend="bottom"
                    colors={chartColors}
                    height="500px"
                  />
                ) : this.state.chartGroup.id === "category" ? (
                  <PieChart
                    data={this.state.categoryChartData}
                    messages={{
                      empty: t("_noBillingCycle"),
                    }}
                    legend="bottom"
                    colors={chartColors}
                    height="500px"
                  />
                ) : this.state.usageFetching ? (
                  <>
                    <p className="text-muted text-center">
                      {t("_thisWillTakeAWhile")}
                    </p>
                    <Loading />
                  </>
                ) : (
                  <>
                    <p className="text-muted text-center">
                      {t("_resourceGroupUsage")}
                    </p>
                    <PieChart
                      data={this.state.resourceGroupChartData}
                      messages={{
                        empty: t("_noBillingCycle"),
                      }}
                      legend="right"
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="2">
            <TranslatedTable
              data={this.state.items}
              fetching={this.state.fetching}
              columns={columns}
              handleresize={this.props.setlistsize}
              listSize={this.props.list.listSetting}
              noDataText="No usage during current billing cycle"
            />
          </TabPane>
        </TabContent>
      </>
    )
  }
}

const colorList = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#651067",
]

const chartColors = colorList
  .concat(colorList)
  .concat(colorList)
  .concat(colorList)
  .concat(colorList)

export default connect((s) => s, { setlistsize, fail })(
  withTranslation()(OrderDetails)
)
