import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  FormText,
  FormFeedback,
  ListGroup,
  ListGroupItem,
  InputGroup,
} from "reactstrap"

import React from "react"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import axios from "axios"
import { Loading } from "."
import countryISOCodes from "../res/countryISOCodes"
import Validator from "../lib/validator"
import { fetchResources } from "../actions"
import * as generatePassword from "password-generator"

const emptyState = {
  email: "",
  firstName: "",
  lastName: "",
  displayName: "",
  username: "",
  password: "",
  usageLocation: "FI",
  showPassword: false,
  active: true,
  licenses: [],
  askForPermission: false,
  error: null,
  modalOpen: false,
  fetching: false,
  touchedField: {
    displayName: false,
    username: false,
  },
  formErrors: {},
  orders: {},
}

const normalizeUsername = (username) =>
  username
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")

const buildDisplayName = (state, update) => {
  return `${"firstName" in update ? update.firstName : state.firstName} ${
    "lastName" in update ? update.lastName : state.lastName
  }`
}

const buildUsername = (state, update) => {
  return normalizeUsername(
    `${"firstName" in update ? update.firstName : state.firstName}${
      (state.lastName === "" && !("lastName" in update)) ||
      update.lastName === ""
        ? ""
        : "." + ("lastName" in update ? update.lastName : state.lastName)
    }`
  )
}

const buildUserPrincipalName = (state) => {
  return `${normalizeUsername(state.username)}@${state.domain}`
}

class CreateUserModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      domain: "",
      domains: [],
    }
    Object.assign(this.state, emptyState)
    this.validator = new Validator({
      displayName: {
        required: true,
      },
      username: {
        required: true,
      },
      password: {
        required: true,
        minLength: 8,
        match: {
          regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          msg: `${this.props.t("_password")} ${this.props.t("_notComplex")}`,
        },
      },
    })
  }

  componentDidMount() {
    this.getDomains()
      .then((domains) => {
        console.log(domains)
        this.setState({
          domains,
          domain: domains[0],
        })
      })
      .catch((err) => console.log(err))
  }

  handleChange = ({ target }, data = null) => {
    switch (target.name) {
      case "firstName":
      case "lastName":
        const changes = { [target.name]: target.value }
        if (!this.state.touchedField.displayName) {
          changes.displayName = buildDisplayName(this.state, changes)
        }
        if (!this.state.touchedField.username) {
          changes.username = buildUsername(this.state, changes)
        }
        this.setState(changes)
        break
      case "displayName":
        this.setState({
          [target.name]: target.value,
          touchedField: {
            ...this.state.touchedField,
            displayName: true,
          },
        })
        break
      case "username":
        this.setState({
          [target.name]: target.value,
          touchedField: {
            ...this.state.touchedField,
            username: true,
          },
        })
        break
      case "order":
        this.setState({
          orders: {
            ...this.state.orders,
            [data]: target.value,
          },
        })
        break
      default:
        this.setState({ [target.name]: target.value })
        break
    }
  }

  validate = () => {
    const formErrors = this.validator.validate(this.state, this.props.t)
    if (Object.keys(formErrors).length !== 0) {
      this.setState({ formErrors })
      return false
    }
    return true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalOpen === false && this.props.modalOpen === true) {
      // modal opened
      this.props.fetchResources({ resource: "subscribed", force: true })
      this.props.fetchResources({ resource: "orders", force: true })
    }
  }

  getOrdersForSubscription = (subscription) => {
    const orders = this.props.orders.orders.filter(
      (order) =>
        order.name.toLowerCase() ===
          subscription.productSku.name.toLowerCase() &&
        order.status === "active"
    )
    return orders
  } // would rather do this with id but its not option for nce offers, this expression might have bugs(with nce) and there is probably a better way to do this

  createUser = async (e = null, buyLicenses = true) => {
    if (e != null) {
      e.preventDefault()
    }
    if (!this.validate()) return
    if (
      !this.state.askForPermission &&
      this.props.subscribed.subscribed
        .filter((subscription) => subscription.availableUnits === 0)
        .some((sub) => this.state.licenses.includes(sub.productSku.id))
    ) {
      this.setState({
        askForPermission: true,
      })
      const subs = this.props.subscribed.subscribed.filter(
        (subscription) =>
          subscription.availableUnits === 0 &&
          this.state.licenses.includes(subscription.productSku.id)
      )
      const orders = {}
      for (const subscription of subs) {
        const o = this.getOrdersForSubscription(subscription, true)
        if (o.length > 0) {
          orders[subscription.productSku.id] = o[0].id
        }
      }

      this.setState({
        orders,
      })
      return
    }
    const unavailable = this.props.subscribed.subscribed
      .filter((subscription) => subscription.availableUnits === 0)
      .map((subscription) => subscription.productSku.id)
    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      displayName: this.state.displayName,
      userPrincipalName: buildUserPrincipalName(this.state),
      usageLocation: this.state.usageLocation,
      password: this.state.password,
      state: this.state.active ? "active" : "inactive",
      licenses: this.state.licenses.filter(
        (license) =>
          !unavailable.includes(license) ||
          (buyLicenses && Object.keys(this.state.orders).includes(license))
      ),
      orders: buyLicenses
        ? Object.keys(this.state.orders).map((license) => {
            return {
              id: this.state.orders[license],
              license,
            }
          })
        : [],
    }
    this.setState({
      fetching: true,
    })
    try {
      const response = await axios.post("/customeruser", data, {
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,
        },
      })
      let errored = false
      this.props.userCreated(true, errored ? 1 : 0) // error code 1 means failed to assign licenses
    } catch (err) {
      console.log(err)
      this.props.userCreated(false)
    } finally {
      this.setState(emptyState)
    }
  }
  getCountryCodes = async () => {
    const resp = await axios.get(
      `${window.location.origin}/ISO3166-1.alpha2.json`
    )
    return resp.data
  }
  getDomains = async () => {
    const resp = await axios.get("/customerdomains", {
      headers: { Authorization: `Bearer ${this.props.user.token}` },
    })
    return resp.data.domains
  }

  handleLicenseChange = (id, value) => {
    const licenses = this.state.licenses
    if (value) {
      this.setState({
        licenses: [...licenses, id],
      })
    } else {
      licenses.splice(licenses.indexOf(id), 1)
      this.setState({
        licenses: licenses,
      })
    }
  }

  render = () => {
    const { t } = this.props
    return (
      <Modal
        isOpen={this.props.modalOpen}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.toggle}>{t("_createUser")}</ModalHeader>
        {this.state.fetching ? (
          <Loading />
        ) : this.state.askForPermission ? (
          <div>
            <h5 className="p-3">{t("_doYouWantAutomaticPurchase")}</h5>
            <p className="p-3">{t("_notEnoughLicenses")}:</p>
            <ListGroup>
              {this.props.subscribed.subscribed
                .filter(
                  (subscription) =>
                    subscription.availableUnits === 0 &&
                    this.state.licenses.includes(subscription.productSku.id)
                )
                .map((subscription) => (
                  <ListGroupItem
                    key={subscription.productSku.id}
                    className="d-flex justify-content-between"
                  >
                    <div>{subscription.productSku.name}</div>
                    <Input
                      type="select"
                      name="order"
                      className="w-50"
                      value={this.state.orders[subscription.productSku.id]}
                      onChange={(e) =>
                        this.handleChange(e, subscription.productSku.id)
                      }
                    >
                      {this.getOrdersForSubscription(subscription).map(
                        (order) => (
                          <option key={order.id} value={order.id}>{`${
                            "productType" in order &&
                            order.productType.id === "OnlineServicesNCE"
                              ? "NCE "
                              : ""
                          }${order.termDuration} ${
                            order.billingCycle
                          }`}</option>
                        )
                      )}
                    </Input>
                  </ListGroupItem>
                ))}
            </ListGroup>
            <small className="text-muted my-3">
              {t("_multipleOrdersForLicense")}
            </small>
          </div>
        ) : (
          <ModalBody>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="firstName">{t("_firstName")}</Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                      placeholder="John"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="lastName">{t("_lastName")}</Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                      placeholder="Doe"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="displayName">{t("_displayName")}</Label>
                    <Input
                      type="text"
                      name="displayName"
                      id="displayName"
                      value={this.state.displayName}
                      onChange={this.handleChange}
                      placeholder="John Doe"
                      invalid={"displayName" in this.state.formErrors}
                    />
                    <FormText>{t("_required")}</FormText>
                    <FormFeedback>
                      {this.state.formErrors.displayName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Label for="username">{t("_username")}</Label>
              <Col className="mb-3 pl-0">
                <Row
                  className="ml-0"
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: "0.25rem",
                  }}
                >
                  <FormGroup className="mb-0 flex-grow-1">
                    <Input
                      style={{
                        border: `${
                          "username" in this.state.formErrors
                            ? "1px solid #dc3545"
                            : "0px"
                        }`,
                      }}
                      className="shadow-none"
                      type="text"
                      name="username"
                      id="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      placeholder="john.doe"
                      invalid={"username" in this.state.formErrors}
                    />
                  </FormGroup>
                  <FormGroup className="mb-0 ml-auto">
                    <Input
                      style={{ border: "0px" }}
                      className="shadow-none"
                      type="select"
                      name="domain"
                      id="domain"
                      value={this.state.domain}
                      onChange={this.handleChange}
                    >
                      {this.state.domains.map((domain) => (
                        <option key={domain} value={domain}>
                          @{domain}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Row>
                <FormText>{t("_required")}</FormText>
                <FormFeedback
                  className={
                    "username" in this.state.formErrors ? "d-block" : "d-none"
                  }
                >
                  {this.state.formErrors.username}
                </FormFeedback>
              </Col>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="password">{t("_password")}</Label>
                    <InputGroup>
                      <Input
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        value={this.state.password}
                        onChange={this.handleChange}
                        invalid={"password" in this.state.formErrors}
                      />
                      <Button
                        color="primary"
                        onClick={() => {
                          let password = ""
                          try {
                            let valid = false
                            // create a password with 12 characters, at least one number, one lowercase and one uppercase letter
                            while (!valid) {
                              password = generatePassword(12, false)
                              const lowercaseChars = password.match(/[a-z]/g)
                              const uppercaseChars = password.match(/[A-Z]/g)
                              const numbers = password.match(/[0-9]/g)
                              if (lowercaseChars && uppercaseChars && numbers) {
                                valid = true
                              }
                            }
                          } catch (e) {
                            alert(
                              "Browser does not support password generation"
                            )
                            return
                          }
                          this.setState({ password })
                        }}
                      >
                        {t("_generateRandom")}
                      </Button>
                    </InputGroup>
                    <FormText>
                      {t("_required")} - {t("_passwordHelper")}
                    </FormText>
                    <FormFeedback>
                      {this.state.formErrors.password}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check for="showPassword">
                      <Input
                        type="checkbox"
                        name="showPassword"
                        id="showPassword"
                        onChange={() =>
                          this.setState({
                            showPassword: !this.state.showPassword,
                          })
                        }
                        value={this.state.showPassword}
                      />
                      {t("_showPassword")}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="usageLocation">{t("_usageLocation")}</Label>
                    <Input
                      type="select"
                      name="usageLocation"
                      id="usageLocation"
                      value={this.state.usageLocation}
                      onChange={this.handleChange}
                    >
                      {Object.keys(countryISOCodes).map((code) => (
                        <option key={code} value={code}>
                          {countryISOCodes[code]}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="active"
                        id="active"
                        defaultChecked={this.state.active}
                        onChange={() =>
                          this.setState({
                            active: !this.state.active,
                          })
                        }
                      />
                      {t("_active")}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Label className="mt-3">{t("_assignLicense")}</Label>
                  <br />
                  {this.props.subscribed.subscribed.map((item) => (
                    <FormGroup check inline key={item.productSku.id}>
                      <Input
                        id={item.productSku.id}
                        type="checkbox"
                        onChange={(e) =>
                          this.handleLicenseChange(
                            item.productSku.id,
                            e.target.checked
                          )
                        }
                      ></Input>
                      <Label check for={item.productSku.id}>
                        {item.productSku.name}
                      </Label>
                    </FormGroup>
                  ))}
                </Col>
              </Row>
            </Form>
          </ModalBody>
        )}

        <ModalFooter>
          <Button
            color="danger"
            disabled={this.state.fetching}
            onClick={(e) => {
              if (this.state.askForPermission) {
                this.createUser(e, false)
                return
              }
              this.setState(emptyState)
              this.props.toggle()
            }}
          >
            {this.state.askForPermission ? t("_false") : t("_close")}
          </Button>
          <Button
            color="primary"
            disabled={this.state.fetching}
            onClick={this.createUser}
          >
            {this.state.askForPermission ? t("_true") : t("_createUser")}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default connect((s) => s, { fetchResources })(
  withTranslation()(CreateUserModal)
)
