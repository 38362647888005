import React, { useEffect } from "react"
import { Container, Alert } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { register } from "../../../actions/register"
import { Loading } from "../../../components"

export default (props) => {
  const registerStatus = useSelector((s) => s.register.status)
  const { t } = useTranslation()
  const { token } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      register({
        ...props.allData[0],
        token,
      })
    )
  }, [])

  if (registerStatus === "registering") {
    return <Loading />
  }

  return (
    <Container>
      <Alert color={registerStatus === "success" ? "success" : "danger"}>
        {registerStatus === "success"
          ? t("_companyRegistrationSuccessful")
          : (<>
              {t("_companyRegistrationFailed")} <a href="https://avanio.com/fi/tukipyynnot/">{t("_this")}</a>
          </>)}
      </Alert>
      {registerStatus === "success" && <Link to="/">{t("_login")}</Link>}
    </Container>
  )
}
