import {
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
  FormFeedback,
  FormText,
  InputGroup,
  InputGroupText,
  Alert,
} from "reactstrap"

import { Loading, PageContainer } from "../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
//import countryISOCodes from "../../res/countryISOCodes"
import Validator from "../../lib/validator"

const countryISOCodes = {
  FI: "Finland",
}

class PreAuth extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      hasTenant: false,
      formErrors: {},
      loading: false,
      success: false,
      msg: "",
    }
    this.validator = new Validator({
      email: {
        required: true,
        match: {
          // http://emailregex.com/
          regex:
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
          msg: `${props.t("_email")} ${props.t("_isInvalid")}`,
        },
      },
      hasTenant: {
        custom: {
          func: (value) => value != null,
        },
      },
    })
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value })
  }

  validate = () => {
    const formErrors = this.validator.validate(this.state, this.props.t)
    if (Object.keys(formErrors).length !== 0) {
      this.setState({ formErrors })
      return false
    }
    this.setState({ formErrors })
    return true
  }

  send = async (e) => {
    e.preventDefault()
    if (!this.validate()) return
    this.setState({
      loading: true,
    })
    const data = {
      email: this.state.email,
      language: "en",
      hasTenant: this.state.hasTenant,
    }
    switch (this.props.i18n.language) {
      case "en-EN":
        data.language = "en"
        break
      case "fi-FI":
        data.language = "fi"
        break
    }
    try {
      await axios.post("/beginregister", data)
      this.setState({
        success: true,
        msg: this.props.t("_sentActivationEmail"),
        loading: false,
      })
    } catch (err) {
      this.setState({
        success: false,
        msg: err.response.data,
        loading: false,
      })
    }
  }

  render() {
    const { t } = this.props
    return (
      <PageContainer
        title={t("_register")}
        className="mb-5"
        loading={this.state.loading}
      >
        {this.state.msg !== "" && (
          <Alert color={this.state.success ? "success" : "danger"}>
            {this.state.msg}
          </Alert>
        )}
        <Form onSubmit={this.send}>
          <Row>
            <Col>
              <Label for="hasTenant">{t("_hasTenantQuestion")}</Label>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="hasTenant"
                    onChange={() => this.setState({ hasTenant: true })}
                    checked={this.state.hasTenant}
                  />
                  {t("_yes")}
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="hasTenant"
                    onChange={() => this.setState({ hasTenant: false })}
                    checked={!this.state.hasTenant}
                  />
                  {t("_no")}
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="email">
                  {t("_email")} ({t("_useTenantEmail")})
                </Label>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="John Doe"
                  invalid={"email" in this.state.formErrors}
                />
                <FormFeedback>{this.state.formErrors.email}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Button className="float-right" color="primary" onClick={this.send}>
            {t("_submit")}
          </Button>
        </Form>
      </PageContainer>
    )
  }
}

export default connect((s) => s)(withTranslation()(PreAuth))
