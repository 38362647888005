import { PageContainer } from "../../components"
import React from "react"
import UserInfoForm from "./UserInfoForm"
import { connect } from "react-redux"
import { fetchResources } from "../../actions"

class User extends React.Component {
  componentDidMount = () =>
    this.props.fetchResources({ resource: "users", force: true })

  render = () => (
    <PageContainer
      title={this.props.user && this.props.user.email}
      loading={!this.props.user}
    >
      <UserInfoForm user={this.props.user} />
    </PageContainer>
  )
}

const mapStateToProps = (state, props) => {
  const user = state.users.users.length
    ? state.users.users.find(
        (u) => u.id.toString() === props.match.params.userId
      )
    : null

  return { user }
}

export default connect(mapStateToProps, { fetchResources })(User)
