import React, { useEffect, useState } from "react"
import AccountInformation from "./AccountInformation"
import Sign from "./Sign"
import { use } from "chai"
import MultiPageHandler from "../../../components/MultiPageHandler"
import { useDispatch, useSelector } from "react-redux"
import { register } from "../../../actions/register"
import Done from "./Done"

export default (props) => {
  const registerStatus = useSelector((s) => s.register.status)

  console.log(registerStatus)

  return (
    <MultiPageHandler {...props}>
      <AccountInformation />
      <Sign />
      <Done />
    </MultiPageHandler>
  )
}
