import {
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap"

import { LoadingGate } from "../../components"
import React, { useEffect } from "react"
import { fetchResources } from "../../actions"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const Licenses = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchResources({ resource: "subscribed" }))
  }, [dispatch])

  const subscribed = useSelector((state) => state.subscribed)

  const getColor = (consumedUnits, activeUnits) => {
    if (consumedUnits <= activeUnits && consumedUnits >= activeUnits * 0.8) {
      return "success"
    } else if (
      consumedUnits <= activeUnits * 0.8 &&
      consumedUnits > activeUnits * 0.5
    ) {
      return "warning"
    } else {
      return "danger"
    }
  }
  return (
    <Card>
      <CardHeader>{t("_licenses")}</CardHeader>
      <CardBody>
        <LoadingGate loading={subscribed.fetching}>
          <ListGroup flush>
            {subscribed.subscribed.map((subscription) => (
              <ListGroupItem key={subscription.productSku.id}>
                {subscription.productSku.name}
                <Badge
                  color={getColor(
                    subscription.consumedUnits,
                    subscription.activeUnits
                  )}
                  className="float-right"
                >
                  {subscription.consumedUnits} / {subscription.activeUnits}
                </Badge>
              </ListGroupItem>
            ))}
          </ListGroup>
        </LoadingGate>
      </CardBody>
    </Card>
  )
}

/*
class Licenses extends React.Component {
  componentDidMount = () => {
    this.props.fetchResources({ resource: "subscribed" })
  }

  render() {
    const { t, software, orders } = this.props

    const filteredOrders = orders.orders
      .filter(
        (order) =>
          this.getStatus(order) === "active" ||
          this.getStatus(order) === "suspended"
      )
      .map((order) => ({
        ...order,
        nce: order?.productType?.id === "OnlineServicesNCE",
      }))
    return (
      <Card>
        <CardHeader>{t("_orders")}</CardHeader>
        <CardBody>
          <LoadingGate loading={orders.fetching}>
            {!filteredOrders.length ? (
              []
            ) : (
              <ListGroup flush>
                {filteredOrders.map((o) => (
                  <ListGroupItem
                    className={`
                    ${
                      this.getStatus(o) === "active"
                        ? "bg-white text-dark"
                        : "bg-secondary text-white"
                    } d-flex align-items-center justify-content-between`}
                    key={o.id}
                    tag="a"
                    href={`/#/${o.type === "azure" ? "orders" : "licenses"}/${
                      o.id
                    }`}
                  >
                    {`${o.name} ${this.getStatusText(o)}`}
                    {o.nce && (
                      <Badge pill color="primary">
                        NCE
                      </Badge>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
          </LoadingGate>
        </CardBody>
      </Card>
    )
  }
}

export default connect((s) => ({ subscribed: s.subscribed }), {
  fetchResources,
})(withTranslation()(Licenses))
*/
export default Licenses
