import { applyMiddleware, compose, createStore } from "redux"
import { persistReducer, persistStore } from "redux-persist"

import rootReducer from "./reducers"
import storage from "redux-persist/lib/storage"
import thunk from "redux-thunk"

const persistConfig = {
  key: "partner",
  storage: storage,
  whitelist: ["list"],
}
const enhancers = []

const persistedReducer = persistReducer(persistConfig, rootReducer)
if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension())
  }
}
const composedEnhancers = compose(applyMiddleware(thunk), ...enhancers)

export default () => {
  const store = createStore(persistedReducer, {}, composedEnhancers)
  const persistor = persistStore(store)
  return { store, persistor }
}
