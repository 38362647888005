import { Button } from "reactstrap"
import { Loading } from "../../components"
import React from "react"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { fail } from "../../actions"
import { durationToString } from "../../lib/date"
import moment from "moment"

class LicenseDetails extends React.Component {
  state = { license: null, fetching: true, showMore: false }

  componentDidMount = () => {
    axios
      .get(`/licenses/${this.props.license.id}`)
      .then(({ data }) => {
        console.log("fetch price", data)
        this.setState({ license: data.license, fetching: false })
      })
      .catch((error) =>
        this.props.fail(null, error).then(() => {
          console.log("fetch price error", error)
          this.setState({ fetching: false })
        })
      )
  }

  shouldBeRenderedInShowMore(key, value) {
    let keysToLeaveOutFromShowMore = [
      "name",
      "id",
      "status",
      "commitmentEndDate",
      "offerName",
      "friendlyName",
      "quantity",
      "billingCycle",
      "termDuration",
    ]
    let keyShouldBeIncluded = !keysToLeaveOutFromShowMore.includes(key)
    let valueIsSupported =
      key === "links" || this.isSupportedTypeInShowMore(value)

    return keyShouldBeIncluded && valueIsSupported
  }

  isSupportedTypeInShowMore(value) {
    let type = typeof value
    if (type === "object" || type === "function") {
      return false
    }
    return true
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  makeKeyReadable(key) {
    key = key
      .replace(/([A-Z])/g, " $1")
      .trim()
      .toLowerCase()
    return this.capitalizeFirstLetter(key)
  }

  getLinkData(linkKey) {
    let linkData = this.state.linkData ? { ...this.state.linkData } : {}
    if (linkData[linkKey] === undefined) {
      linkData[linkKey] = {}
      linkData[linkKey].loading = true
      this.setState({ linkData: linkData })
      axios
        .get(`/orders/${this.props.license.id}/link/${linkKey}`)
        .then(({ data }) => {
          console.log(linkKey, data)
          linkData[linkKey] = {}
          linkData[linkKey].data = data.linkData
          linkData[linkKey].loading = false
          linkData[linkKey].isOpen = true
          this.setState({ linkData: linkData })
        })
        .catch((error) => {
          linkData[linkKey].loading = false
          this.setState({ linkData: linkData })
          console.log(error)
        })
    } else {
      linkData[linkKey].isOpen = !linkData[linkKey].isOpen
      this.setState({ linkData: linkData })
    }
  }

  render() {
    const { t, license } = this.props
    const { showMore } = this.state
    const nce =
      "productType" in license &&
      license.productType?.id === "OnlineServicesNCE"
    const linkData = this.state.linkData ? this.state.linkData : {}
    console.log(license)
    let skuLink = license?.links?.sku?.uri
    let sku = skuLink ? skuLink.split("skus/")[1] : t("_notAvailable")
    if (nce) {
      sku = license.offerId.split(":")[1]
    }
    let licenseKeys = Object.keys(license)
    let licenseValues = Object.values(license)
    return (
      <dl>
        <dt>{t("_licenseName")}</dt>
        <dd>{license.name}</dd>
        <dt>{t("_offerName")}</dt>
        <dd>{license.offerName}</dd>
        <dt>{t("_friendlyName")}</dt>
        <dd>{license.friendlyName}</dd>
        <dt>{t("_licenseID")}</dt>
        <dd>{license.id}</dd>
        <dt>{"SKU"}</dt>
        <dd>{sku}</dd>
        <dt>{t("_status")}</dt>
        <dd>{license.status}</dd>
        <dt>{t("_commitmentEndDate")}</dt>
        <dd>{license.commitmentEndDate}</dd>
        <dt>{t("_billingPlan")}</dt>
        <dd>{t(`_${license.billingCycle}`)}</dd>
        <dt>{t("_termDuration")}</dt>
        <dd>
          {durationToString(
            moment.duration(license.termDuration).asMonths(),
            t
          )}
        </dd>
        {this.state.fetching ? (
          <Loading small />
        ) : (
          <>
            <dt>{t("_unitPrice")}</dt>
            <dd>
              {this.state.license &&
                this.state.license.unitPrice &&
                this.state.license.unitPrice.toFixed(2)}
            </dd>
          </>
        )}
        <Button
          className={"my-3"}
          onClick={() => this.setState({ showMore: !showMore })}
        >
          {showMore ? t("_showLess") : t("_showMore")}
        </Button>
        {showMore && (
          <dl>
            {licenseValues.map(
              (value, i) =>
                this.shouldBeRenderedInShowMore(licenseKeys[i], value) && (
                  <dl key={i}>
                    {licenseKeys[i] === "links" ? (
                      <dl>
                        <dt>{"Links"}</dt>
                        {Object.keys(value).map(
                          (linkKey, j) =>
                            linkKey !== "self" && (
                              <dl key={j}>
                                {linkData[linkKey] &&
                                linkData[linkKey].loading ? (
                                  <dl className={"border p-2"}>
                                    <Loading small />
                                  </dl>
                                ) : (
                                  <dl className={"border p-2"}>
                                    <Button
                                      onClick={() => this.getLinkData(linkKey)}
                                    >
                                      {linkKey}
                                    </Button>
                                    {linkData[linkKey] &&
                                      linkData[linkKey].isOpen &&
                                      Object.keys(linkData[linkKey].data).map(
                                        (key, k) => (
                                          <dl key={k}>
                                            <dt>{this.makeKeyReadable(key)}</dt>
                                            <dd>
                                              {Object.values(
                                                linkData[linkKey].data
                                              )[k].toString()}
                                            </dd>
                                          </dl>
                                        )
                                      )}
                                  </dl>
                                )}
                              </dl>
                            )
                        )}
                      </dl>
                    ) : (
                      <dl>
                        <dt>{this.makeKeyReadable(licenseKeys[i])}</dt>
                        <dd>{value.toString()}</dd>
                      </dl>
                    )}
                  </dl>
                )
            )}
          </dl>
        )}
      </dl>
    )
  }
}

export default connect(null, { fail })(withTranslation()(LicenseDetails))
